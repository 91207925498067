export const createDiagnosisArray = (data: any, selected: any) => {
  const array: Array<{
    id_diagnosis_type: number;
    id_diagnosis_type_sub?: number;
    other_text?: string;
  }> = [];

  const addSubCat = (array: any, item: any, index: number) => {
    return data.map((sub: any) => {
      return (
        selected[sub.name] &&
        sub.level &&
        sub.id_parent === item.id_diagnosis_type &&
        (item.id_diagnosis_type_sub
          ? array.push({
              id_diagnosis_type: item.id_diagnosis_type,
              id_diagnosis_type_sub: sub.id,
            })
          : (array[index].id_diagnosis_type_sub = sub.id))
      );
    });
  };
  const addOtherCat = (array: any, item: any) => {
    array.push({
      id_diagnosis_type: item.id,
      id_diagnosis_type_sub: '',
      other_text: selected.otherDiagnosis,
    });
  };
  data.map((item: any) => {
    return selected['Other'] && item.name === 'Other'
      ? addOtherCat(array, item)
      : selected[item.name] &&
          !item.level &&
          array.push({
            id_diagnosis_type: item.id,
          });
  });
  array.map((item: any, index) => {
    addSubCat(array, item, index);
  });
  return array;
};
