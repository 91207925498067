export const ON_GET_ASSIGNABLE_DEVICES_REQUEST = 'ON_GET_ASSIGNABLE_DEVICES_REQUEST';
export const ON_GET_ASSIGNABLE_DEVICES_SUCCESS = 'ON_GET_ASSIGNABLE_DEVICES_SUCCESS';
export const ON_GET_ASSIGNABLE_DEVICES_FAILED = 'ON_GET_ASSIGNABLE_DEVICES_FAILED';

export const ON_GET_CURRENT_DEVICE_REQUEST = 'ON_GET_CURRENT_DEVICE_REQUEST';
export const ON_GET_CURRENT_DEVICE_SUCCESS = 'ON_GET_CURRENT_DEVICE_SUCCESS';
export const ON_GET_CURRENT_DEVICE_FAILED = 'ON_GET_CURRENT_DEVICE_FAILED';

export const ON_UNASSIGN_DEVICE_REQUEST = 'ON_UNASSIGN_DEVICE_REQUEST';
export const ON_UNASSIGN_DEVICE_SUCCESS = 'ON_UNASSIGN_DEVICE_SUCCESS';
export const ON_UNASSIGN_DEVICE_FAILED = 'ON_UNASSIGN_DEVICE_FAILED';

export const ON_ASSIGN_DEVICE_REQUEST = 'ON_ASSIGN_DEVICE_REQUEST';
export const ON_ASSIGN_DEVICE_SUCCESS = 'ON_ASSIGN_DEVICE_SUCCESS';
export const ON_ASSIGN_DEVICE_FAILED = 'ON_ASSIGN_DEVICE_FAILED';

export const ON_GET_ALL_DEVICES_REQUEST = 'ON_GET_ALL_DEVICES_REQUEST';
export const ON_GET_ALL_DEVICES_SUCCESS = 'ON_GET_ALL_DEVICES_SUCCESS';
export const ON_GET_ALL_DEVICES_FAILED = 'ON_GET_ALL_DEVICES_FAILED';
