import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useForm, Controller } from 'react-hook-form';
import { get } from 'lodash';
import { useHistory } from 'react-router-dom';
import TextInput from '../components/TextInput';
import CustomButton from '../components/CustomButton';
import logo from '../assets/logo/logo-white.jpg';
import { loginErrors } from '../constants/errors';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: 'linear-gradient(135deg, #1A428A 0%, #007CBA 100%)',
  },
  card: {
    padding: theme.spacing(2),
    width: theme.spacing(50),
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  logo: {
    width: '5em',
    marginBottom: theme.spacing(3),
  },
  buttonContainer: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(4),
  },
  text: {
    textAlign: 'center',
    fontWeight: 600,
    padding: theme.spacing(2),
  },
  form: {
    width: '100%',
  },
}));

function ForgotPassword() {
  const classes = useStyles();
  const history = useHistory();
  const [mailSent, setMailSent] = useState(false);
  const { handleSubmit, errors, control } = useForm({ mode: 'onChange' });

  const clickHandle = (data: object) => {
    if (mailSent) {
      history.push('/login');
    } else {
      setMailSent(true);
    }
  };

  return (
    <div className={classes.root}>
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <img src={logo} alt='white_logo' className={classes.logo} />
          <form className={classes.form}>
            {!mailSent ? (
              <>
                <Controller
                  as={
                    <TextInput
                      id='username'
                      name='username'
                      label='Username'
                      placeholder='Enter Username'
                      type='text'
                      bgColor='#E6EBF4'
                      autoFocus
                    />
                  }
                  name='username'
                  defaultValue=''
                  control={control}
                  rules={{
                    required: true,
                    pattern: /^.+@.+\..+$/,
                  }}
                />
                {errors.username && (
                  <Typography variant='caption' color='error'>
                    {get(loginErrors.username, errors.username.type, '')}
                  </Typography>
                )}
              </>
            ) : (
              <Typography variant='subtitle2' className={classes.text}>
                Your credentials have been sent to your email address
              </Typography>
            )}
            <Grid container direction='row' justify='center' alignItems='center'>
              <Grid item xs={12} className={classes.buttonContainer}>
                <CustomButton height='3.5em' onClick={handleSubmit(clickHandle)}>
                  {!mailSent ? 'Send Mail' : 'Back to Login'}
                </CustomButton>
              </Grid>
            </Grid>
          </form>
        </CardContent>
      </Card>
    </div>
  );
}

export default ForgotPassword;
