import {
  ON_GET_DASHBOARD_DATA_REQUEST,
  ON_GET_DASHBOARD_DATA_SUCCESS,
  ON_GET_DASHBOARD_DATA_FAILED,
} from '../actionTypes';

export const getDashboardRequest = () => ({
  type: ON_GET_DASHBOARD_DATA_REQUEST,
});

export const getDashboardSuccess = (data: any) => ({
  type: ON_GET_DASHBOARD_DATA_SUCCESS,
  payload: data,
});

export const getDashboardFailed = (data: any) => ({
  type: ON_GET_DASHBOARD_DATA_FAILED,
  payload: data,
});
