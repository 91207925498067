import { get, auth, put, post, deleteRow } from '../index';
const ENDPOINT = '/clinician';

export const getAssignableDevices = (personID: any) => {
  const url = `${ENDPOINT}/${personID}/device-assignable `;

  return get(url, auth());
};

export const getCurrentDevice = (personID: any) => {
  const url = `${ENDPOINT}/${personID}/device-current`;

  return get(url, auth());
};

export const unAssignDevice = (id_person_clinician: any, deviceID: any) => {
  const url = `${ENDPOINT}/${id_person_clinician}/device-unassign/${deviceID}`;

  return deleteRow(url, auth());
};
export const assignDevice = (clinician: any, deviceID: any) => {
  const { id_person_clinician } = clinician;
  const url = `${ENDPOINT}/${id_person_clinician}/device-assign/${deviceID}`;

  return post(url, clinician, auth());
};

export const getDevices = () => {
  const url = '/device/all';

  return get(url, auth());
};
