import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { isLogin } from '../utils/middleware/auth';

interface PublicRouteProps {
  component: any;
  path: string;
}
const PublicRoute = ({ component: Component, path, ...rest }: PublicRouteProps) => {
  return <Route path={path} exact render={(props) => <Component {...props} />} />;
};

export default PublicRoute;
