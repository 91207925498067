import {
  ON_GET_STATES_DATA_REQUEST,
  ON_GET_STATES_DATA_SUCCESS,
  ON_GET_STATES_DATA_FAILED,
  ON_GET_GENDER_DATA_SUCCESS,
  ON_GET_GENDER_DATA_FAILED,
  ON_GET_GENDER_DATA_REQUEST,
  ON_GET_CLINICIAN_CREDENTIALS_REQUEST,
  ON_GET_CLINICIAN_CREDENTIALS_SUCCESS,
  ON_GET_CLINICIAN_CREDENTIALS_FAILED,
  ON_GET_TREATMENT_STATUS_REQUEST,
  ON_GET_TREATMENT_STATUS_SUCCESS,
  ON_GET_TREATMENT_STATUS_FAILED,
  ON_GET_DIAGNOSIS_REQUEST,
  ON_GET_DIAGNOSIS_SUCCESS,
  ON_GET_DIAGNOSIS_FAILED,
  ON_DEVICE_LIST_FAILED,
  ON_DEVICE_LIST_REQUEST,
  ON_DEVICE_LIST_SUCCESS,
  ON_GET_TIME_ZONES_REQUEST,
  ON_GET_TIME_ZONES_SUCCESS,
  ON_GET_TIME_ZONES_FAILED,
} from '../actionTypes';

export const getStatesRequest = () => ({
  type: ON_GET_STATES_DATA_REQUEST,
});

export const getStatesSuccess = (data: any) => ({
  type: ON_GET_STATES_DATA_SUCCESS,
  payload: data,
});

export const getStatesFailed = (data: any) => ({
  type: ON_GET_STATES_DATA_FAILED,
  payload: data,
});
export const getGenderRequest = () => ({
  type: ON_GET_GENDER_DATA_REQUEST,
});

export const getGenderSuccess = (data: any) => ({
  type: ON_GET_GENDER_DATA_SUCCESS,
  payload: data,
});

export const getGenderFailed = (data: any) => ({
  type: ON_GET_GENDER_DATA_FAILED,
  payload: data,
});
export const getClinicianCredentialsRequest = () => ({
  type: ON_GET_CLINICIAN_CREDENTIALS_REQUEST,
});

export const getClinicianCredentialsSuccess = (data: any) => ({
  type: ON_GET_CLINICIAN_CREDENTIALS_SUCCESS,
  payload: data,
});

export const getClinicianCredentialsFailed = (data: any) => ({
  type: ON_GET_CLINICIAN_CREDENTIALS_FAILED,
  payload: data,
});
export const getTreatmentStatusRequest = () => ({
  type: ON_GET_TREATMENT_STATUS_REQUEST,
});

export const getTreatmentStatusSuccess = (data: any) => ({
  type: ON_GET_TREATMENT_STATUS_SUCCESS,
  payload: data,
});

export const getTreatmentStatusFailed = (data: any) => ({
  type: ON_GET_TREATMENT_STATUS_FAILED,
  payload: data,
});
export const getDiagnosisRequest = () => ({
  type: ON_GET_DIAGNOSIS_REQUEST,
});

export const getDiagnosisSuccess = (data: any) => ({
  type: ON_GET_DIAGNOSIS_SUCCESS,
  payload: data,
});

export const getDiagnosisFailed = (data: any) => ({
  type: ON_GET_DIAGNOSIS_FAILED,
  payload: data,
});

export const getDeviceRequest = (data: any) => ({
  type: ON_DEVICE_LIST_REQUEST,
  payload: data,
});

export const getDeviceSuccess = (data: any) => ({
  type: ON_DEVICE_LIST_SUCCESS,
  payload: data,
});

export const getDeviceFailed = (data: any) => ({
  type: ON_DEVICE_LIST_FAILED,
  payload: data,
});

export const getTimeZoneRequest = () => ({
  type: ON_GET_TIME_ZONES_REQUEST,
});

export const getTimeZoneSuccess = (data: any) => ({
  type: ON_GET_TIME_ZONES_SUCCESS,
  payload: data,
});

export const getTimeZoneFailed = (data: any) => ({
  type: ON_GET_TIME_ZONES_FAILED,
  payload: data,
});
