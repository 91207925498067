import { post } from '../index';
const ENDPOINT = '/auth';

interface LoginPayload {
  email: string;
  password: string;
}

export const login = (payload: LoginPayload) => {
  const url = `${ENDPOINT}/login`;

  return post(url, payload, {});
};
