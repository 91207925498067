export const ON_GET_STATES_DATA_REQUEST = 'ON_GET_STATES_DATA_REQUEST';
export const ON_GET_STATES_DATA_SUCCESS = 'ON_GET_STATES_DATA_SUCCESS';
export const ON_GET_STATES_DATA_FAILED = 'ON_GET_STATES_DATA_FAILED';

export const ON_GET_GENDER_DATA_REQUEST = 'ON_GET_GENDER_DATA_REQUEST';
export const ON_GET_GENDER_DATA_SUCCESS = 'ON_GET_GENDER_DATA_SUCCESS';
export const ON_GET_GENDER_DATA_FAILED = 'ON_GET_GENDER_DATA_FAILED';

export const ON_GET_CLINICIAN_CREDENTIALS_REQUEST = 'ON_GET_CLINICIAN_CREDENTIALS_REQUEST';
export const ON_GET_CLINICIAN_CREDENTIALS_SUCCESS = 'ON_GET_CLINICIAN_CREDENTIALS_SUCCESS';
export const ON_GET_CLINICIAN_CREDENTIALS_FAILED = 'ON_GET_CLINICIAN_CREDENTIALS_FAILED';

export const ON_GET_DIAGNOSIS_REQUEST = 'ON_GET_DIAGNOSIS_REQUEST';
export const ON_GET_DIAGNOSIS_SUCCESS = 'ON_GET_DIAGNOSIS_SUCCESS';
export const ON_GET_DIAGNOSIS_FAILED = 'ON_GET_DIAGNOSIS_FAILED';

export const ON_GET_TREATMENT_STATUS_REQUEST = 'ON_GET_TREATMENT_STATUS_REQUEST';
export const ON_GET_TREATMENT_STATUS_SUCCESS = 'ON_GET_TREATMENT_STATUS_SUCCESS';
export const ON_GET_TREATMENT_STATUS_FAILED = 'ON_GET_TREATMENT_STATUS_FAILED';

export const ON_GET_TIME_ZONES_REQUEST = 'ON_GET_TIME_ZONES_REQUEST';
export const ON_GET_TIME_ZONES_SUCCESS = 'ON_GET_TIME_ZONES_SUCCESS';
export const ON_GET_TIME_ZONES_FAILED = 'ON_GET_TIME_ZONES_FAILED';
