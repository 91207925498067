import React, { useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FilledInput from '@material-ui/core/FilledInput';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import FormControl from '@material-ui/core/FormControl';

interface InputProps {
  id: string;
  name: string;
  label: string;
  placeholder: string;
  password?: any;
  autoFocus?: any;
  type?: string;
  bgColor?: string;
}
const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(0),
    marginTop: theme.spacing(2),
  },
  textField: {
    width: '100%',
    backgroundColor: (props: InputProps) => props.bgColor && props.bgColor,
  },
  label: {
    color: '#333333',
    fontSize: 16,
    fontWeight: 400,
  },
  filledInput: {
    '&:before': {
      borderBottom: '0px solid',
    },
    '&:after': {
      borderBottom: '1px solid #007CBA',
    },
  },
  icon: {
    color: '#007CBA',
  },
}));

function TextInput(props: InputProps) {
  const { password } = props;
  const classes = useStyles(props);
  const [showPassword, setShowPassword] = useState(false);
  return (
    <FormControl className={clsx(classes.margin, classes.textField)} variant='filled'>
      <InputLabel htmlFor={props.id} className={classes.label}>
        {props.label}
      </InputLabel>
      <FilledInput
        type={showPassword ? 'text' : 'password'}
        {...props}
        style={{ borderBottom: '0px solid rgba(0, 0, 0, 0.42)' }}
        // value={values.password}
        // onChange={handleChange('password')}
        className={classes.filledInput}
        color='primary'
        endAdornment={
          password ? (
            <InputAdornment position='end'>
              <IconButton
                className={classes.icon}
                aria-label='toggle password visibility'
                onClick={() => setShowPassword(!showPassword)}
                edge='end'
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ) : (
            ''
          )
        }
      />
    </FormControl>
  );
}

TextInput.defaultProps = {
  password: 0,
};

export default TextInput;
