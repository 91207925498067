import { deleteRow, get, post, put } from '../index';
const ENDPOINT = '/patient';

const auth = () => {
  return {
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('simbex_authtoken')}`,
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
    },
  };
};

/**
 * Calls endpoint to get data for all patients defined in the database.
 * @returns
 */
export const getPatients = () => {
  const url = `${ENDPOINT}/all`;

  return get(url, auth());
};

export const setPatient = (payload: object) => {
  const url = `${ENDPOINT}`;

  return post(url, payload, auth());
};

export const deletePatient = (payload: object) => {
  const url = `${ENDPOINT}/${payload}`;

  return deleteRow(url, auth());
};
export const setPatientDiagnosis = (payload: any) => {
  const { patientID, data } = payload;
  const url = `${ENDPOINT}/${patientID}/patient-diagnosis/set-bulk`;

  return post(url, data, auth());
};

/**
 * Calls API endpoint to update patient information in the database.
 * @param payload
 * @param patientID
 * @returns
 */
export const updatePatient = (payload: any, patientID: number) => {
  const url = `${ENDPOINT}/${patientID}`;

  return put(url, payload, auth());
};

export const getPatientDetails = (patientID: number) => {
  const url = `${ENDPOINT}/${patientID}/details`;

  return get(url, auth());
};

export const delegatePatient = (payload: any, patientID: number) => {
  const url = `/affiliate/${patientID}`;

  return post(url, payload, auth());
};
export const undoDelegatePatient = (payload: any, patientID: number) => {
  const url = `/affiliate/${patientID}`;

  return put(url, payload, auth());
};

export const getPatientSession = (payload: any) => {
  let url = `${ENDPOINT}/{{id}}/sessions/`;
  url = url.replace('{{id}}', payload.patientId);
  let parsedUrl = `${url}?date_range_end={{endDate}}&date_range_start={{startDate}}`;
  if (payload.startDate) parsedUrl = parsedUrl.replace('{{startDate}}', payload.startDate);
  if (payload.endDate) parsedUrl = parsedUrl.replace('{{endDate}}', payload.endDate);

  return get(payload.startDate || payload.endDate ? parsedUrl : url, auth());
};

export const getDeviceList = (payload: any) => {
  let url = `/clinician/${payload.personID}/device-assignable`;
  if (!payload.isClinician) {
    url = `/patient/${payload.personID}/device-assignable/${payload.clinicianID}`;
  }
  // url = url.replace("{{id}}", payload.patientId);
  // let parsedUrl = `${url}?date_range_end={{endDate}}&date_range_start={{startDate}}&fetch_count=10`;
  // if (payload.startDate)
  //   parsedUrl = parsedUrl.replace("{{startDate}}", payload.startDate);
  // if (payload.endDate)
  //   parsedUrl = parsedUrl.replace("{{endDate}}", payload.endDate);

  return get(url, auth());
};
export const getCliniciansForDelegate = (patientID: number) => {
  const url = `${ENDPOINT}/${patientID}/clincians-for-delegate`;
  return get(url, auth());
};

export const initiateSessionProcessing = (patientID: number, sessionID: number) => {
  const url = `${ENDPOINT}/${patientID}/session/${sessionID}/fill-processed-data-summary`;

  return get(url, auth());
};
