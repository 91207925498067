import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CustomButton from './CustomButton';
import { CircularProgress, IconButton, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { styled } from '@material-ui/core/styles';
import { useForm } from 'react-hook-form';
import { connect, useDispatch } from 'react-redux';

import {
  getClinicainsRequest,
  getClinicianCredentialsRequest,
  getGenderRequest,
  getStatesRequest,
  getTimeZoneRequest,
  getTreatmentStatusRequest,
} from '../store/actions';
import ClinicianForm from './ClinicianForm';
import PatientForm from './PatientForm';
import MedicalForm from './MedicalForm';
import DelegateForm from './DelegateForm';
import theme from '../theme';
import DeviceForm from './DeviceForm';
import ResetPassword from './ResetPassword';
import ReportForm from './ReportForm';
import AddDevice from './AddDevice';

const DialogActionsWrapper = styled(DialogActions)({
  padding: '24px',
});

const RequiredText = styled(Typography)({
  display: 'block',
  color: '#c62828',
});

const CancelButton = styled(Button)({
  textTransform: 'none',
  marginRight: '1em',
});

const IconButtonWrapper = styled(IconButton)({
  position: 'absolute',
  right: theme.spacing(1),
  top: theme.spacing(1),
});

const defaultValues = {
  firstName: '',
  lastName: '',
  npi: '',
  email: '',
  clinicName: '',
  street: '',
  city: '',
  state: '',
  credential: '',
  zip: '',
  createLogin: false,
  otherCredential: '',
  gender: '',
  treatmentStatus: '',
  clinician: '',
  emr: '',
  timeZones: '',
  AG: false,
  BVFL: false,
  AL: false,
  BIL: false,
  CHI: false,
  CNSD: false,
  CYST: false,
  GS: false,
  LC: false,
  MTD: false,
  NDLS: false,
  NML: false,
  OD: false,
  Other: false,
  PAP: false,
  PARK: false,
  PLPS: false,
  SD: false,
  STROKE: false,
  SUSC: false,
  UNL: false,
  VFI: false,
  VT: false,
  otherDiagnosis: '',
  delegateClinician: '',
  device: '',
};

interface UserFormProps {
  modalOpen: boolean;
  modalClose: () => void;
  title: string;
  onSubmit: (data: any) => void;
  states: any;
  clinicianCredentials: { data: { result: Array<any> } };
  clinician?: boolean;
  userData: any;
  clinicians: any;
  medicalInfoForm?: boolean;
  delegateForm?: boolean;
  list: any;
  edit: boolean;
  editData?: any;
  patient?: boolean;
  device?: boolean;
  view?: boolean;
  clinicianID?: any;
  onEditDiagClick?: any;
  resetPassword?: boolean;
  resetPasswordForUser?: boolean;
  isLoading?: boolean;
  reportForm?: boolean;
  addDeviceForm?: boolean;
  patientID?: number;
}

function UserForm({
  modalOpen,
  modalClose,
  title,
  onSubmit,
  states,
  clinicianCredentials,
  clinician,
  userData,
  clinicians,
  medicalInfoForm,
  list,
  edit,
  editData,
  delegateForm,
  patient,
  device,
  view,
  clinicianID,
  onEditDiagClick,
  resetPassword,
  resetPasswordForUser,
  isLoading,
  reportForm,
  addDeviceForm,
  patientID,
}: UserFormProps) {
  const { handleSubmit, errors, control, watch, setValue } = useForm({
    defaultValues,
  });

  const dispatch = useDispatch();
  const result = clinicianCredentials?.data?.result;
  const [clinicianList, setClinicianList] = React.useState<any>([]);
  useEffect(() => {
    dispatch(getStatesRequest());
    dispatch(getClinicianCredentialsRequest());
    dispatch(getTreatmentStatusRequest());
    dispatch(getTimeZoneRequest());
    dispatch(getGenderRequest());
  }, []);

  useEffect(() => {
    if (userData?.data?.user.is_admin) {
      dispatch(getClinicainsRequest());
      const list: Array<any> = [];
      clinicians?.data?.result.map((item: any) => {
        return list.push({
          id: item.id,
          label: `${item.first_name} ${item.last_name}`,
          name: item.first_name,
          id_org: item.id_org,
        });
      });
      setClinicianList(list);
    }
  }, []);

  const handleOnSubmit = (data: any) => {
    if (userData?.data?.user.is_clinician) {
      data.clinician = { ...userData?.data?.user, id: userData?.data?.user.id_person };
    }
    onSubmit(data);
    setValue('delegateClinician', '');
    setValue('device', '');
  };

  return (
    <Dialog
      maxWidth={resetPassword || addDeviceForm ? 'sm' : 'md'}
      open={modalOpen}
      onClose={modalClose}
    >
      {isLoading ? (
        <div style={{ padding: '100px' }}>
          <CircularProgress />
        </div>
      ) : (
        <>
          <DialogTitle>
            {!(edit && patient) && !(edit && medicalInfoForm) && (
              <Typography variant='button'>{title}</Typography>
            )}

            <IconButtonWrapper aria-label='close' onClick={modalClose} disableRipple>
              <CloseIcon />
            </IconButtonWrapper>

            {edit && (patient || medicalInfoForm) && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                {patient ? (
                  <div style={{ borderBottom: '2px solid #007CBA' }}>
                    <Typography variant='button'>{title}</Typography>
                  </div>
                ) : (
                  <div
                    style={{ cursor: 'pointer', paddingBottom: '2px' }}
                    onClick={onEditDiagClick}
                  >
                    <Typography variant='button' color='textSecondary'>
                      Edit User
                    </Typography>
                  </div>
                )}

                {medicalInfoForm ? (
                  <div style={{ marginLeft: '20px', borderBottom: '2px solid #007CBA' }}>
                    <Typography variant='button'>{title}</Typography>
                  </div>
                ) : (
                  <div
                    style={{ marginLeft: '20px', cursor: 'pointer', paddingBottom: '2px' }}
                    onClick={() => onEditDiagClick(true)}
                  >
                    <Typography variant='button' color='textSecondary'>
                      Edit Diagnosis
                    </Typography>
                  </div>
                )}
              </div>
            )}

            {clinician && !view && (
              <RequiredText variant='caption'>
                <i>All fields required</i>
              </RequiredText>
            )}
          </DialogTitle>
          <DialogContent style={{ padding: 24 }}>
            {clinician && (
              <ClinicianForm
                control={control}
                edit={edit}
                view={view}
                editData={editData}
                setValue={setValue}
                errors={errors}
                watch={watch}
                list={list}
              />
            )}
            {patient && (
              <PatientForm
                control={control}
                errors={errors}
                watch={watch}
                patientID={patientID}
                clinicians={clinicianList}
                isAdmin={userData?.data?.user.is_admin}
                currentUser={userData?.data}
                setValue={setValue}
                editData={editData}
                edit={edit}
                list={list}
              />
            )}
            {medicalInfoForm && (
              <MedicalForm
                control={control}
                errors={errors}
                watch={watch}
                edit={edit}
                editData={editData}
                setValue={setValue}
                clinicians={clinicianList}
                isAdmin={userData?.data?.user.is_admin}
              />
            )}
            {delegateForm && (
              <DelegateForm
                setValue={setValue}
                watch={watch}
                control={control}
                onAdd={handleSubmit(handleOnSubmit)}
                clinicianList={clinicianList}
                errors={errors}
              />
            )}
            {resetPassword && (
              <ResetPassword
                forUser={resetPasswordForUser}
                control={control}
                watch={watch}
                errors={errors}
                isLoading={isLoading}
              />
            )}
            {device && (
              <DeviceForm
                setValue={setValue}
                watch={watch}
                control={control}
                clinicianID={clinicianID}
                errors={errors}
                editData={editData}
                list={list}
                onAddDevice={handleSubmit(handleOnSubmit)}
              />
            )}
            {reportForm && (
              <ReportForm
                control={control}
                errors={errors}
                watch={watch}
                clinicians={clinicianList}
                isAdmin={userData?.data?.user.is_admin}
                setValue={setValue}
                editData={editData}
                edit={edit}
                list={list}
              />
            )}
            {addDeviceForm && <AddDevice control={control} errors={errors} watch={watch} />}
          </DialogContent>
          {!view && !delegateForm && !device && (
            <DialogActionsWrapper>
              <CancelButton autoFocus variant='contained' onClick={modalClose} color='default'>
                <Typography variant='body1'>{'Cancel'}</Typography>
              </CancelButton>

              <CustomButton onClick={handleSubmit(handleOnSubmit)} width='min-content'>
                <Typography variant='body1'>
                  {edit ? (reportForm ? 'Download' : 'Update') : 'Save'}
                </Typography>
              </CustomButton>
            </DialogActionsWrapper>
          )}
        </>
      )}
    </Dialog>
  );
}

UserForm.defaultProps = {
  clinician: false,
  patient: false,
  medicalInfoForm: false,
  delegateForm: false,
};
const mapStateToProps = (state: any) => ({
  states: state.list.statesData?.data?.result,
  clinicianCredentials: state.list.clinicianCredentials,
  userData: state.userAuth.userData,
  clinicians: state.clinician.clinicianData,
  list: state.list,
});

export default connect(mapStateToProps)(UserForm);
