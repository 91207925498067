import { call, takeLatest, put } from 'redux-saga/effects';

import { ON_GET_DASHBOARD_DATA_REQUEST, ON_LOGIN_WITH_EMAIL_SUCCESS } from '../actionTypes';
import { getDashboardFailed, getDashboardSuccess } from '../actions';
import { getDashboardData } from '../../utils/services/dashboard';

function* getDashboardSaga(action: any) {
  try {
    const result = yield call(getDashboardData);
    if (!result.success) throw result.data;
    yield put(getDashboardSuccess(result));
  } catch (error) {
    yield put(getDashboardFailed(error));
  }
}
const dashboardSaga = [takeLatest(ON_GET_DASHBOARD_DATA_REQUEST, getDashboardSaga)];

export default dashboardSaga;
