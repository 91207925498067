/* eslint-disable no-use-before-define */
import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { Controller } from 'react-hook-form';
import { get as GET, post } from '../utils/services';
import { useDispatch } from 'react-redux';
import { getClinicListRequest } from '../store/actions';

const filter = createFilterOptions<any>();

export default function CreatableDropdown({ options, name, control, disabled }: any) {
  const [value, setValue] = React.useState<any | null>(null);
  const dispatch = useDispatch();
  const createOrganization = async (newValue: any) => {
    const { inputValue } = newValue;
    const res = await post(
      `/organization`,
      { name: inputValue, label: inputValue },
      {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('simbex_authtoken')}`,
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
        },
      },
    );
    dispatch(getClinicListRequest());

    return res.data.id;
  };

  //Guards against autocomplete errors when there are no options.
  options = !options ? [''] : options;

  return (
    <Controller
      render={(props) => (
        <Autocomplete
          {...props}
          disabled={disabled}
          onChange={async (event, newValue) => {
            props.onChange(newValue);
            if (typeof newValue === 'string') {
              setValue({
                label: newValue,
              });
            } else if (newValue && newValue.inputValue) {
              const id = await createOrganization(newValue);
              // Create a new value from the user input
              setValue({
                label: newValue.inputValue,
              });
              props.onChange({ id, name: newValue.inputValue, label: newValue.inputValue });
            } else {
              setValue(newValue);
            }
          }}
          filterOptions={(options, params) => {
            const filtered = options.length > 1 ? filter(options, params) : [];

            // Suggest the creation of a new value
            if (params.inputValue !== '') {
              filtered.push({
                inputValue: params.inputValue,
                label: `Add "${params.inputValue}"`,
              });
            }
            return filtered;
          }}
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          id={name}
          options={options}
          getOptionLabel={(option) => {
            // Value selected with enter, right from the input
            if (typeof option === 'string') {
              return option;
            }
            // Add "xxx" option created dynamically
            if (option.inputValue) {
              return option.inputValue;
            }
            // Regular option
            return option.label;
          }}
          renderOption={(option) => option.label}
          freeSolo
          renderInput={(params) => (
            <TextField {...params} margin='dense' size='small' variant='outlined' />
          )}
        />
      )}
      name={name}
      control={control}
      rules={{
        required: true,
      }}
    />
  );
}
