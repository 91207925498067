import { Typography } from '@material-ui/core';
import React, { useEffect } from 'react';
import { auth, get } from '../utils/services';
import * as uiVersion from '../build.json';

function Footer() {
  const [apiVersion, setApiVersion] = React.useState({
    apiVerMinor: 0,
    apiVerMajor: 0,
    apiVerPatch: 0,
    apiVerBuild: 0,
    commitshort: '',
  });
  useEffect(() => {
    get('/app/version', {}).then((version: any) => {
      setApiVersion({
        apiVerMinor: version.data.apiVerMinor,
        apiVerMajor: version.data.apiVerMajor,
        apiVerPatch: version.data.apiVerPatch,
        apiVerBuild: version.data.apiVerBuild,
        commitshort: version.data.commitshort,
      });
    });
  }, []);
  return (
    <Typography style={{ textAlign: 'center', color: 'gray' }}>
      &copy; 2020 - 2021 InnoVoyce, Inc. All rights reserved.
      <br />
      UI: {uiVersion.ui}({uiVersion.commitshort}) &#9679; API: {apiVersion.apiVerMajor}.
      {apiVersion.apiVerMinor}.{apiVersion.apiVerPatch}.{apiVersion.apiVerBuild}(
      {apiVersion.commitshort})
    </Typography>
  );
}

export default Footer;
