import React from 'react';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  card: {
    padding: '1rem',
    backgroundColor: theme.palette.primary.main,
  },
  text: {
    textAlign: 'center',
    color: 'white',
  },
}));

const NoDataCard = (props) => {
  const classes = useStyles();
  return (
    <Card className={classes.card}>
      <Typography className={classes.text}>
        5 minutes of data must be collected in order to display time series data
      </Typography>
    </Card>
  );
};

export default NoDataCard;
