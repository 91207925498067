import { combineReducers } from 'redux';

import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import authReducer from './userAuth.reducer';
import sessionReducer from './session.reducer';
import patientReducer from './patient.reducer';
import clinicianReducer from './clinician.reducer';
import listReducer from './list.reducer';
import dashboardReducer from './dashboard.reducer';
import parameterReducer from './parameter.reducer';
import deviceReducer from './device.reducer';
import sessionDetails from './sessionDetails.reducer';
import advancedParameterReducer from './advancedParameter.reducer';
import chatReducer from './chat.reducer';

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['sessionDetails'],
};

const rootReducer = combineReducers({
  userAuth: authReducer,
  session: sessionReducer,
  patient: patientReducer,
  clinician: clinicianReducer,
  list: listReducer,
  dashboard: dashboardReducer,
  parameters: parameterReducer,
  device: deviceReducer,
  sessionDetails,
  advancedParameters: advancedParameterReducer,
  chat: chatReducer,
});

export default persistReducer(persistConfig, rootReducer);
