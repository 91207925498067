import { call, takeLatest, put } from 'redux-saga/effects';

import { ON_LOGIN_WITH_EMAIL_REQUEST } from '../actionTypes';
import { loginSuccess, loginFailed } from '../actions/userAuth.actions';
import { login } from '../../utils/services/auth';
import { saveLogin } from '../../utils/middleware/auth';

function* loginSaga(action: any) {
  try {
    const result = yield call(login, action.payload);
    if (!result.success) throw result.data;

    saveLogin(result.data.authToken);
    yield put(loginSuccess(result));
  } catch (error) {
    yield put(loginFailed(error));
  }
}
const authSaga = [takeLatest(ON_LOGIN_WITH_EMAIL_REQUEST, loginSaga)];
export default authSaga;
