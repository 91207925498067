import {
  Button,
  Checkbox,
  DialogActions,
  DialogTitle,
  Divider,
  Grid,
  styled,
  TextField,
  Typography,
  IconButton,
} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { get, map } from 'lodash';
import React, { useEffect } from 'react';
import { Controller, useForm, useController } from 'react-hook-form';
import { connect, useDispatch } from 'react-redux';
import { patientFormErrors } from '../constants/errors';
import { paramFormSchema } from '../constants/paramterForm';
import CloseIcon from '@material-ui/icons/Close';
import theme from '../theme';

import {
  getClinicainsRequest,
  getGenderRequest,
  getTreatmentStatusRequest,
} from '../store/actions';
import Dropdown from './Dropdown';
import CustomButton from './CustomButton';

interface LooseObject {
  [key: string]: string | boolean;
}

const DialogActionsWrapper = styled(DialogActions)({
  // padding: '24px',
});

const CancelButton = styled(Button)({
  textTransform: 'none',
  marginRight: '1em',
});

const GridItem = styled(Grid)({
  padding: '10px 20px !important',
});

const Input = styled(TextField)({
  width: '100%',
  marginTop: 10,
});

const IconButtonWrapper = styled(IconButton)({
  position: 'absolute',
  right: theme.spacing(1),
  top: theme.spacing(1),
});
const DatePicker = styled('input')({
  marginTop: 10,
  width: '100%',
  padding: '9px',
  border: '1px solid #c4c4c4',
  borderRadius: '3px',
  color: '#757575',
});
interface Iprops {
  handleClose: any;
  initialValue: LooseObject;
  handleAdd: (x: any) => void;
  disabled?: boolean;
  toggleDisabled?: () => void;
  viewOnly?: boolean;
}

function PatientForm(props: Iprops) {
  const { handleClose, initialValue, viewOnly = false } = props;
  const { disabled, toggleDisabled } = props;
  const { handleSubmit, errors, control, watch, setValue, getValues } = useForm({
    defaultValues: initialValue,
  });
  // useEffect(() => {
  //   map(paramFormSchema.properties || {}, (key: any, value: string) => {
  //     // eslint-disable-next-line @typescript-eslint/no-unused-expressions
  //     if (key.type === "boolean") watch(value, initialValue[value]);
  //     setValue(value, initialValue[value]);
  //   });
  // }, []);

  const handleFormSubmit = () => {
    props.handleAdd(getValues());
  };

  return (
    <Dialog open={true} onClose={handleClose} maxWidth='sm' scroll='paper'>
      <DialogTitle>
        <Typography variant='button'>
          {props.disabled ? 'View Parameter' : 'Add Parameter'}
        </Typography>
        <IconButtonWrapper aria-label='close' onClick={handleClose}>
          <CloseIcon />
        </IconButtonWrapper>
      </DialogTitle>
      {/* <div style={{ backgroundColor: 'white', padding: 48 }}> */}
      {/* <div
          style={{
            position: 'sticky',
            top: 0,
            backgroundColor: 'white',
            zIndex: 999,
          }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              paddingTop: 24,
            }}>
            <Typography variant='h5'>{props.disabled ? 'View Parameter' : 'Add Parameter'}</Typography>
            <CloseIcon onClick={handleClose} />
          </div>
          <Divider style={{ marginBottom: 24, marginTop: 8, color: 'black' }} variant='fullWidth' />
        </div> */}

      <DialogContent dividers={true}>
        <Grid container spacing={5}>
          {map(paramFormSchema.properties || {}, (key: any, value: any) => {
            return (
              <GridItem item xs={12} sm={6} style={{ marginTop: 8 }}>
                <Typography variant='subtitle2' style={{}}>
                  {key.title}
                </Typography>
                <Controller
                  render={(props) => {
                    return (
                      <>
                        {key.type === 'boolean' ? (
                          <input
                            style={{ borderStyle: 'none' }}
                            type='checkbox'
                            checked={props.value}
                            aria-label={value}
                            color='primary'
                            onChange={(e) => props.onChange(e.target.checked)}
                            disabled={!!disabled}
                          />
                        ) : (
                          <Input
                            size='small'
                            name={value}
                            onChange={props.onChange}
                            value={props.value}
                            variant='outlined'
                            style={{}}
                            disabled={!!disabled}
                          />
                        )}
                      </>
                    );
                  }}
                  name={value}
                  control={control}
                  // rules={{
                  //   required: true,
                  // }}
                />
                {/* {errors.emr && (
            <Typography variant='caption' color='error'>
              {get(patientFormErrors.emr, errors.emr.type, "")}
            </Typography>
          )} */}
              </GridItem>
            );
          })}
        </Grid>
      </DialogContent>
      {/* </div> */}
      {/* {props.disabled ? null : ( */}
      {!viewOnly && (
        <div
          style={{
            position: 'sticky',
            bottom: 0,
            right: 0,
            width: '100%',
            backgroundColor: 'white',
          }}
        >
          <DialogActionsWrapper>
            {!disabled && (
              <CancelButton autoFocus variant='contained' onClick={handleClose} color='default'>
                <Typography variant='body1'>Cancel</Typography>
              </CancelButton>
            )}
            {!disabled ? (
              <CustomButton onClick={handleFormSubmit} width='min-content'>
                <Typography variant='body1'>Update</Typography>
              </CustomButton>
            ) : (
              <CustomButton onClick={() => toggleDisabled && toggleDisabled()} width='min-content'>
                <Typography variant='body1'>Edit</Typography>
              </CustomButton>
            )}
          </DialogActionsWrapper>
        </div>
      )}
      {/* )} */}
    </Dialog>
  );
}

const mapStateToProps = (state: any) => ({
  patient: state.patient,
});

export default connect(mapStateToProps)(PatientForm);
