import React from 'react';
import { v4 } from 'uuid';
import clsx from 'clsx';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ToolTip from '@material-ui/core/Tooltip';
import logo from '../assets/logo/logo-big.jpg';
import { Avatar, Snackbar } from '@material-ui/core';
import MenuDropdown from './MenuDropdown';
import { navItems, menuItems, adminNavItems } from '../constants/sidebar';
import { NavLink } from 'react-router-dom';
import UserForm from './UserForm';
import { auth, post } from '../utils/services';
import Alert from '@material-ui/lab/Alert';
import { useHistory } from 'react-router-dom';

const drawerWidth = 200;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    appBar: {
      width: `calc(100% - 72px)`,
      zIndex: theme.zIndex.drawer + 1,
      color: '#333333',
      backgroundColor: '#fff',
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarContent: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    userInfo: {
      display: 'flex',
      justifyContent: 'space-evenly',
      alignItems: 'center',
    },
    avatar: {
      backgroundColor: theme.palette.primary.main,
      fontSize: 20,
    },
    // appBarShift: {
    //   width: '95%',
    //   transition: theme.transitions.create(['width', 'margin'], {
    //     easing: theme.transitions.easing.sharp,
    //     duration: theme.transitions.duration.enteringScreen,
    //   }),
    // },
    // menuButton: {
    //   marginRight: 36,
    // },
    // hide: {
    //   display: "none",
    // },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
    },
    drawerOpen: {
      backgroundColor: theme.palette.primary.main,
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      backgroundColor: theme.palette.primary.main,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: theme.spacing(7) + 1,
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9) + 1,
      },
    },
    toolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
    logo: {
      'width': theme.spacing(13),
      '&:hover': {
        cursor: 'pointer',
      },
    },
    navlink: {
      color: '#FFFFFF',
      //   fontSize: 14,
      fontWeight: 400,
      textDecoration: 'none',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: theme.spacing(1),
    },
    navSelected: {
      'backgroundColor': '#1A428A',
      '& :hover': {
        backgroundColor: '#1A428A',
      },
    },
  }),
);

interface SidebarProps {
  children: any;
  userData: {
    data: {
      user: {
        username: string;
        is_admin: number;
      };
    };
  };
}

function Sidebar({ children, userData }: SidebarProps) {
  const classes = useStyles();
  const history = useHistory();
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [alert, setAlert] = React.useState(false);
  const [resetPassword, setResetPassword] = React.useState(false);
  const [passwordResetSuccess, setPasswordResetSuccess] = React.useState(false);
  const [passwordMessage, setPasswordMessage] = React.useState('');
  const { user } = userData.data;

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const changePassword = () => {
    setResetPassword(true);
  };
  const modalClose = () => {
    setResetPassword(false);
  };
  const onSubmit = (data: any) => {
    setLoading(true);
    const { username } = user;
    const { oldPassword, newPassword } = data;
    const payload = {
      username,
      oldPassword,
      newPassword,
    };
    post('/auth/change-password', payload, auth()).then((data) => {
      setPasswordResetSuccess(data.success);
      if (data.success) {
        setResetPassword(false);
        setAlert(true);
        setPasswordMessage(data.data.message);
        setLoading(false);
      } else {
        setAlert(true);
        setPasswordMessage(data.data.message);
        setLoading(false);
      }
    });
  };
  // React.useEffect(() => {}, [passwordMessage]);
  const handleClose = () => {
    setAlert(false);
  };
  menuItems.map((item: any) => {
    return item.key === 'changePassword' ? (item.handleClick = changePassword) : '';
  });
  return (
    <div className={classes.root}>
      <CssBaseline />
      <Snackbar
        open={alert}
        autoHideDuration={6000}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={passwordResetSuccess ? 'success' : 'error'}>
          {passwordMessage}
        </Alert>
      </Snackbar>
      <AppBar position='fixed' className={clsx(classes.appBar)}>
        <Toolbar className={classes.appBarContent}>
          <img
            src={logo}
            className={classes.logo}
            alt='logo-big'
            onClick={() => history.push('/dashboard')}
          />
          <div className={classes.userInfo}>
            <MenuDropdown items={menuItems}>
              <Avatar variant='rounded' className={classes.avatar}>
                <Typography variant='h5'>{user.username.charAt(0).toUpperCase()}</Typography>
              </Avatar>
            </MenuDropdown>
            <Typography variant='body1' style={{ marginLeft: 10 }}>
              {user.username.split('@')[0]}
            </Typography>
          </div>
          <UserForm
            modalOpen={resetPassword}
            modalClose={modalClose}
            onSubmit={onSubmit}
            title='Reset Password'
            resetPassword={true}
            edit={false}
            isLoading={loading}
          />
        </Toolbar>
      </AppBar>
      <Drawer
        variant='permanent'
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
          {open ? (
            <IconButton onClick={handleDrawerClose}>
              <CloseIcon color='secondary' />
            </IconButton>
          ) : (
            <IconButton onClick={handleDrawerOpen}>
              <MenuIcon color='secondary' />
            </IconButton>
          )}
        </div>
        <Divider />
        <List style={{ padding: 0 }}>
          {(user.is_admin ? adminNavItems : navItems).map((item) => (
            <ToolTip title={item.label} key={v4()}>
              <NavLink
                className={classes.navlink}
                activeClassName={classes.navSelected}
                key={item.key}
                to={item.link}
              >
                <ListItem button key={item.key}>
                  <ListItemIcon>
                    {/* {item.key === "clinicians" ? (
                    <item.icon />
                  ) : ( */}
                    <item.icon color='secondary' />
                    {/* )} */}
                  </ListItemIcon>
                  {item.label}
                </ListItem>
              </NavLink>
            </ToolTip>
          ))}
        </List>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {children}
      </main>
    </div>
  );
}

const mapStateToProps = (state: any) => ({
  userData: state.userAuth.userData,
});

export default connect(mapStateToProps)(Sidebar);
