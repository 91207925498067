import { ON_SET_PARAMETER_REQUEST } from './../actionTypes/parameter.actionTypes';
import { call, takeLatest, put } from 'redux-saga/effects';
import { setParameterFailed, setParameterSuccess } from '../actions';
import { addParameter } from '../../utils/services/parameter';

function* setParameterSaga(action: any) {
  const { data, toast } = action;
  try {
    const result = yield addParameter({
      parameterId: data.parameterId,
      payload: data.payload,
    });
    if (result.success) {
      yield put(setParameterSuccess(result.data));
      toast.alert(true);
      toast.message('Parameters updated');
      toast.status(true);
    } else throw result.data.message;
  } catch (err) {
    setParameterFailed(err);
    toast.alert(true);
    toast.message('Parameters update failed');
    toast.status(false);
  }
}

const patientSaga = [takeLatest(ON_SET_PARAMETER_REQUEST, setParameterSaga)];

export default patientSaga;
