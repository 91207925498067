import {
  ON_GET_SESSIONS_DATA_REQUEST,
  ON_GET_SESSIONS_DATA_SUCCESS,
  ON_GET_SESSIONS_DATA_FAILED,
} from '../actionTypes';

export const getSessionsRequest = () => ({
  type: ON_GET_SESSIONS_DATA_REQUEST,
});

export const getSessionsSuccess = (data: any) => ({
  type: ON_GET_SESSIONS_DATA_SUCCESS,
  payload: data,
});

export const getSessionsFailed = (data: any) => ({
  type: ON_GET_SESSIONS_DATA_FAILED,
  payload: data,
});
