import {
  ON_ASSIGN_DEVICE_FAILED,
  ON_ASSIGN_DEVICE_REQUEST,
  ON_ASSIGN_DEVICE_SUCCESS,
  ON_GET_ASSIGNABLE_DEVICES_FAILED,
  ON_GET_ASSIGNABLE_DEVICES_REQUEST,
  ON_GET_ASSIGNABLE_DEVICES_SUCCESS,
  ON_GET_CURRENT_DEVICE_FAILED,
  ON_GET_CURRENT_DEVICE_REQUEST,
  ON_GET_CURRENT_DEVICE_SUCCESS,
  ON_UNASSIGN_DEVICE_FAILED,
  ON_UNASSIGN_DEVICE_REQUEST,
  ON_UNASSIGN_DEVICE_SUCCESS,
  ON_GET_ALL_DEVICES_REQUEST,
  ON_GET_ALL_DEVICES_SUCCESS,
  ON_GET_ALL_DEVICES_FAILED,
} from '../actionTypes';

const initialState = {
  loading: true,
  device: [],
  error: {},
};

const deviceReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case ON_GET_ASSIGNABLE_DEVICES_REQUEST:
      return { ...state, loading: true };
    case ON_GET_ASSIGNABLE_DEVICES_SUCCESS:
      return {
        ...state,
        loading: false,
        assignableDevices: action.payload,
        error: {},
      };
    case ON_GET_ASSIGNABLE_DEVICES_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
        assignableDevices: {},
      };
    case ON_GET_CURRENT_DEVICE_REQUEST:
      return { ...state, loading: true };
    case ON_GET_CURRENT_DEVICE_SUCCESS:
      return {
        ...state,
        loading: false,
        currentDevices: action.payload,
        error: {},
      };
    case ON_GET_CURRENT_DEVICE_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
        currentDevices: {},
      };
    case ON_UNASSIGN_DEVICE_REQUEST:
      return { ...state, loading: true };
    case ON_UNASSIGN_DEVICE_SUCCESS:
      return {
        ...state,
        loading: false,
        unassignDevice: action.payload,
        error: {},
      };
    case ON_UNASSIGN_DEVICE_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
        unassignDevice: {},
      };
    case ON_ASSIGN_DEVICE_REQUEST:
      return { ...state, loading: true };
    case ON_ASSIGN_DEVICE_SUCCESS:
      return {
        ...state,
        loading: false,
        assignDevice: action.payload,
        error: {},
      };
    case ON_ASSIGN_DEVICE_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
        assignDevice: {},
      };
    case ON_GET_ALL_DEVICES_REQUEST:
      return {
        ...state,
        loading: true,
        error: {},
      };
    case ON_GET_ALL_DEVICES_SUCCESS:
      return {
        ...state,
        loading: false,
        device: action.payload,
        error: {},
      };
    case ON_GET_ALL_DEVICES_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default deviceReducer;
