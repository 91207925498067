export const ON_GET_CLINICIANS_DATA_REQUEST = 'ON_GET_CLINICIANS_DATA_REQUEST';
export const ON_GET_CLINICIANS_DATA_SUCCESS = 'ON_GET_CLINICIANS_DATA_SUCCESS';
export const ON_GET_CLINICIANS_DATA_FAILED = 'ON_GET_CLINICIANS_DATA_FAILED';

export const ON_SET_CLINICIAN_REQUEST = 'ON_SET_CLINICIAN_REQUEST';
export const ON_SET_CLINICIAN_SUCCESS = 'ON_SET_CLINICIAN_SUCCESS';
export const ON_SET_CLINICIAN_FAILED = 'ON_SET_CLINICIAN_FAILED';

export const ON_DELETE_CLINICIAN_REQUEST = 'ON_DELETE_CLINICIAN_REQUEST';
export const ON_DELETE_CLINICIAN_SUCCESS = 'ON_DELETE_CLINICIAN_SUCCESS';
export const ON_DELETE_CLINICIAN_FAILED = 'ON_DELETE_CLINICIAN_FAILED';

export const ON_UPDATE_CLINICIAN_REQUEST = 'ON_UPDATE_CLINICIAN_REQUEST';
export const ON_UPDATE_CLINICIAN_SUCCESS = 'ON_UPDATE_CLINICIAN_SUCCESS';
export const ON_UPDATE_CLINICIAN_FAILED = 'ON_UPDATE_CLINICIAN_FAILED';

export const ON_GET_CLINIC_REQUEST = 'ON_GET_CLINIC_REQUEST';
export const ON_GET_CLINIC_SUCCESS = 'ON_GET_CLINIC_SUCCESS';
export const ON_GET_CLINIC_FAILED = 'ON_GET_CLINIC_FAILED';
