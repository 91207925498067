import { auth, get } from '../../services';

// LOGIN
export const saveLogin = (token: string) => {
  localStorage.setItem('simbex_authtoken', token);
};

// LOGOUT
export const logout = () => localStorage.removeItem('simbex_authtoken');

// LOGIN STATUS
export const isLogin = async () => {
  if (localStorage.getItem('simbex_authtoken')) {
    const whoAmI = await get('/auth/whoami', auth());
    if (!whoAmI.success) {
      localStorage.removeItem('simbex_authtoken');
      return false;
    }
    const refreshToken = await get('/auth/refresh-token', auth());

    if (!refreshToken.success) {
      // localStorage.removeItem("simbex_authtoken");
      return false;
    }
    localStorage.setItem('simbex_authtoken', refreshToken.data.authToken);
    return true;
  }
  return false;
};
