import { all } from 'redux-saga/effects';
import auth from './userAuth.saga';
import session from './session.saga';
import patient from './patient.saga';
import clinician from './clinician.saga';
import list from './list.saga';
import dashboard from './dashboard.saga';
import device from './device.saga';
import parameter from './parameter.saga';
import sessionDetails from './sessionDetails.saga';
import advancedParameterSaga from './advancedParameter.saga';
import chatSaga from './chat.saga';

const sagas = [
  ...auth,
  ...session,
  ...patient,
  ...clinician,
  ...list,
  ...dashboard,
  ...parameter,
  ...device,
  ...sessionDetails,
  ...advancedParameterSaga,
  ...chatSaga,
];

export function* rootSaga() {
  yield all(sagas);
}
