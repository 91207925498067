//AddDevice.tsx
/**
 * Defines the view for entering information to register a device on the system.
 *
 * @module: AddDevice.tsx
 * @author: Corey Paye
 * @copyright: Simbex, 2023
 */

import { Grid, styled, TextField, Typography } from '@material-ui/core';
import { Controller } from 'react-hook-form';

const GridItem = styled(Grid)({
  padding: '10px 20px !important',
});

const Input = styled(TextField)({
  width: '100%',
  marginTop: 10,
});

const Asterisk = () => {
  return <span style={{ color: '#c62828' }}> *</span>;
};

const validateMacFormat = (input: String) => {
  const regex = /^([0-9A-Fa-f]{2}:){5}[0-9A-Fa-f]{2}$/;
  return regex.test(input.toUpperCase());
};

const macFormatMsg =
  'Enter an address in the form XX:XX:XX:XX:XX:XX.  \nAll letters must be between A and F, inclusive.';

function AddDevice({ control, errors }: any) {
  return (
    <Grid container style={{ alignItems: 'center' }}>
      <GridItem item xs={12} sm={12} md={12}>
        <Typography variant='subtitle2'>
          Mac Address
          <Asterisk />
        </Typography>
        <Controller
          // Setting the size so that the modal width does not jump around while user is entering info
          as={
            <Input
              size='small'
              variant='outlined'
              inputProps={{ size: `${macFormatMsg.length}`, spellCheck: false }}
            />
          }
          defaultValue=''
          name='mac_address'
          control={control}
          rules={{
            validate: validateMacFormat,
            required: true,
          }}
        />
        {errors.mac_address && (
          <Typography variant='caption' color='error'>
            {macFormatMsg}
          </Typography>
        )}
      </GridItem>
    </Grid>
  );
}

export default AddDevice;
