import React, { useState, MouseEvent } from 'react';
import { v4 } from 'uuid';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';

import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

import { styled } from '@material-ui/core/styles';
import { LinearProgress } from '@material-ui/core';
interface Data {
  any;
}

type Order = 'asc' | 'desc';

interface EnhancedTableProps {
  classes: ReturnType<typeof useStyles>;
  headerCells: any;
  onRequestSort: (event: MouseEvent<unknown>, property: keyof Data) => void;
  order: Order;
  orderBy: string;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { classes, headerCells, order, orderBy, onRequestSort } = props;

  const createSortHandler = (property: keyof Data) => (event: MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headerCells.map((headCell: any) => {
          return (
            <TableCell
              key={v4()}
              align='left'
              padding='default'
              sortDirection={orderBy === headCell.id ? order : false}
            >
              {headCell.id !== 'options' && (
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : 'asc'}
                  onClick={createSortHandler(headCell.id)}
                >
                  {headCell.label}
                  {orderBy === headCell.id ? (
                    <span className={classes.visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </span>
                  ) : null}
                </TableSortLabel>
              )}
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
}

const ErrorText = styled(Typography)({
  padding: '1em',
  textAlign: 'center',
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      // maxHeight: '85vh',
      marginBottom: theme.spacing(2),
      overflowY: 'auto',
      overflowX: 'hidden',
    },
    table: {
      'minWidth': 750,
      '& tr:nth-of-type(even)': {
        backgroundColor: 'rgba(0, 0, 0, 0.05)',
      },
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    link: {
      'padding': '7px 16px',
      'color': theme.palette.primary.main,
      'cursor': 'pointer',
      'fontSize': 14,
      'fontWeight': 600,
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
        color: 'white',
      },
    },
    parameterLink: {
      'padding': 0,
      'color': theme.palette.primary.main,
      'cursor': 'pointer',
      'fontSize': 14,
      'fontWeight': 600,
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
        color: 'white',
      },
    },
  }),
);

interface DeviceTableProps {
  header: any;
  dataRows: Array<any>;
  loading: boolean;
}

export default function DeviceTable({ header, dataRows, loading }: DeviceTableProps) {
  const classes = useStyles();
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof Data>(header[0].id);

  const handleRequestSort = (event: MouseEvent<unknown>, property: keyof Data) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const getSortedData = (data, orderBy, order) => {
    if (data) {
      let sortedData;
      const sortMethod = header.find((head) => head.id === orderBy).sortMethod;
      // get null values at data[orderBy], filter them out
      const nulls = data.filter((patient) => patient[orderBy] === null);
      // data without null values
      const newData = data.filter((patient) => patient[orderBy] !== null);

      const alphabeticSort = (a, b) => {
        if (order === 'asc') {
          return a[orderBy].localeCompare(b[orderBy]);
        } else {
          return b[orderBy].localeCompare(a[orderBy]);
        }
      };

      const numericSort = (a, b) => {
        if (order === 'asc') {
          return a[orderBy] - b[orderBy];
        } else {
          return b[orderBy] - a[orderBy];
        }
      };

      const dateSort = (a, b) => {
        if (order === 'asc') {
          if (new Date(a[orderBy]) > new Date(b[orderBy])) return 1;
          if (new Date(a[orderBy]) < new Date(b[orderBy])) return -1;
          return 0;
        } else {
          if (new Date(a[orderBy]) > new Date(b[orderBy])) return -1;
          if (new Date(a[orderBy]) < new Date(b[orderBy])) return 1;
          return 0;
        }
      };

      const nameSort = (a, b) => {
        if (order === 'asc') {
          return `${a[orderBy]?.first_name} ${a[orderBy]?.last_name}}`.localeCompare(
            `${b[orderBy]?.first_name} ${b[orderBy]?.last_name}}`,
          );
        }
      };

      // use appropriate sort function depending on orderBy property's sortMethod
      sortMethod === 'date'
        ? (sortedData = newData.sort(dateSort))
        : sortMethod === 'name'
        ? (sortedData = newData.sort(nameSort))
        : sortMethod === 'alpha'
        ? (sortedData = newData.sort(alphabeticSort))
        : sortMethod === 'numeric'
        ? (sortedData = newData.sort(numericSort))
        : (sortedData = newData.sort((a, b) => (b[orderBy] = a[orderBy])));
      // append filtered out nulls
      sortedData.push(...nulls);

      return sortedData;
    }
  };

  const tableRows =
    dataRows &&
    getSortedData(dataRows, orderBy, order).map((row: any, index) => {
      return (
        <TableRow hover key={row.id}>
          <TableCell component='th' scope='row'>
            <Typography variant='button'>{row.id}</Typography>
          </TableCell>
          <TableCell align='left'>{row.serial_number}</TableCell>
          <TableCell align='left'>{row.mac_address || '-'}</TableCell>
          <TableCell align='left'>{row.passkey}</TableCell>
          <TableCell align='left'>
            {row.assinged_clinician
              ? `${row.assinged_clinician.first_name} ${row.assinged_clinician.last_name}`
              : '-'}
          </TableCell>
          <TableCell align='left'>
            {row.assinged_patient
              ? `${row.assinged_patient.first_name} ${row.assinged_patient.last_name}`
              : '-'}
          </TableCell>
        </TableRow>
      );
    });

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        {loading && <LinearProgress />}
        <Table className={classes.table} size={'small'} aria-label='enhanced table'>
          <EnhancedTableHead
            classes={classes}
            headerCells={header}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />
          {dataRows && <TableBody>{tableRows}</TableBody>}
        </Table>
        {dataRows?.length === 0 && <ErrorText variant='body1'>No Data</ErrorText>}
      </Paper>
    </div>
  );
}
