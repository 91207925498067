import axios from 'axios';
import { Parser } from 'json2csv';
import { history } from '../../screens/App';
import baseURLConfig from './baseURLConfig';

axios.defaults.baseURL = baseURLConfig();

console.log('API url: ', axios.defaults.baseURL);

axios.interceptors.response.use((res) => {
  if (!res.data.success && res.data.data.message === 'Invalid Token') {
    history.push('/');
  }
  return res;
});

export const auth = () => {
  return {
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('simbex_authtoken')}`,
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
    },
  };
};
export async function post(endpoint: string, payload: object, auth: object) {
  const postFunc = auth ? axios.post(endpoint, payload, auth) : axios.post(endpoint, payload);

  return postFunc
    .then((response) => response.data)
    .catch((error) => {
      throw error.response;
    });
}

export async function get(endpoint: string, auth: object) {
  const getFunc = auth ? axios.get(endpoint, auth) : axios.get(endpoint);

  return getFunc
    .then((response) => response.data)
    .catch((error) => {
      throw error.response;
    });
}

export async function deleteRow(endpoint: string, auth: object) {
  const deleteFunc = auth ? axios.delete(endpoint, auth) : axios.delete(endpoint);

  return deleteFunc
    .then((response) => response.data)
    .catch((error) => {
      throw error.response;
    });
}
export async function downloadCSV(endpoint: string, payload: object, fileName: string) {
  const instance = axios.create();
  return instance({
    url: endpoint, //your url
    method: 'GET',
    data: payload,
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('simbex_authtoken')}`,
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      // responseType: "blob",
      // "Access-Control-Allow-Origin": "*",
    },
  }).then((response) => {
    const parser = new Parser();
    const csv = parser.parse(response.data);
    const url = window.URL.createObjectURL(new Blob([csv]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName); //or any other extension
    document.body.appendChild(link);
    link.click();
  });
}
export async function put(endpoint: string, payload: object, auth: object) {
  const postFunc = auth ? axios.put(endpoint, payload, auth) : axios.put(endpoint, payload);

  return postFunc
    .then((response) => response.data)
    .catch((error) => {
      throw error.response;
    });
}
export async function getRawData(sessionId: number) {
  const url = `session/${sessionId}/raw-data`;
  const result = await get(url, auth());
  if (result.success && result.data.result.aws) {
    return { url: result.data.result.url, sessionId: sessionId };
  } else {
    throw 'error';
  }
}
export async function getCSVData(sessionId: number) {
  const url = `session/${sessionId}/processed-data`;
  const result = await get(url, auth());
  if (result.success && result.data.result.aws) {
    return { url: result.data.result.url, sessionId: sessionId };
  } else {
    throw 'error';
  }
}
export async function download(
  endpoint: string,
  payload: object,
  fileName: string,
  getMethod: boolean,
) {
  const instance = axios.create();
  return instance({
    url: endpoint, //your url
    method: getMethod ? 'GET' : 'POST',
    responseType: 'blob',
    data: payload,
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('simbex_authtoken')}`,
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      // responseType: "blob",
      // "Access-Control-Allow-Origin": "*",
    },
  }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName); //or any other extension
    document.body.appendChild(link);
    link.click();
  });

  const postFunc = axios.post(endpoint, payload, {
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('simbex_authtoken')}`,
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      // responseType: "blob",
    },
  });

  return await postFunc
    .then((response) => response.data)
    .catch((error) => {
      throw error.response;
    });
}
