import { Grid, styled, TextField, Typography } from '@material-ui/core';
import { get } from 'lodash';
import React, { useEffect } from 'react';
import { Controller } from 'react-hook-form';
import { connect, useDispatch } from 'react-redux';
import { clinicianFormErrors } from '../constants/errors';
import {
  getStatesRequest,
  getClinicianCredentialsRequest,
  getClinicListRequest,
} from '../store/actions';
import CreatableDropdown from './CreatableDropdown';
import Dropdown from './Dropdown';

const GridItem = styled(Grid)({
  padding: '10px 20px !important',
});

const Input = styled(TextField)({
  width: '100%',
  marginTop: 10,
});

function ClinicianForm({
  control,
  errors,
  states,
  clinicianCredentials,
  watch,
  edit,
  editData,
  setValue,
  view,
  clinicList,
  list,
}: any) {
  const dispatch = useDispatch();

  const { timeZones } = list;
  const result = clinicianCredentials?.data?.result;
  //When working with a new system, no clinics will be defined.  Create a placeholder.
  const clinics = !clinicList?.data?.result ? [''] : clinicList?.data?.result;
  const [userTimeZones, setUserTimeZones] = React.useState([]);

  useEffect(() => {
    dispatch(getStatesRequest());
    dispatch(getClinicianCredentialsRequest());
    dispatch(getClinicListRequest());
  }, []);

  useEffect(() => {
    if (timeZones) {
      const timeZoneList: any = [];
      timeZones?.result.map((item: any) => {
        return timeZoneList.push({
          id: item.id,
          label: item.tz_identifier,
          name: item.tz_identifier,
        });
      });
      setUserTimeZones(timeZoneList);
    }
  }, []);
  useEffect(() => {
    const credential = clinicianCredentials?.data?.result;
    setValue('firstName', editData.first_name);
    setValue('lastName', editData.last_name);
    setValue('npi', editData.npi);
    setValue('email', editData.email);
    setValue(
      'clinicName',
      clinics && clinics.filter((item: any) => item.id === editData.id_org)[0],
    );
    setValue('street', editData.address1);
    setValue('city', editData.city);
    setValue('zip', editData.zip);
    setValue('otherCredential', editData.clinician_credential_text);
    setValue('state', states && states.filter((item: any) => item.id === editData.id_state)[0]);
    setValue(
      'credential',
      credential &&
        credential.filter((item: any) => item.id === editData.id_clinician_credential)[0],
    );
    setValue('timeZones', {
      id: editData.id_time_zone,
      label: editData.tz_identifier,
      name: editData.tz_identifier,
    });
  }, [editData]);

  const Asterisk = () => {
    return !view ? <span style={{ color: '#c62828' }}> *</span> : <></>;
  };
  return (
    <Grid container spacing={5}>
      <GridItem item xs={12} sm={4}>
        <Typography variant='subtitle2'>
          First Name
          <Asterisk />
        </Typography>
        <Controller
          as={
            <Input
              disabled={view ? true : false}
              size='small'
              InputProps={{
                readOnly: view ? true : false,
              }}
              variant='outlined'
            />
          }
          defaultValue=''
          name='firstName'
          control={control}
          rules={{
            required: true,
          }}
        />
        {errors.firstName && (
          <Typography variant='caption' color='error'>
            {get(clinicianFormErrors.firstName, errors.firstName.type, '')}
          </Typography>
        )}
      </GridItem>
      <GridItem item xs={12} sm={4}>
        <Typography variant='subtitle2'>
          Last Name
          <Asterisk />
        </Typography>
        <Controller
          as={
            <Input
              disabled={view ? true : false}
              size='small'
              InputProps={{
                readOnly: view ? true : false,
              }}
              variant='outlined'
            />
          }
          defaultValue=''
          name='lastName'
          control={control}
          rules={{
            required: true,
          }}
        />
        {errors.lastName && (
          <Typography variant='caption' color='error'>
            {get(clinicianFormErrors.lastName, errors.lastName.type, '')}
          </Typography>
        )}
      </GridItem>
      <GridItem item xs={12} sm={4}>
        <Typography variant='subtitle2'>
          NPI
          <Asterisk />
        </Typography>
        <Controller
          as={
            <Input
              disabled={view ? true : false}
              size='small'
              InputProps={{
                readOnly: view ? true : false,
              }}
              variant='outlined'
            />
          }
          defaultValue=''
          name='npi'
          control={control}
          rules={{
            required: true,
            pattern: /^[0-9]{10}$/,
          }}
        />
        {errors.npi && (
          <Typography variant='caption' color='error'>
            {get(clinicianFormErrors.npi, errors.npi.type, '')}
          </Typography>
        )}
      </GridItem>
      <GridItem item xs={12} sm={4}>
        <Typography variant='subtitle2'>
          Email
          <Asterisk />
        </Typography>
        <Controller
          as={
            <Input
              disabled={view ? true : false}
              size='small'
              InputProps={{
                readOnly: view ? true : false,
              }}
              variant='outlined'
            />
          }
          defaultValue=''
          name='email'
          control={control}
          rules={{
            required: true,
            pattern: /^.+@.+\..+$/,
          }}
        />
        {errors.email && (
          <Typography variant='caption' color='error'>
            {get(clinicianFormErrors.email, errors.email.type, '')}
          </Typography>
        )}
      </GridItem>
      <GridItem item xs={12} sm={4}>
        <Typography variant='subtitle2'>
          Clinic Name
          <Asterisk />
        </Typography>
        <CreatableDropdown
          options={clinics}
          control={control}
          error={errors}
          name='clinicName'
          disabled={view ? true : false}
        />
        {errors.clinicName && (
          <Typography variant='caption' color='error'>
            {get(clinicianFormErrors.clinicName, errors.clinicName.type, '')}
          </Typography>
        )}
      </GridItem>
      <GridItem item xs={12} sm={4}>
        <Typography variant='subtitle2'>
          Street
          <Asterisk />
        </Typography>
        <Controller
          as={
            <Input
              disabled={view ? true : false}
              size='small'
              InputProps={{
                readOnly: view ? true : false,
              }}
              variant='outlined'
            />
          }
          defaultValue=''
          name='street'
          control={control}
          rules={{
            required: true,
          }}
        />
        {errors.street && (
          <Typography variant='caption' color='error'>
            {get(clinicianFormErrors.street, errors.street.type, '')}
          </Typography>
        )}
      </GridItem>
      <GridItem item xs={12} sm={4}>
        <Typography variant='subtitle2'>
          City
          <Asterisk />
        </Typography>
        <Controller
          as={
            <Input
              disabled={view ? true : false}
              size='small'
              InputProps={{
                readOnly: view ? true : false,
              }}
              variant='outlined'
            />
          }
          defaultValue=''
          name='city'
          control={control}
          rules={{
            required: true,
          }}
        />
        {errors.city && (
          <Typography variant='caption' color='error'>
            {get(clinicianFormErrors.city, errors.city.type, '')}
          </Typography>
        )}
      </GridItem>
      <GridItem item xs={12} sm={4}>
        <Typography variant='subtitle2'>
          State
          <Asterisk />
        </Typography>
        <Dropdown
          fieldRequired={true}
          data={states}
          control={control}
          error={errors}
          name='state'
          label='state'
          disabled={view ? true : false}
        />
        {errors.state && (
          <Typography variant='caption' color='error'>
            {get(clinicianFormErrors.state, errors.state.type, '')}
          </Typography>
        )}
      </GridItem>
      <GridItem item xs={12} sm={4}>
        <Typography variant='subtitle2'>
          Zip
          <Asterisk />
        </Typography>
        <Controller
          as={
            <Input
              disabled={view ? true : false}
              size='small'
              InputProps={{
                readOnly: view ? true : false,
              }}
              variant='outlined'
            />
          }
          defaultValue=''
          name='zip'
          control={control}
          rules={{
            required: true,
            pattern: /^[0-9\b]+$/,
          }}
        />
        {errors.zip && (
          <Typography variant='caption' color='error'>
            {get(clinicianFormErrors.zip, errors.zip.type, '')}
          </Typography>
        )}
      </GridItem>
      <GridItem item xs={12} sm={4}>
        <Typography variant='subtitle2'>
          User Time Zone
          <Asterisk />
        </Typography>
        <Dropdown
          fieldRequired={true}
          data={userTimeZones}
          control={control}
          error={errors}
          label='timeZones'
          name='timeZones'
          disabled={view ? true : false}
        />
        {errors.timeZones && (
          <Typography variant='caption' color='error'>
            {get(clinicianFormErrors.timeZones, errors.timeZones.type, '')}
          </Typography>
        )}
      </GridItem>
      <GridItem item xs={12} sm={5}>
        <Typography variant='subtitle2'>
          Clinician Credentials
          <Asterisk />
        </Typography>
        <Dropdown
          fieldRequired={true}
          data={result}
          control={control}
          error={errors}
          name='credential'
          label='credential'
          disabled={view ? true : false}
        />
        {errors.credential && (
          <Typography variant='caption' color='error'>
            {get(clinicianFormErrors.state, errors.credential.type, '')}
          </Typography>
        )}
      </GridItem>
      {watch('credential').label === 'Other' && (
        <GridItem item xs={12} sm={4}>
          <Typography variant='subtitle2'>
            Other Credential
            <Asterisk />
          </Typography>
          <Controller
            as={
              <Input
                size='small'
                InputProps={{
                  readOnly: view ? true : false,
                }}
                variant='outlined'
              />
            }
            name='otherCredential'
            control={control}
            rules={{
              required: true,
            }}
          />
          {errors.otherCredential && (
            <Typography variant='caption' color='error'>
              {get(clinicianFormErrors.otherCredential, errors.otherCredential.type, '')}
            </Typography>
          )}
        </GridItem>
      )}
    </Grid>
  );
}

const mapStateToProps = (state: any) => ({
  states: state.list.statesData?.data?.result,
  clinicianCredentials: state.list.clinicianCredentials,
  clinicList: state.clinician?.clinicList,
});

export default connect(mapStateToProps)(ClinicianForm);
