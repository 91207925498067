import {
  Checkbox,
  Grid,
  styled,
  Table,
  TableContainer,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  TextField,
  Typography,
  LinearProgress,
  IconButton,
} from '@material-ui/core';
import { get } from 'lodash';
import React, { useEffect } from 'react';
import { Controller } from 'react-hook-form';
import { connect, useDispatch } from 'react-redux';
import DeleteIcon from '@material-ui/icons/Delete';
import { medFormErrors } from '../constants/errors';
import {
  getClinicainsRequest,
  getDiagnosisRequest,
  getGenderRequest,
  getPatientDetailsRequest,
  getTreatmentStatusRequest,
  unSetPatientDelegateRequest,
} from '../store/actions';
import Dropdown from './Dropdown';
import CustomButton from './CustomButton';

const GridItem = styled(Grid)({
  padding: '10px 20px !important',
});

const IconButtonWrapper = styled(IconButton)({
  padding: 0,
});
function DelegateForm({
  control,
  errors,
  list,
  watch,
  isAdmin,
  editData,
  edit,
  setValue,
  patient,
  clinicianList,
  onAdd,
  clinician,
}: any) {
  const dispatch = useDispatch();
  const { loading, patientDetails, cliniciansForDelegate } = patient;
  const clinicians: any = patientDetails?.data?.clinicians ? patientDetails?.data.clinicians : [];
  const clinicList = clinician?.clinicList?.data?.result;
  const cliniciansList =
    cliniciansForDelegate?.data?.data.result.map((item: any) => {
      return {
        id: item.id,
        label: `${item.first_name} ${item.last_name}`,
        name: item.first_name,
      };
    }) || [];
  const handleUndoDelegate = (data: any) => {
    dispatch(unSetPatientDelegateRequest(data, data.id_person_patient));
  };
  return (
    <Grid container spacing={5}>
      <TableContainer>
        {loading && <LinearProgress />}
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align='left'>Clinician</TableCell>
              <TableCell>Clinic Name</TableCell>
              <TableCell>NPI</TableCell>
              <TableCell>Primary</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          {clinicians.length !== 0 && (
            <TableBody>
              {clinicians.map((row: any) => (
                <TableRow key={row.id}>
                  <TableCell component='th' scope='row'>
                    {row.first_name}
                  </TableCell>
                  <TableCell>
                    {clinicList &&
                      clinicList.map((list) => {
                        if (list.id === row.id_org) return list.label;
                      })}
                  </TableCell>
                  <TableCell>{row.npi}</TableCell>
                  <TableCell>{row.is_primary_clinician ? 'Yes' : 'No'}</TableCell>
                  <TableCell>
                    {!row.is_primary_clinician && (
                      <IconButtonWrapper onClick={() => handleUndoDelegate(row)}>
                        <DeleteIcon color='error' />
                      </IconButtonWrapper>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
        {clinicians.length === 0 && (
          <GridItem xs={12} sm={12} style={{ textAlign: 'center' }}>
            No Data
          </GridItem>
        )}
        <GridItem xs={12} sm={12}>
          <Typography variant='subtitle2'>Clinician</Typography>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Dropdown
              fieldRequired={true}
              data={cliniciansList}
              control={control}
              error={errors}
              label='Clinician'
              name='delegateClinician'
            />
            <div>
              <CustomButton onClick={onAdd} width='min-content' margin={'0 0 0 1rem'}>
                <Typography variant='body1'>Add</Typography>
              </CustomButton>
            </div>
          </div>
          {/* {errors.clinician && (
              <Typography variant="caption" color="error">
                {get(patientFormErrors.clinician, errors.clinician.type, "")}
              </Typography>
            )} */}
        </GridItem>
      </TableContainer>
    </Grid>
  );
}

const mapStateToProps = (state: any) => ({
  patient: state.patient,
  clinician: state.clinician,
});

export default connect(mapStateToProps)(DelegateForm);
