//formatTime.ts
/**
 * Helper functions for formatting time data.
 *
 * @module
 * @author:
 * @copyright:
 */

import moment from 'moment-timezone';

const secondsToHms = (d: any) => {
  d = Number(d);
  const h = Math.floor(d / 3600);
  const m = Math.floor((d % 3600) / 60);
  const s = Math.floor((d % 3600) % 60);

  const hDisplay = h > 0 ? h : '00';
  const mDisplay = m > 0 ? (m < 10 ? `:0${m}` : ':' + m) : ':00';
  const sDisplay = s > 0 ? (s < 10 ? `:0${s}` : ':' + s) : ':00';
  return hDisplay + mDisplay + sDisplay;
};

/**
 * Function for producing a formatted date-time string.  Note that it is expected that the
 * input string is already in UTC.
 * @param utcDateTime String representing a date-time.  The expected format is
 * 'YYYY-MM-DDTHH:mm:ss.sssZ' (ex: 2023-01-20T09:29:31.527Z).
 * @returns Returns a timestamp in the format of 'MMM DD, YYYY hh:mm A' (ex:
 * Jan 20, 2023 09:29).  If exception occurs, fails, returns 'Invalid timestamp'.
 * If moment library cannot convert the string returns 'Invalid date'
 */
const getEstFormattedDateTime = (utcDateTime: string) => {
  try {
    //As per agreement with client, defaulting to EST for all displayed time
    //NOTE: Using 'moment' resulted in bizarre behavior and corrupted timestamps.
    //Views that use this function may call the function repeatedly when rendering.
    //It is unclear whether these multiple calls causes the unexpected behavior or something
    //else.
    const temp = moment.tz(utcDateTime, 'America/New_York').format('MMM DD, YYYY hh:mm A');
    return temp;
  } catch (err) {
    return 'Invalid timestamp';
  }
};

const getEstFormattedTime = (utcDateTime: string) => {
  try {
    //As per agreement with client, defaulting to EST for all displayed time

    const temp = moment.tz(utcDateTime, 'America/New_York').format('hh:mm A');
    return temp;
  } catch (err) {
    return 'Invalid time';
  }
};

export { secondsToHms, getEstFormattedDateTime, getEstFormattedTime };
