import { useState, MouseEvent, ChangeEvent } from 'react';
import { v4 } from 'uuid';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import CreateIcon from '@material-ui/icons/Create';
import VisibilityIcon from '@material-ui/icons/Visibility';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { styled } from '@material-ui/core/styles';
import { Button, LinearProgress } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import Moment from 'react-moment';

import theme from '../theme';
import ConfirmationModal from './ConfirmationModal';
import MenuDropdown from './MenuDropdown';
import { getAssignableDevicesRequest, getCurrentDeviceRequest } from '../store/actions';
import { useDispatch } from 'react-redux';
import { getEstFormattedDateTime } from '../utils/helper/formatTime';

const IconButtonWrapper = styled(IconButton)({
  backgroundColor: '#e5e5e5 !important',
  borderRadius: 8,
  padding: 5,
});

const TableFooter = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
});
const DeleteButtonWrapper = styled('div')({
  padding: theme.spacing(1),
});

const DeleteButton = styled(Button)({
  'backgroundColor': '#c62828',
  'textTransform': 'none',
  'color': '#ffffff',
  '&:hover': {
    backgroundColor: 'red',
  },
  '&:disabled': {
    backgroundColor: '#efd5d5',
    color: '#ffffff',
  },
});

const ButtonsContainer = styled(TableCell)({
  display: 'flex',
  justifyContent: 'space-evenly',
  padding: 11,
});

interface Data {
  id: string;
  first_name: string;
  gender_text: string;
  emr: string;
  patient_status_text: string;
}

type Order = 'asc' | 'desc';

interface EnhancedTableProps {
  classes: ReturnType<typeof useStyles>;
  headerCells: any;
  numSelected: number;
  onRequestSort: (event: MouseEvent<unknown>, property: keyof Data) => void;
  onSelectAllClick: (event: ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const {
    classes,
    headerCells,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;

  const createSortHandler = (property: keyof Data) => (event: MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding='checkbox'>
          <Checkbox
            color='primary'
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all desserts' }}
          />
        </TableCell>
        {headerCells.map((headCell: any) => {
          return (
            <TableCell
              key={v4()}
              align='left'
              padding='default'
              sortDirection={orderBy === headCell.id ? order : false}
            >
              {headCell.id !== 'options' && (
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : 'asc'}
                  onClick={createSortHandler(headCell.id)}
                >
                  {headCell.label}
                  {orderBy === headCell.id ? (
                    <span className={classes.visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </span>
                  ) : null}
                </TableSortLabel>
              )}
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
}

const ErrorText = styled(Typography)({
  padding: '1em',
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      // maxHeight: '85vh',
      marginBottom: theme.spacing(2),
      overflowY: 'auto',
      overflowX: 'hidden',
    },
    table: {
      'minWidth': 750,
      '& tr:nth-of-type(even)': {
        backgroundColor: 'rgba(0, 0, 0, 0.05)',
      },
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
  }),
);

interface CustomeTableProps {
  header: any;
  dataRows: Array<any>;
  loading: boolean;
  deleteRows: (selected: Array<string>) => void;
  onEdit: (data: any) => void;
  onDelegate?: any;
  onResendInvite?: any;
  onResetPassword?: any;
  onEditParameters?: any;
  onEditAdvancedParameters?: any;
  clinician?: boolean;
  patient?: boolean;
  onView?: any;
  handleDevices?: any;
  clinicList?: any;
}

export default function CustomTable({
  header,
  dataRows,
  loading,
  deleteRows,
  onEdit,
  onResetPassword,
  onEditParameters,
  onEditAdvancedParameters,
  onDelegate,
  patient,
  onView,
  handleDevices,
  clinicList,
  onResendInvite,
}: CustomeTableProps) {
  const classes = useStyles();
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof Data>(header[0].id);
  const [selected, setSelected] = useState<string[]>([]);
  const [open, setOpen] = useState(false);
  const history = useHistory();
  const {
    location: { pathname },
  } = history;

  const dispatch = useDispatch();

  const handleRequestSort = (event: MouseEvent<unknown>, property: keyof Data) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = dataRows.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const patientsOptionsMenu = [
    {
      key: 1,
      label: 'Reset Password',
      handleClick: (data: any) => onResetPassword(data),
    },
    {
      key: 2,
      label: 'Parameters',
      handleClick: (data: any) => {
        onEditParameters(data);
      },
    },
    {
      key: 3,
      label: 'Advanced Parameters',
      handleClick: (data: any) => {
        onEditAdvancedParameters(data);
      },
    },
    {
      key: 4,
      label: 'Delegate',
      handleClick: (data: any) => {
        onDelegate(data);
      },
    },
    {
      key: 5,
      label: 'Delete',
      handleClick: (data: any) => {
        setOpen(true);
      },
    },
  ];

  const clinicianOptionsMenu = [
    {
      key: 1,
      label: 'Resend Invite',
      handleClick: (data: any) => {
        onResendInvite(data);
      },
    },
    {
      key: 2,
      label: 'Devices',
      handleClick: (data: any) => {
        handleDevices(data);
        dispatch(getAssignableDevicesRequest(data.id));
        dispatch(getCurrentDeviceRequest(data.id));
      },
    },
    {
      key: 4,
      label: 'Delete',
      handleClick: (data: any) => {
        setOpen(true);
      },
    },
  ];

  const handleClick = (event: MouseEvent<unknown>, name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected?.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  const getSortedData = (data, orderBy, order) => {
    if (data) {
      let sortedData;
      const sortMethod = header.find((head) => head.id === orderBy).sortMethod;
      // get null values at data[orderBy], filter them out
      const nulls = data.filter((patient) => patient[orderBy] === null);
      // data without null values
      const newData = data.filter((patient) => patient[orderBy] !== null);

      const alphabeticSort = (a, b) => {
        if (order === 'asc') {
          return a[orderBy].localeCompare(b[orderBy]);
        } else {
          return b[orderBy].localeCompare(a[orderBy]);
        }
      };

      const numericSort = (a, b) => {
        if (order === 'asc') {
          return a[orderBy] - b[orderBy];
        } else {
          return b[orderBy] - a[orderBy];
        }
      };

      const dateSort = (a, b) => {
        if (order === 'asc') {
          if (new Date(a[orderBy]) > new Date(b[orderBy])) return 1;
          if (new Date(a[orderBy]) < new Date(b[orderBy])) return -1;
          return 0;
        } else {
          if (new Date(a[orderBy]) > new Date(b[orderBy])) return -1;
          if (new Date(a[orderBy]) < new Date(b[orderBy])) return 1;
          return 0;
        }
      };

      // use appropriate sort function depending on orderBy property's sortMethod
      sortMethod === 'date'
        ? (sortedData = newData.sort(dateSort))
        : sortMethod === 'alpha'
        ? (sortedData = newData.sort(alphabeticSort))
        : sortMethod === 'numeric'
        ? (sortedData = newData.sort(numericSort))
        : (sortedData = newData.sort((a, b) => (b[orderBy] = a[orderBy])));
      // append filtered out nulls
      sortedData.push(...nulls);

      return sortedData;
    }
  };

  const tableRows =
    dataRows &&
    getSortedData(dataRows, orderBy, order).map((row: any, index) => {
      const isItemSelected = isSelected(row.id);
      const labelId = `enhanced-table-checkbox-${index}`;

      return (
        <TableRow
          hover
          role='checkbox'
          aria-checked={isItemSelected}
          tabIndex={-1}
          key={row.id}
          selected={isItemSelected}
        >
          <TableCell onClick={(event) => handleClick(event, row.id)} padding='checkbox'>
            <Checkbox color='primary' checked={isItemSelected} />
          </TableCell>
          <TableCell align='left'>{row.id}</TableCell>
          {pathname === '/clinicians' && (
            <TableCell component='th' id={labelId} scope='row'>
              {row.first_name} {row.last_name}
            </TableCell>
          )}
          {pathname === '/clinicians' && (
            <TableCell align='left'>
              {clinicList &&
                clinicList.map((list) => {
                  if (list.id === row.id_org) return list.label;
                })}
            </TableCell>
          )}
          {pathname === '/clinicians' && <TableCell align='left'>{row.npi}</TableCell>}
          {pathname === '/patients' && (
            <TableCell align='left'>{row.patient_display_name}</TableCell>
          )}
          {pathname === '/patients' && (
            <TableCell align='left'>{row.gender_text ? row.gender_text : '-'}</TableCell>
          )}
          {pathname === '/patients' && (
            <TableCell align='left'>{row.emr ? row.emr : '-'}</TableCell>
          )}
          {pathname === '/patients' && (
            <TableCell align='left'>
              {row.patient_status_text ? row.patient_status_text : '-'}
            </TableCell>
          )}
          {pathname === '/clinicians' && <TableCell align='left'>{row.email}</TableCell>}
          {pathname === '/clinicians' && <TableCell align='left'>{row.address1}</TableCell>}
          {pathname === '/clinicians' && (
            <TableCell align='left'>{getEstFormattedDateTime(row.created_date)}</TableCell>
          )}
          <ButtonsContainer align='center'>
            {[
              <Tooltip title={`${patient ? 'User Details' : 'View Clinician'}`}>
                <VisibilityIcon onClick={() => onView(row, row.id)} />
              </Tooltip>,
              <Tooltip title={`Edit ${patient ? 'User/Diagnosis' : 'Clinician'}`}>
                <CreateIcon color='primary' onClick={() => onEdit(row)} />
              </Tooltip>,
              // pathname === "/patients" && (
              //   <Tooltip title="Edit Diagnosis">
              //     <DescriptionIcon
              //       onClick={() => onEditDiagnosis(row)}
              //     />
              //   </Tooltip>
              // ),
              <MenuDropdown items={patient ? patientsOptionsMenu : clinicianOptionsMenu} data={row}>
                <Tooltip title='More Options'>
                  <MoreVertIcon
                    onClick={(event) => {
                      selected.length = 0;
                      handleClick(event, row.id);
                    }}
                  />
                </Tooltip>
              </MenuDropdown>,
            ].map((item) => {
              return (
                item && (
                  <IconButtonWrapper
                    disabled={selected.length > 1}
                    // onClick={() => {}}
                    size='small'
                    color='default'
                  >
                    {item}
                  </IconButtonWrapper>
                )
              );
            })}
          </ButtonsContainer>
        </TableRow>
      );
    });

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        {loading && <LinearProgress />}
        <Table className={classes.table} size={'small'} aria-label='enhanced table'>
          <EnhancedTableHead
            classes={classes}
            headerCells={header}
            numSelected={selected?.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={dataRows?.length}
          />
          {dataRows && <TableBody>{tableRows}</TableBody>}
        </Table>
        {dataRows?.length === 0 && (
          <ErrorText variant='body1' align='center'>
            No Data
          </ErrorText>
        )}
        {dataRows && (
          <TableFooter>
            <DeleteButtonWrapper>
              <DeleteButton
                variant='contained'
                disabled={selected.length === 0}
                onClick={() => setOpen(true)}
              >
                <Typography>Delete</Typography>
              </DeleteButton>
            </DeleteButtonWrapper>
          </TableFooter>
        )}
      </Paper>
      <ConfirmationModal
        modalOpen={open}
        modalClose={() => {
          setOpen(false);
          setSelected([]);
        }}
        onClick={() => {
          deleteRows(selected);
          setOpen(false);
        }}
        title='Delete'
      >
        {patient
          ? 'Are you sure you want to delete this User?'
          : 'Are you sure you want to delete this Clinician?'}
      </ConfirmationModal>
    </div>
  );
}
