export const ON_SESSION_DETAILS_REQUEST = 'ON_SESSION_DETAILS_REQUEST';
export const ON_SESSION_DETAILS_SUCCESS = 'ON_SESSION_DETAILS_SUCCESS';
export const ON_SESSION_DETAILS_FAILED = 'ON_SESSION_DETAILS_FAILED';

export const ON_SESSION_VISUAL_DETAILS_REQUEST = 'ON_SESSION_VISUAL_DETAILS_REQUEST';
export const ON_SESSION_VISUAL_DETAILS_SUCCESS = 'ON_SESSION_VISUAL_DETAILS_SUCCESS';
export const ON_SESSION_VISUAL_DETAILS_FAILED = 'ON_SESSION_VISUAL_DETAILS_FAILED';

export const ON_SESSION_VISUAL_TIME_DETAILS_REQUEST = 'ON_SESSION_VISUAL_TIME_DETAILS_REQUEST';
export const ON_SESSION_VISUAL_TIME_DETAILS_SUCCESS = 'ON_SESSION_VISUAL_TIME_DETAILS_SUCCESS';
export const ON_SESSION_VISUAL_TIME_DETAILS_FAILED = 'ON_SESSION_VISUAL_TIME_DETAILS_FAILED';

export const ON_SESSION_HEATMAP_REQUEST = 'ON_SESSION_HEATMAP_REQUEST';
export const ON_SESSION_HEATMAP_SUCCESS = 'ON_SESSION_HEATMAP_SUCCESS';
export const ON_SESSION_HEATMAP_FAILED = 'ON_SESSION_HEATMAP_FAILED';
