import {
  ON_GET_CLINICIANS_DATA_REQUEST,
  ON_GET_CLINICIANS_DATA_SUCCESS,
  ON_GET_CLINICIANS_DATA_FAILED,
  ON_SET_CLINICIAN_REQUEST,
  ON_SET_CLINICIAN_SUCCESS,
  ON_SET_CLINICIAN_FAILED,
  ON_DELETE_CLINICIAN_REQUEST,
  ON_DELETE_CLINICIAN_SUCCESS,
  ON_DELETE_CLINICIAN_FAILED,
  ON_UPDATE_CLINICIAN_FAILED,
  ON_UPDATE_CLINICIAN_REQUEST,
  ON_UPDATE_CLINICIAN_SUCCESS,
  ON_GET_CLINIC_FAILED,
  ON_GET_CLINIC_REQUEST,
  ON_GET_CLINIC_SUCCESS,
} from '../actionTypes';

export const getClinicainsRequest = () => ({
  type: ON_GET_CLINICIANS_DATA_REQUEST,
});

export const getCliniciansSuccess = (data: any) => ({
  type: ON_GET_CLINICIANS_DATA_SUCCESS,
  payload: data,
});

export const getCliniciansFailed = (data: any) => ({
  type: ON_GET_CLINICIANS_DATA_FAILED,
  payload: data,
});
export const setClinicainRequest = (data: any, toast?: (state: boolean) => void) => ({
  type: ON_SET_CLINICIAN_REQUEST,
  data,
  toast,
});

export const setClinicianSuccess = (data: any) => ({
  type: ON_SET_CLINICIAN_SUCCESS,
  payload: data,
});

export const setClinicianFailed = (data: any) => ({
  type: ON_SET_CLINICIAN_FAILED,
  payload: data,
});
export const deleteClinicainRequest = (data: any, toast?: (state: boolean) => void) => ({
  type: ON_DELETE_CLINICIAN_REQUEST,
  data,
  toast,
});

export const deleteClinicianSuccess = (data: any) => ({
  type: ON_DELETE_CLINICIAN_SUCCESS,
  payload: data,
});

export const deleteClinicianFailed = (data: any) => ({
  type: ON_DELETE_CLINICIAN_FAILED,
  payload: data,
});

export const updateClinicianRequest = (
  data: any,
  clinicianID: number,
  toast?: (state: boolean) => void,
) => ({
  type: ON_UPDATE_CLINICIAN_REQUEST,
  data,
  clinicianID,
  toast,
});

export const updateClinicianSuccess = (data: any) => ({
  type: ON_UPDATE_CLINICIAN_SUCCESS,
  payload: data,
});

export const updateClinicianFailed = (data: any) => ({
  type: ON_UPDATE_CLINICIAN_FAILED,
  payload: data,
});

export const getClinicListRequest = () => ({
  type: ON_GET_CLINIC_REQUEST,
});

export const getClinicListSuccess = (data: any) => ({
  type: ON_GET_CLINIC_SUCCESS,
  payload: data,
});

export const getClinicListFailed = (data: any) => ({
  type: ON_GET_CLINIC_FAILED,
  payload: data,
});
