export const DASHBOARD_PATH = '/dashboard';

export const PATIENTS_PATH = '/patients';

export const CLINICIANS_PATH = '/clinicians';

export const PATIENT_DETAILS_PATH = '/patient-details';

export const AUTH_CHECK_PATH = '/auth-check';

export const SESSION_VISUAL_PATH = '/session-visuals';

export const DEVICES_PATH = '/devices';
