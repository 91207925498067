import {
  ON_SET_PARAMETER_REQUEST,
  ON_SET_PARAMETER_FAILED,
  ON_SET_PARAMETER_SUCCESS,
} from './../actionTypes/parameter.actionTypes';

const parameterInitialState = {
  loading: true,
  data: {},
  error: null,
};

const patientReducer = (state = parameterInitialState, action: any) => {
  switch (action.type) {
    case ON_SET_PARAMETER_REQUEST:
      return { ...state, loading: true };
    case ON_SET_PARAMETER_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null,
      };
    case ON_SET_PARAMETER_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default patientReducer;
