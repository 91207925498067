import React from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles, Theme } from '@material-ui/core/styles';

interface ButtonProps {
  children: any;
  onClick: Function;
  height?: string;
  width?: string;
  padding?: string;
  margin?: string;
}
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundImage: 'linear-gradient(90deg, #007CBA 0%, #1A428A 100%)',
    color: '#FFFFFF',
    width: (props: ButtonProps) => props.width,
    height: (props: ButtonProps) => props.height,
    padding: (props: ButtonProps) => props.padding,
    margin: (props: ButtonProps) => props.margin,
    fontSize: 16,
    fontWeight: 500,
    textTransform: 'none',
  },
}));

function CustomButton(props: ButtonProps) {
  const classes = useStyles(props);
  return (
    <Button fullWidth className={classes.root} onClick={() => props.onClick()} variant='contained'>
      {props.children}
    </Button>
  );
}

export default CustomButton;
