//SessionVisuals.tsx
/**
 * View for presenting session data in a visual format (table, graphs, etc.)
 *
 * @module
 * @author:
 * @copyright:
 */

import React, { useEffect, useState } from 'react';
import { v4 } from 'uuid';
import { isEmpty } from 'lodash';
import { createStyles, makeStyles, Theme, withStyles } from '@material-ui/core/styles';
import {
  Dialog,
  Button,
  CircularProgress,
  FormControl,
  MenuItem,
  Select,
  Snackbar,
  Tooltip,
  Typography,
  useMediaQuery,
  DialogTitle,
  DialogContent,
  IconButton,
  Link,
} from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import AssignmentIcon from '@material-ui/icons/Assignment';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Skeleton from '@material-ui/lab/Skeleton';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import exporting from 'highcharts/modules/exporting';
import highchartsHeatmap from 'highcharts/modules/heatmap';
import boost from 'highcharts/modules/boost';
import { Options } from 'highcharts';
import CustomButton from '../components/CustomButton';
import MenuDropdown from '../components/MenuDropdown';
import NoDataCard from '../components/NoDataCard';
import { connect, useDispatch } from 'react-redux';
import { get } from 'lodash';
import moment from 'moment-timezone';
import CloseIcon from '@material-ui/icons/Close';
import { styled } from '@material-ui/core/styles';
import {
  sessionDetailsRequest,
  sessionVisualDetailsRequest,
  sessionHeatmapRequest,
} from '../store/actions';
import { download, getRawData, getCSVData } from '../utils/services';
import UserForm from '../components/UserForm';
import { initiateSessionProcessing } from '../utils/services/patient';
import Alert from '@material-ui/lab/Alert';
//import FileDownload from 'js-file-download';
//import Alert from '@material-ui/lab/Alert';
import { getEstFormattedDateTime, getEstFormattedTime } from '../utils/helper/formatTime';
exporting(Highcharts);
highchartsHeatmap(Highcharts);
boost(Highcharts);

interface LooseObject {
  [key: string]: string | boolean | number[];
}

const legendMap: LooseObject = {
  1: `SPL (dB)`,
  2: `f0 (Hz)`,
  3: `ACC (dB)`,
  4: `CPP (dB)`,
  5: `H1H2 (dB)`,
};

const legendKeyMap: LooseObject = {
  1: `spl_db_`,
  2: `fund_freq_hz_`,
  3: `acc_db_`,
  4: `cpp_db_`,
  5: `h1_h2_db_`,
};

const legendKeyVoicedMap: LooseObject = {
  1: `spl_`,
  2: `f0_`,
  3: `acc_`,
  4: `cpp_`,
  5: `h1_h2_`,
};

const legendKeyReportdMap: LooseObject = {
  1: `spl`,
  2: `f0`,
  3: `acc`,
  4: `cpp`,
  5: `h1h2`,
};

const legendKeyBarMap: LooseObject = {
  1: `SPL_`,
  2: `f0_`,
  3: `ACC_`,
  4: `CPP_`,
  5: `H1H2_`,
};

const heatmapMetrics: LooseObject = {
  1: 'spl_db_avg',
  2: 'fund_freq_hz_mode',
  3: 'acc_db_avg',
  4: 'cpp_db_avg',
  5: 'h1_h2_db_mode',
};

const metricRangeMap: LooseObject = {
  1: [45, 130], // spl
  2: [70, 1000], // f0
  3: [10, 100], // acc
  4: [10, 35], // cpp
  5: [-30, 40], // h1h2
};

interface SessionVisualProps {
  patient: any;
  location: any;
  sessionDetails: any;
  sessionListData: any;
  history: any;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: 'white',
    },
    body: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: '2em',
    },
    profileContainer: {
      display: 'flex',
      height: 100,
      width: '100%',
      backgroundColor: 'white',
      borderRadius: 4,
      borderWidth: 0.7,
      borderColor: 'rgba(0, 0, 0, 0.12)',
      marginBottom: 24,
      borderStyle: 'solid',
      padding: 12,
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    noDataContainer: {
      border: '1px solid lightgrey',
      borderRadius: '8px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    infoContainer: {
      display: 'flex',
      width: '100%',
      borderRadius: 3,
      marginBottom: 24,
      justifyContent: 'space-between',
    },
    chatText: {
      color: 'rgb(51, 51, 51)',
      fontSize: 14,
      fontWeight: 'bold',
    },
    deviceParentContainer: {
      flex: 1,
      borderRadius: 4,
      display: 'flex',
      maxHeight: 250,
    },
    deviceContainer: {
      flex: 1,
      marginRight: 24,
      height: 170,
      borderRadius: 4,
      borderColor: 'rgba(0, 0, 0, 0.12)',
      borderWidth: 0.1,
      borderStyle: 'solid',
      borderTopWidth: 0,
      overflow: 'auto',
    },
    deviceLogoContainer: {
      width: '100%',
      borderRadius: '4,4,0,0',
      backgroundColor: '#007cba',
      padding: 12,
      display: 'flex',
      alignItems: 'center',
    },
    deviceIcon: { height: 12, width: 12 },
    deviceText: {
      color: 'white',
      marginLeft: 10,
      fontSize: 16,
      fontWeight: 'bold',
    },
    deviceTextHeader: {
      color: 'rgb(109, 109, 109)',
      // marginLeft: 10,
      fontSize: 14,
      fontWeight: 'normal',
    },
    deviceTextInfo: {
      color: 'rgb(51, 51, 51)',
      // marginLeft: 10,
      fontSize: 14,
      fontWeight: 'normal',
    },
    clinicContainer: { marginTop: 24, maxHeight: 250 },
    clinicianLogo: {
      // width: "100%",
      borderRadius: 4,
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      backgroundColor: 'rgb(40, 167, 69)',
      padding: 12,
      display: 'flex',
      marginRight: 24,
      alignItems: 'center',
    },
    clinicListContainer: {
      borderStyle: 'solid',
      borderWidth: 0.7,
      borderColor: 'rgba(0, 0, 0, 0.12)',
      marginRight: 24,
      borderBottomRightRadius: 4,
      borderBottomLeftRadius: 4,
      overflow: 'auto',
    },
    sessionContainer: {
      flex: 1,
      marginRight: 24,
      height: '100%',
      borderRadius: 4,
      borderColor: 'rgba(0, 0, 0, 0.12)',
      borderWidth: 0.7,
      borderStyle: 'solid',
      // overflow: "auto",
    },
    sessionLogoContainer: {
      borderRadius: 4,
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      backgroundColor: '#6c757d',
      padding: 8,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    sessionIcon: { height: 18, width: 18 },
    parameterLogoContainer: {
      flex: 1,
      borderRadius: 4,
      borderColor: 'rgba(0, 0, 0, 0.12)',
      borderWidth: 0.7,
      borderStyle: 'solid',
    },
    deviceIconContainer: {
      width: '100%',
      borderRadius: 3,
      backgroundColor: '#ff7f30',
      padding: 8,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    chatContainer: {
      flex: 1,
      borderRadius: 4,
      borderColor: 'rgba(0, 0, 0, 0.12)',
      borderWidth: 0.7,
      borderStyle: 'solid',
      marginTop: 24,
    },
    chatHeaderContainer: {
      width: '100%',
      borderRadius: 3,
      backgroundColor: 'rgb(0, 123, 255)',
      padding: 8,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    chatAddContainer: {
      padding: 4,
      justifyContent: 'center',
      alignItems: 'center',
      // backgroundColor: "rgb(0, 0, 0)",
      borderRadius: 2,
      borderColor: '#FFFFFF',
      borderStyle: 'solid',
      borderWidth: 0.1,
      position: 'absolute',
      // opacity: 0.2,
    },
    chatAdd: {
      color: '#FFFFFF',
      fontSize: 13,
      opacity: 1,
      // fontWeight: "500",
    },
    parameterParentContainer: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
    },
    muiRoot: {
      maxWidth: 'unset',
      backgroundColor: 'red',
    },
    sidePanelContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      flex: 1,
      height: '100%',
      backgroundColor: 'white',
    },
    sessionInfoContainer: {
      maxHeight: 200,
      flex: 1.5,
      display: 'flex',
      borderStyle: 'solid',
      borderColor: 'rgba(0, 0, 0, 0.12)',
      borderWidth: 0.7,
      padding: 8,
      marginTop: 12,
    },
    sessionInfoContainer1: {
      width: 200,
      borderStyle: 'solid',
      borderWidth: 0.7,
      padding: 8,
      marginTop: 12,
    },
    sessionNamePatient: {
      color: 'gray',
      marginLeft: 4,
    },
    sessionTmspContainer: {
      display: 'flex',
      alignItems: 'center',
      paddingLeft: 8,
    },
    header: {
      fontWeight: 'bold',
    },
    biofeedbackItem: {
      fontSize: 18,
    },
    selectSession: {
      margin: theme.spacing(1),
      width: '70%',
    },
    formControl: {
      margin: theme.spacing(1),
      width: '33%',
    },
  }),
);

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.action.hover,
    width: '1%',
    paddingBottom: 2,
    paddingTop: 2,
    height: 35,
    fontWeight: 'bold',
  },
  body: {
    fontSize: 16,
    paddingBottom: 2,
    paddingTop: 2,
  },
}))(TableCell);

const IconButtonWrapper = styled(IconButton)({
  position: 'absolute',
  right: 5,
  top: 0,
});
const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      // backgroundColor: theme.palette.action.hover,
    },
    'width': '50%',
    'height': 35,
  },
}))(TableRow);

function SessionVisuals(props: SessionVisualProps) {
  const [reportForm, setReportForm] = useState(false);
  const [reportDownload, setReportDownload] = useState(false);
  const [rawDataDownload, setRawDataDownload] = useState(false);
  const [sessionDataDownload, setSessionDataDownload] = useState(false);
  // for setting displayed metric 1 and metric 2 in inputs
  const [metric1, setMetric1] = useState(1);
  const [metric2, setMetric2] = useState(2);
  const [dialog, setDialog] = React.useState(false);
  const [dataURL, setDataURL] = useState<any>('');
  const [downloadSuccess, setDownloadSuccess] = React.useState(true);
  const [open, setOpen] = React.useState(false);
  const [selectedSessionIndex, setSelectedSessionIndex] = useState(0);
  // for setting displayed session id in input
  const [sessionId, setSessionId] = useState(props.location.state.sessionId);
  // for setting actual sessionId, metric1, and metric2 all charts are using
  const [metrics, setMetrics] = useState([sessionId, 1, 2]);
  const { sessionListData } = props;
  const { sessionsDetailsData, sessionVisualData, sessionHeatmapData } = props.sessionDetails;
  const timeSeriesData = get(sessionsDetailsData, 'data', []);
  const columnData = get(sessionVisualData, 'data.data', {});
  const heatmapData = get(sessionHeatmapData, 'data.data', {});

  const patientDetailsInfo = get(props.location, 'state.data', {});
  const patientDetails = get(props.patient, 'patientDetails.data', {});
  const patientId = get(props.location, 'state.id', '');
  const isLaptopFloor = useMediaQuery('(max-width: 1440px)');
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    setSessionId(sessionId);
    getInitData();
  }, []);

  // call lambda functions again if sessionId, metric1, or metric2 have changed in metrics array
  // metrics array is watched because it only changes with click of Update button
  useEffect(() => {
    getInitData();
  }, [metrics]);

  useEffect(() => {
    const newSessionIndex = sessionListData.findIndex((item) => item.id === sessionId);
    setSelectedSessionIndex(newSessionIndex);
  }, [sessionId]);

  const getInitData = () => {
    dispatch(
      sessionDetailsRequest({
        id: patientId,
        sessionId: metrics[0],
      }),
    );

    dispatch(
      sessionVisualDetailsRequest({
        id: patientId,
        sessionId: metrics[0],
      }),
    );

    dispatch(
      sessionHeatmapRequest({
        id: patientId,
        sessionId: metrics[0],
        metric1: heatmapMetrics[metrics[1]],
        metric2: heatmapMetrics[metrics[2]],
      }),
    );
  };

  const getTimeSeriesOptions: (isHz?: boolean) => Options = (isHz) => {
    const chart1y1: number[] = timeSeriesData[`${legendKeyMap[metrics[1]]}mean`];
    const chart1y2: number[] = timeSeriesData[`${legendKeyMap[metrics[1]]}max`];
    const chart2y1: number[] = timeSeriesData[`${legendKeyMap[metrics[2]]}mean`];
    const chart2y2: number[] = timeSeriesData[`${legendKeyMap[metrics[2]]}max`];
    const voiced_percent1: number[] = timeSeriesData[`${legendKeyVoicedMap[metrics[1]]}voiced`];
    const voiced_percent2: number[] = timeSeriesData[`${legendKeyVoicedMap[metrics[2]]}voiced`];
    const voiced_low1: number[] = timeSeriesData[`${legendKeyVoicedMap[metrics[1]]}voiced_low`];
    const voiced_high1: number[] = timeSeriesData[`${legendKeyVoicedMap[metrics[1]]}voiced_high`];
    const voiced_low2: number[] = timeSeriesData[`${legendKeyVoicedMap[metrics[2]]}voiced_low`];
    const voiced_high2: number[] = timeSeriesData[`${legendKeyVoicedMap[metrics[2]]}voiced_high`];

    //NOTE: Refer to README re: use of timezone
    //This handles the case when data has not been uploaded to the server
    const chart1x = !timeSeriesData
      ? []
      : timeSeriesData.hasOwnProperty('record_date')
      ? timeSeriesData[`record_date`].map((item) => getEstFormattedTime(item))
      : [];
    const metricLabel = legendMap[isHz ? metrics[2] : metrics[1]];

    return {
      xAxis: {
        categories: chart1x,
        tickmarkPlacement: 'on',
        labels: {
          style: {
            fontSize: '.9rem',
          },
        },
      },
      legend: {
        itemStyle: {
          fontSize: '.9rem',
        },
      },
      title: {
        text: '',
      },
      chart: {
        height: 300,
        zoomType: 'xy',
      },
      tooltip: {
        formatter: function () {
          return this.point.series.name + ': <b>' + Math.round(this.point.y as number) + '</b>';
        },
      },
      credits: {
        enabled: false,
      },
      plotOptions: {
        area: {
          stacking: 'normal',
          lineColor: '#666666',
          lineWidth: 1,
          marker: {
            lineWidth: 1,
            lineColor: '#666666',
          },
        },
      },
      series: [
        {
          type: 'line',
          yAxis: 1,
          color: 'purple',
          name: `Mean ${metricLabel}`,
          data: isHz ? chart2y1 : chart1y1,
        },
        {
          type: 'line',
          name: `Max ${metricLabel}`,
          yAxis: 1,
          color: '#b19cd9', // light purple
          data: isHz ? chart2y2 : chart1y2,
        },
        {
          type: 'area',
          name: 'Above Threshold (%)',
          yAxis: 0,
          color: '#BE7A7B', // pastel red
          data: isHz ? voiced_high2 : voiced_high1,
        },
        {
          type: 'area',
          name: `Phonation${'\n'} Time (%)`,
          yAxis: 0,
          color: '#a6a6a6',
          data: isHz ? voiced_percent2 : voiced_percent1, // grey
        },
        {
          type: 'area',
          name: 'Below Threshold (%)',
          yAxis: 0,
          color: '#8BB7C8', // pastel blue
          data: isHz ? voiced_low2 : voiced_low1,
        },
      ],
      yAxis: [
        {
          title: {
            useHTML: true,
            style: {
              fontSize: '1rem',
            },
            text: `<b>Phonation${`\n`} Time (%)</b>`,
          },
          labels: {
            style: {
              fontSize: '.9rem',
            },
            format: '{value}',
          },
          startOnTick: false,
          endOnTick: false,
          min: 0,
          max: 100,
          gridLineColor: 'transparent',
        },
        {
          title: {
            useHTML: true,
            style: {
              fontSize: '.9rem',
            },
            text: `<b>${metricLabel}</b>`,
          },
          opposite: true,
          labels: {
            style: {
              fontSize: '.9rem',
            },
            format: '{value}',
          },
          startOnTick: false,
          endOnTick: false,
          min: metricRangeMap[isHz ? metrics[2] : metrics[1]][0],
          max: metricRangeMap[isHz ? metrics[2] : metrics[1]][1],
          gridLineColor: 'transparent',
        },
      ],
    };
  };
  function downloadRawData() {
    setRawDataDownload(true);
    setDialog(true);
    setDownloadSuccess(false);
    getRawData(sessionId)
      .then((resp) => {
        setDownloadSuccess(true);
        setDataURL(resp.url);
        setRawDataDownload(false);
      })
      .catch(() => {
        setDownloadSuccess(false);
        setRawDataDownload(false);
      });
  }
  function downloadSessionData() {
    setSessionDataDownload(true);
    setDownloadSuccess(false);
    setDialog(true);
    getCSVData(sessionId)
      .then((resp) => {
        setDownloadSuccess(true);
        setDataURL(resp.url);
        setSessionDataDownload(false);
      })
      .catch(() => {
        setDownloadSuccess(false);
        setSessionDataDownload(false);
      });
  }
  function generate(data: any): void {
    const sessionDetails = get(props, 'sessionListData', []).filter(
      (option: any) => option.id === sessionId,
    );
    const subject = {
      ...data,
      treatmentStatus: data.treatmentStatus.label,
      gender: data.gender.label,
      //NOTE: Refer to README re: use of timezone
      session_date: moment
        .tz(sessionDetails[0].start_date, 'America/New_York')
        .format('MM/DD/YYYY'),
      start_time: getEstFormattedTime(sessionDetails[0].start_date),
      end_time: getEstFormattedTime(sessionDetails[0].end_date),
    };

    setReportDownload(true);
    setReportForm(false);
    download(
      `/patient/${patientId}/session/${sessionId}/report/${legendKeyReportdMap[metrics[1]]}/${
        legendKeyReportdMap[metrics[2]]
      }`,
      subject,
      `Patient-${patientId}-Session-${sessionId}.docx`,
      false,
    )
      .then(() => setReportDownload(false))
      .catch(() => setReportDownload(false));
  }

  function createData(
    measure: string,
    mean: string,
    sd: string,
    min: string,
    max: string,
    skewness: string,
  ) {
    return { measure, mean, sd, min, max, skewness };
  }

  const rows = [
    createData(
      'Mic SPL (dB SPL)',
      get(props, 'sessionDetails.sessionVisualData.data.data.SPL_mean', 0).toFixed(2).toString(),
      get(props, 'sessionDetails.sessionVisualData.data.data.SPL_SD', 0).toFixed(2).toString(),
      get(props, 'sessionDetails.sessionVisualData.data.data.SPL_fifth_pct', 0)
        .toFixed(2)
        .toString(),
      get(props, 'sessionDetails.sessionVisualData.data.data.SPL_ninefifth_pct', 0)
        .toFixed(2)
        .toString(),
      get(props, 'sessionDetails.sessionVisualData.data.data.SPL_skew', 0).toFixed(2).toString(),
    ),
    createData(
      'ACC (dB)',
      get(props, 'sessionDetails.sessionVisualData.data.data.ACC_mean', 0).toFixed(2).toString(),
      get(props, 'sessionDetails.sessionVisualData.data.data.ACC_SD', 0).toFixed(2).toString(),
      get(props, 'sessionDetails.sessionVisualData.data.data.ACC_fifth_pct', 0)
        .toFixed(2)
        .toString(),
      get(props, 'sessionDetails.sessionVisualData.data.data.ACC_ninefifth_pct', 0)
        .toFixed(2)
        .toString(),
      get(props, 'sessionDetails.sessionVisualData.data.data.ACC_skew', 0).toFixed(2).toString(),
    ),
    createData(
      'f0 (Hz)',
      get(props, 'sessionDetails.sessionVisualData.data.data.f0_mean', 0).toFixed(2).toString(),
      get(props, 'sessionDetails.sessionVisualData.data.data.f0_SD', 0).toFixed(2).toString(),
      get(props, 'sessionDetails.sessionVisualData.data.data.f0_fifth_pct', 0)
        .toFixed(2)
        .toString(),
      get(props, 'sessionDetails.sessionVisualData.data.data.f0_ninefifth_pct', 0)
        .toFixed(2)
        .toString(),
      get(props, 'sessionDetails.sessionVisualData.data.data.f0_skew', 0).toFixed(2).toString(),
    ),
    createData(
      'CPP (dB)',
      get(props, 'sessionDetails.sessionVisualData.data.data.CPP_mean', 0).toFixed(2).toString(),
      get(props, 'sessionDetails.sessionVisualData.data.data.CPP_SD', 0).toFixed(2).toString(),
      get(props, 'sessionDetails.sessionVisualData.data.data.CPP_fifth_pct', 0)
        .toFixed(2)
        .toString(),
      get(props, 'sessionDetails.sessionVisualData.data.data.CPP_ninefifth_pct', 0)
        .toFixed(2)
        .toString(),
      get(props, 'sessionDetails.sessionVisualData.data.data.CPP_skew', 0).toFixed(2).toString(),
    ),
    createData(
      'H1H2 (dB)',
      get(props, 'sessionDetails.sessionVisualData.data.data.H1H2_mean', 0).toFixed(2).toString(),
      get(props, 'sessionDetails.sessionVisualData.data.data.H1H2_SD', 0).toFixed(2).toString(),
      get(props, 'sessionDetails.sessionVisualData.data.data.H1H2_fifth_pct', 0)
        .toFixed(2)
        .toString(),
      get(props, 'sessionDetails.sessionVisualData.data.data.H1H2_ninefifth_pct', 0)
        .toFixed(2)
        .toString(),
      get(props, 'sessionDetails.sessionVisualData.data.data.H1H2_skew', 0).toFixed(2).toString(),
    ),
  ];

  //NOTE: 2023-03-13: Removed the patient report as a downloadable b/c there were errors in its
  //generation.  Refer to commit aa39fb5 (or earlier) for returning the code.
  const Downloadables = () => {
    return (
      <>
        <Tooltip title='Raw acceleration data'>
          <GetAppIcon
            color='primary'
            style={{ cursor: 'pointer', marginLeft: 15 }}
            onClick={downloadRawData}
          />
        </Tooltip>
        <Tooltip title='50ms phonation data'>
          <GetAppIcon
            style={{ cursor: 'pointer', color: '#ff7f30', marginLeft: 10 }}
            onClick={downloadSessionData}
          />
        </Tooltip>
      </>
    );
  };
  const getHeatmapOptions: () => Options = () => {
    const data: number[][] = [];
    for (let i = 0; i < heatmapData.histx.length; i++) {
      if (heatmapData.histmag[i] > 0) {
        data.push([heatmapData.histx[i], heatmapData.histy[i], heatmapData.histmag[i]]);
      }
    }

    const lowX: number = Math.min(...data.map((point) => point[0])) - 10;
    const highX: number = Math.max(...data.map((point) => point[0])) + 10;
    const lowY: number = Math.min(...data.map((point) => point[1])) - 10;
    const highY: number = Math.max(...data.map((point) => point[1])) + 10;
    const lowMag: number = Math.min(...data.map((point) => point[2]));
    const highMag: number = Math.max(...data.map((point) => point[2]));

    return {
      chart: {
        type: 'heatmap',
        zoomType: 'xy',
        height: 450,
        width: !isLaptopFloor ? 600 : 410,
        marginRight: 50,
      },
      boost: {
        useGPUTranslations: true,
        // boost when there are more than 5 series in the chart
        seriesThreshold: 5,
      },
      credits: {
        enabled: false,
      },
      title: {
        text: '',
      },
      xAxis: {
        title: {
          useHTML: true,
          style: {
            fontSize: '1rem',
          },
          text: `<b>${legendMap[metrics[1]]}</b>` as string,
        },
        labels: {
          style: {
            fontSize: '.9rem',
          },
          format: '{value}',
        },
        showLastLabel: true,
        tickLength: 10,
        min: lowX,
        max: highX,
        startOnTick: false,
        endOnTick: false,
      },
      yAxis: {
        title: {
          useHTML: true,
          style: {
            fontSize: '1rem',
          },
          text: `<b>${legendMap[metrics[2]]}</b>` as string,
        },
        labels: {
          style: {
            fontSize: '.9rem',
          },
          format: '{value}',
        },
        showLastLabel: true,
        tickLength: 10,
        min: lowY,
        max: highY,
        startOnTick: false,
        endOnTick: false,
      },
      colorAxis: {
        stops: [
          [0, 'white'],
          [0.25, '#808080'], // light grey
          [0.75, '#5a5a5a'], // dark grey
          [1, 'red'],
        ],
        // min mag number
        min: lowMag,
        // max mag number
        max: highMag,
        startOnTick: true,
        endOnTick: true,
        labels: {
          style: {
            fontSize: '.7rem',
          },
          format: '{value}',
        },
      },
      tooltip: {
        pointFormatter: function () {
          return `${Math.round((this.x + Number.EPSILON) * 100) / 100}, ${
            Math.round((this.y ?? 0 + Number.EPSILON) * 100) / 100
          }: ${Math.round((this.value ?? 0 + Number.EPSILON) * 1000) / 1000}`;
        },
      },
      legend: {
        useHTML: true,
        title: {
          text: '<div style="text-align: center; width: 200px; color: #65656d;">Normalized Peak</div>',
        },
      },
      series: [
        {
          type: 'heatmap',
          // boost when there is more than 1 point in the series
          boostThreshold: 1,
          borderWidth: 0,
          name: '',
          nullColor: '#EFEFEF',
          rowsize: 10,
          data,
          turboThreshold: Number.MAX_VALUE,
        },
      ],
    };
  };
  const sessionProcessing = async () => {
    const initiate = await initiateSessionProcessing(patientId, sessionId);
    if (initiate.success) setOpen(true);
  };
  const summary_data_processed = () => {
    const session =
      props.sessionListData &&
      get(props, 'sessionListData', []).filter((option: any) => option.id === sessionId)[0];
    if (session?.summary_data_processed_flag > 1)
      return (
        <Typography color='error' style={{ fontStyle: 'italic' }}>
          The post processing failed for this session. {session.post_processing_notes}
        </Typography>
      );
    if (session?.summary_data_processed_flag < 1 || session?.summary_data_processed_flag === null)
      return (
        <Typography color='primary' style={{ fontStyle: 'italic' }}>
          The post processing was not done for this session. Click{' '}
          <span
            style={{ textDecoration: 'underline', cursor: 'pointer' }}
            onClick={sessionProcessing}
          >
            here
          </span>{' '}
          to initiate.
        </Typography>
      );
    return <span />;
  };
  const parametersByClinician =
    props.sessionListData &&
    get(props, 'sessionListData', []).filter((option: any) => option.id === sessionId)[0]
      ?.parameters_by_clinician;
  const getBioFeedbackLimits = (limit: string) => {
    return get(parametersByClinician, limit, 0).toFixed(2);
  };
  const getBioFeedbackInclude = (include: string) => {
    return get(parametersByClinician, include, {}) ? 'Active' : 'Inactive';
  };
  const getColumnChartOptions: (isSecondary?: boolean) => Options = (isSecondary) => {
    const categories: string[] = [];
    const divisons: number[] = columnData[
      `${legendKeyBarMap[isSecondary ? metrics[2] : metrics[1]]}divison`
    ]?.map(Math.ceil);

    for (let i = 0; i < divisons.length - 1; i++) {
      const category = `${divisons[i]}-${divisons[i + 1]}`;
      categories.push(category);
    }

    return {
      title: {
        text: '',
      },
      credits: {
        enabled: false,
      },
      chart: {
        type: 'column',
        width: !isLaptopFloor ? 600 : 410,
        marginRight: 50,
      },
      legend: {
        enabled: false,
      },
      tooltip: {
        useHTML: true,
        formatter: function () {
          return (
            this.x +
            ': <b>' +
            this.point.y?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') +
            '</b>'
          );
        },
      },
      xAxis: {
        categories,
        title: {
          useHTML: true,
          style: {
            fontSize: '1rem',
          },
          text: `<b>${legendMap[isSecondary ? metrics[2] : metrics[1]] as string}</b>`,
        },
        labels: {
          style: {
            fontSize: '.9rem',
          },
          format: '{value}',
        },
      },
      yAxis: {
        min: 0,
        useHTML: true,
        style: {
          fontSize: '1rem',
        },
        title: {
          useHTML: true,
          style: {
            fontSize: '1rem',
          },
          text: '<b>Count</b>',
        },
        labels: {
          style: {
            fontSize: '.9rem',
          },
          formatter: function () {
            return `${this.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
          },
        },
      },
      plotOptions: {
        column: {
          grouping: false,
          shadow: false,
          borderWidth: 0,
          pointPadding: 0,
          groupPadding: 0.12,
        },
      },
      series: [
        {
          type: 'column',
          data: columnData[`${legendKeyBarMap[isSecondary ? metrics[2] : metrics[1]]}count`],
        },
      ],
    };
  };

  return (
    <>
      <div className={classes.root}>
        {props.sessionDetails.loading || props.sessionDetails.sessionVisualData.loading ? (
          <div
            style={{
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
              height: '100vh',
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <>
            <ArrowBackIosIcon
              fontSize='large'
              style={{ cursor: 'pointer' }}
              onClick={() => {
                props.history.goBack();
              }}
            />
            <Dialog open={dialog} onClose={() => setDialog(false)}>
              <DialogTitle>
                <IconButtonWrapper
                  aria-label='close'
                  disableRipple
                  onClick={() => setDialog(false)}
                >
                  <CloseIcon style={{ width: 20 }} />
                </IconButtonWrapper>
              </DialogTitle>
              <DialogContent
                style={{ display: 'flex', justifyContent: 'center', padding: 50, paddingTop: 30 }}
              >
                {rawDataDownload || sessionDataDownload ? (
                  <>
                    <CircularProgress
                      style={{ height: 25, width: 25, marginRight: 10 }}
                      color='primary'
                    />
                    Preparing file. Please wait...
                  </>
                ) : downloadSuccess ? (
                  <div>
                    File is ready. Please click{' '}
                    <Link
                      component='a'
                      href={`${dataURL}`}
                      variant='body2'
                      onClick={() => setDialog(false)}
                    >
                      here
                    </Link>{' '}
                    to download
                  </div>
                ) : (
                  <Typography>
                    it's taking longer than usual to generate the file. Please try again after few
                    minutes
                  </Typography>
                )}
              </DialogContent>
            </Dialog>
            <div style={{ margin: '0 10rem' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant='h6'>User {patientId}</Typography>
                <Downloadables />
                <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
                  <div>{summary_data_processed()}</div>
                </div>
              </div>
              <Snackbar
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                open={open}
                autoHideDuration={8000}
                onClose={() => setOpen(false)}
              >
                <Alert onClose={() => setOpen(false)} severity='info'>
                  Post processing initiated and will take few minutes to complete.
                </Alert>
              </Snackbar>
              <UserForm
                modalOpen={reportForm}
                modalClose={() => setReportForm(false)}
                editData={patientDetailsInfo}
                onSubmit={generate}
                title={'Generate Report'}
                edit={true}
                reportForm={true}
              />
              <div className={classes.sidePanelContainer}>
                <div
                  className={classes.sessionInfoContainer}
                  style={{
                    borderRadius: 8,
                    justifyContent: 'space-between',
                    display: 'flex',
                  }}
                >
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <FormControl className={classes.selectSession}>
                      <Typography className={classes.header}>Select Session</Typography>
                      <Select
                        defaultValue=''
                        variant='outlined'
                        value={sessionId}
                        style={{
                          justifyContent: 'center',
                          alignItems: 'center',
                          borderColor: 'rgba(0, 0, 0, 0.12)',
                        }}
                        onChange={(e) => setSessionId(e.target.value as number)}
                      >
                        {!isEmpty(props.sessionListData) &&
                          get(props, 'sessionListData', []).map((option: any) => {
                            return (
                              <MenuItem key={v4()} value={option.id}>
                                {`Session ${option.id} ${getEstFormattedDateTime(
                                  option.start_date,
                                )} - ${getEstFormattedTime(option.end_date)}`}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>
                    <FormControl className={classes.formControl}>
                      <Typography className={classes.header}>Metric 1</Typography>
                      <Select
                        variant='outlined'
                        value={metric1}
                        style={{
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                        defaultValue={0}
                        onChange={(e) => setMetric1(e.target.value as number)}
                      >
                        <MenuItem value={1}>SPL (dB)</MenuItem>
                        <MenuItem value={2}>f0 (Hz)</MenuItem>
                        <MenuItem value={3}>ACC (dB)</MenuItem>
                        <MenuItem value={4}>CPP (dB)</MenuItem>
                        <MenuItem value={5}>H1H2 (db)</MenuItem>
                      </Select>
                    </FormControl>
                    <FormControl className={classes.formControl}>
                      <Typography className={classes.header}>Metric 2</Typography>
                      <Select
                        defaultValue=''
                        id='grouped-select'
                        variant='outlined'
                        value={metric2}
                        onChange={(e) => setMetric2(e.target.value as number)}
                      >
                        <MenuItem value={1}>SPL (dB)</MenuItem>
                        <MenuItem value={2}>f0 (Hz)</MenuItem>
                        <MenuItem value={3}>ACC (dB)</MenuItem>
                        <MenuItem value={4}>CPP (dB)</MenuItem>
                        <MenuItem value={5}>H1H2 (db)</MenuItem>
                      </Select>
                    </FormControl>
                    <div
                      style={{
                        display: 'flex',
                        flex: 1,
                        flexDirection: 'column',
                        alignItems: 'flex-end',
                        marginTop: 24,
                        padding: '0 10px 0 10px',
                      }}
                    >
                      <CustomButton
                        height='2.5em'
                        width='max-content'
                        onClick={() => setMetrics([sessionId, metric1, metric2])}
                      >
                        Update
                      </CustomButton>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className={classes.sessionInfoContainer}
                style={{
                  flex: 1,
                  marginLeft: 8,
                  flexDirection: 'column',
                  borderRadius: 8,
                  width: 'max-content',
                }}
              >
                <Typography className={classes.header}>Biofeedback Limits</Typography>
                <div style={{ display: 'flex', flex: 1, paddingTop: 8 }}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <div className={classes.sessionTmspContainer}>
                      <Typography className={classes.biofeedbackItem}>
                        db SPL:
                        {` ${getBioFeedbackLimits('biofeedback_micspl_lower')},
                        ${getBioFeedbackLimits('biofeedback_micspl_upper')},
                        ${getBioFeedbackInclude('biofeedback_micspl_include')}`}
                      </Typography>
                    </div>
                    <div className={classes.sessionTmspContainer}>
                      <Typography className={classes.biofeedbackItem}>
                        db ACC:
                        {` ${getBioFeedbackLimits('biofeedback_acclevel_lower')},
                        ${getBioFeedbackLimits('biofeedback_acclevel_upper')},
                        ${getBioFeedbackInclude('biofeedback_acclevel_include')}`}
                      </Typography>
                    </div>
                    <div className={classes.sessionTmspContainer}>
                      <Typography className={classes.biofeedbackItem}>
                        f0:
                        {` ${getBioFeedbackLimits('biofeedback_fo_lower')},
                        ${getBioFeedbackLimits('biofeedback_fo_upper')},
                        ${getBioFeedbackInclude('biofeedback_fo_include')}`}
                      </Typography>
                    </div>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      marginLeft: '2rem',
                    }}
                  >
                    <div className={classes.sessionTmspContainer}>
                      <Typography className={classes.biofeedbackItem}>
                        CPP:
                        {` ${getBioFeedbackLimits('biofeedback_cpp_lower')},
                        ${getBioFeedbackLimits('biofeedback_cpp_upper')},
                        ${getBioFeedbackInclude('biofeedback_cpp_include')}`}
                      </Typography>
                    </div>
                    <div className={classes.sessionTmspContainer}>
                      <Typography className={classes.biofeedbackItem}>
                        H1H2:{' '}
                        {` ${getBioFeedbackLimits('biofeedback_h1h2_lower')},
                        ${getBioFeedbackLimits('biofeedback_h1h2_upper')},
                        ${getBioFeedbackInclude('biofeedback_h1h2_include')}`}
                      </Typography>
                    </div>
                  </div>
                </div>
              </div>
              <div
                style={{
                  marginTop: 24,
                  maxHeight: '100px !important',
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <div
                  style={{
                    flex: 1,
                    backgroundColor: 'white',
                    marginRight: 24,
                    marginBottom: 16,
                    borderRadius: 12,
                    display: 'flex',
                    padding: 24,
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flex: 0.5,
                      marginRight: 12,
                      flexDirection: 'column',
                    }}
                  >
                    <div>
                      <Typography style={{ fontWeight: 'bold', marginBottom: 8 }}>
                        Dosage
                      </Typography>
                      <Typography>
                        Phonation time (HH:MM:SS):{' '}
                        {get(sessionVisualData, 'data.data.session_voiced_time', 0)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      </Typography>
                      <br />
                      <Typography>
                        Percent phonation (%):{' '}
                        {get(
                          sessionVisualData,
                          'data.data.voiced_percent_total_percent',
                          0,
                        ).toFixed(1)}
                      </Typography>
                      <br />
                      <Typography>
                        <Typography>
                          Cycle dose (cycles):{' '}
                          {get(sessionVisualData, 'data.data.cycle_dose_sum', 0)
                            .toFixed(0)
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        </Typography>
                        <br />
                        Distance dose (meters):{' '}
                        {get(sessionVisualData, 'data.data.distance_dose', 0).toFixed(0)}
                      </Typography>
                    </div>
                  </div>
                  <div style={{ display: 'flex', flex: 1 }}>
                    <div>
                      <Typography style={{ fontWeight: 'bold', marginBottom: 8 }}>
                        Biofeedback
                      </Typography>
                      <Typography>
                        # of flags:{' '}
                        {get(sessionVisualData, 'data.data.BiofeedbackFlags', '')
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      </Typography>
                      <Typography>
                        % out of range:{' '}
                        {get(sessionVisualData, 'data.data.Percent_Biofeedback', '')}
                      </Typography>
                      <Typography>
                        Count of biofeedback frames:{' '}
                        {/* voiced color dots with count next to each */}
                      </Typography>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <FiberManualRecordIcon style={{ color: 'green', marginRight: '.5rem' }} />
                        <div>
                          {get(sessionVisualData, 'data.data.flag_count[0]', 0)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        </div>
                      </div>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <FiberManualRecordIcon style={{ color: 'yellow', marginRight: '.5rem' }} />
                        <div>
                          {get(sessionVisualData, 'data.data.flag_count[1]', 0)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        </div>
                      </div>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <FiberManualRecordIcon style={{ color: '#CCCC00', marginRight: '.5rem' }} />
                        <div>
                          {get(sessionVisualData, 'data.data.flag_count[2]', 0)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        </div>
                      </div>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <FiberManualRecordIcon style={{ color: 'red', marginRight: '.5rem' }} />
                        <div>
                          {get(sessionVisualData, 'data.data.flag_count[3]', 0)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <TableContainer
                component={Paper}
                style={{
                  borderStyle: 'solid',
                  borderColor: 'rgba(0, 0, 0, 0.12)',
                  borderWidth: 0.7,
                  flex: 1,
                  marginTop: 12,
                  marginBottom: 24,
                  height: 220,
                }}
              >
                <TableHead style={{ backgroundColor: 'lightgray' }}>
                  <StyledTableRow>
                    <StyledTableCell align='center'>Measure</StyledTableCell>
                    <StyledTableCell align='center'>Mean</StyledTableCell>
                    <StyledTableCell align='center'>SD</StyledTableCell>
                    <StyledTableCell align='center'>5th Percentile</StyledTableCell>
                    <StyledTableCell align='center'>95th Percentile</StyledTableCell>
                    <StyledTableCell align='center'>Skewness</StyledTableCell>
                  </StyledTableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => (
                    <StyledTableRow key={v4()}>
                      <StyledTableCell style={{ fontWeight: 'bold' }} align='center'>
                        {row.measure}
                      </StyledTableCell>
                      <StyledTableCell align='center'>{row.mean}</StyledTableCell>
                      <StyledTableCell align='center'>{row.sd}</StyledTableCell>
                      <StyledTableCell align='center'>{row.min}</StyledTableCell>
                      <StyledTableCell align='center'>{row.max}</StyledTableCell>
                      <StyledTableCell align='center'>{row.skewness}</StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </TableContainer>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: '1rem',
              }}
            >
              <Typography style={{ fontSize: '.9rem' }}>
                Drag your mouse on the heatmap and time series charts to zoom.
              </Typography>
            </div>
            <div style={{ width: '100%' }}>
              {Object.keys(timeSeriesData).length !== 0 ? (
                <>
                  <div>
                    <HighchartsReact highcharts={Highcharts} options={getTimeSeriesOptions()} />
                  </div>
                  <div>
                    <HighchartsReact highcharts={Highcharts} options={getTimeSeriesOptions(true)} />
                  </div>
                </>
              ) : (
                <>
                  <div className={classes.noDataContainer} style={{ height: '300px' }}>
                    <NoDataCard />
                  </div>
                  <div className={classes.noDataContainer} style={{ height: '300px' }}>
                    <NoDataCard />
                  </div>
                </>
              )}
            </div>
          </>
        )}
      </div>
      <div
        style={{
          display:
            props.sessionDetails.loading || props.sessionDetails.sessionVisualData.loading
              ? 'none'
              : 'flex',
          width: '100%',
          backgroundColor: 'white',
        }}
      >
        <div
          style={{
            marginTop: 16,
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
          }}
        >
          {props.sessionDetails.sessionVisualData.loading ? (
            <>
              <Skeleton variant='rect' width={410} height={450} />
              <Skeleton variant='rect' width={410} height={450} />
            </>
          ) : Object.keys(columnData).length === 48 ? (
            <>
              <HighchartsReact highcharts={Highcharts} options={getColumnChartOptions()} />
              <HighchartsReact highcharts={Highcharts} options={getColumnChartOptions(true)} />
            </>
          ) : (
            <>
              <div className={classes.noDataContainer} style={{ width: '410px', height: '450px' }}>
                <NoDataCard />
              </div>
              <div className={classes.noDataContainer} style={{ width: '410px', height: '450px' }}>
                <NoDataCard />
              </div>
            </>
          )}
          {props.sessionDetails.sessionHeatmapData.loading ? (
            <Skeleton variant='rect' width={410} height={450} />
          ) : Object.keys(heatmapData).length === 3 ? (
            <HighchartsReact highcharts={Highcharts} options={getHeatmapOptions()} />
          ) : (
            <div className={classes.noDataContainer} style={{ width: '410px', height: '450px' }}>
              <NoDataCard />
            </div>
          )}
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state: any) => ({
  userData: state.userAuth.userData,
  patient: state.patient,
  list: state.list,
  sessionListData: state.session.sessionsData,
  parameters: state.parameters,
  sessionDetails: state.sessionDetails,
});

export default connect(mapStateToProps)(SessionVisuals);
