export const clinicianFrom = [
  {
    id: '',
    name: '',
    label: '',
    placeHolder: '',
    type: '',
  },
];

export interface Clinician {
  id: number;
  city: string;
  clinicName: string;
  createLogin: boolean;
  email: string;
  firstName: string;
  lastName: string;
  npi: string;
  state: string;
  street: string;
  zip: number;
}
export interface Data {
  id: string;
  first_name: string;
  clinic_name: string;
  npi: string;
  email: string;
  address1: string;
  created_date: Date;
  options: any;
}

type SortMethod = 'alpha' | 'numeric' | 'date' | 'none';

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  sortMethod: SortMethod;
}

export const headCells: HeadCell[] = [
  {
    id: 'id',
    sortMethod: 'numeric',
    disablePadding: false,
    label: 'ID',
  },
  {
    id: 'first_name',
    sortMethod: 'alpha',
    disablePadding: true,
    label: 'Clinician',
  },
  { id: 'clinic_name', sortMethod: 'alpha', disablePadding: false, label: 'Clinic' },
  { id: 'npi', sortMethod: 'numeric', disablePadding: false, label: 'NPI' },
  { id: 'email', sortMethod: 'alpha', disablePadding: false, label: 'Email' },
  { id: 'address1', sortMethod: 'alpha', disablePadding: false, label: 'Address' },
  { id: 'created_date', sortMethod: 'date', disablePadding: false, label: 'Added on' },
  { id: 'options', sortMethod: 'none', disablePadding: false, label: '' },
];
