//Devices.tsx
/**
 * Defines the views for the list of devices (/devices).
 *
 * @module
 * @author:
 * @copyright:
 */
import { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { getAllDevicesRequest } from '../store/actions';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Input, Snackbar, Typography } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import SearchIcon from '@material-ui/icons/Search';
import CustomButton from '../components/CustomButton';
import DeviceTable from '../components/DeviceTable';
import { headCells } from '../constants/device';
import UserForm from '../components/UserForm';
import { auth, post } from '../utils/services';
import { isEmpty } from 'lodash';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    body: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: '2em',
    },
  }),
);

interface DeviceProps {
  devices: any;
  userData: any;
}

function Devices({ devices, userData }: DeviceProps) {
  const [open, setOpen] = useState(false);
  const [alert, setAlert] = useState(false);
  const [deviceToast, setDeviceToast] = useState<any>('');
  const [deviceOperationSuccess, setDeviceOperationSuccess] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [jsonDataLoading, setJsonDataLoading] = useState(false);
  const [searchList, setSearchList] = useState([]);
  const classes = useStyles();
  const user = userData?.data?.user;

  const userDevices = userData?.data?.user.is_admin
    ? devices?.device
    : devices.device.filter((d) => {
        if (d.assinged_clinician?.id === userData?.data?.user?.id_person) {
          return d;
        }
      });

  const dispatch = useDispatch();

  const handleClose = () => {
    setAlert(false);
  };

  const handleSearch = (value: any) => {
    setSearchValue(value);
  };

  const handleAddDevice = (data: any) => {
    data.mac_address = data.mac_address.toUpperCase();
    setJsonDataLoading(true);
    post('/device', data, auth())
      .then((data) => {
        if (!data.success) throw data.data.message;
        setJsonDataLoading(false);
        setAlert(true);
        setDeviceToast(data.data.result);
        setDeviceOperationSuccess(true);
        setOpen(false);
        dispatch(getAllDevicesRequest());
      })
      .catch(() => {
        setJsonDataLoading(false);
        setAlert(true);
        setDeviceToast('Device added failed');
        setDeviceOperationSuccess(false);
        setOpen(false);
      });
  };

  //Even if user is a clinician, we are going to get all devices b/c this has all of the data we
  //need.  We will filter based on the user.
  useEffect(() => {
    dispatch(getAllDevicesRequest());
  }, []);

  useEffect(() => {
    setJsonDataLoading(true);
    let filteredData: any = [];
    const timeoutId = setTimeout(() => {
      filteredData = ([userDevices] || []).filter((e) => {
        //In case userDevices is undefined
        if (e === undefined) {
          return;
        }
        return (
          (e.id && e.id.toString().includes(searchValue.toUpperCase())) ||
          (e.serial_number && e.serial_number.toUpperCase().includes(searchValue.toUpperCase())) ||
          (e.mac_address && e.mac_address.toUpperCase().includes(searchValue.toUpperCase())) ||
          (e.passkey && e.passkey.toUpperCase().includes(searchValue.toUpperCase())) ||
          (e.assinged_clinician &&
            `${e.assinged_clinician.first_name} ${e.assinged_clinician.last_name}`
              .toUpperCase()
              .includes(searchValue.toUpperCase())) ||
          (e.assinged_patient &&
            `${e.assinged_patient.first_name} ${e.assinged_patient.last_name}`
              .toUpperCase()
              .includes(searchValue.toUpperCase()))
        );
      });
      setSearchList(filteredData);
      setJsonDataLoading(false);
    }, 1000);
    return () => clearTimeout(timeoutId);
  }, [searchValue]);

  return (
    <div className={classes.root}>
      <div className={classes.body}>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={alert}
          autoHideDuration={5000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity={deviceOperationSuccess ? 'success' : 'error'}>
            {deviceToast}
          </Alert>
        </Snackbar>
        <Typography variant='h6'>Device List</Typography>

        <div
          style={{
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
          }}
        >
          <SearchIcon style={{ marginRight: -12 }} />
          <Input
            type='text'
            onChange={(e: any) => handleSearch(e.target.value)}
            style={{
              width: 300,
              padding: 8,
              paddingLeft: 12,
              marginRight: 8,
            }}
            placeholder='Search'
          />
          {/* Putting in the empty string otherwise a 0 is inserted in the place of the button. */}
          <div>
            {user.is_admin ? (
              <CustomButton height='2.5em' width='max-content' onClick={() => setOpen(true)}>
                {/* <PersonAddIcon style={{ marginRight: '5px' }} /> */}
                Add Device
              </CustomButton>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>

      <DeviceTable
        header={headCells}
        dataRows={
          searchValue ? searchList : devices ? (!isEmpty(userDevices) ? userDevices : []) : []
        }
        loading={devices.loading || jsonDataLoading}
      />
      {user.is_admin ? (
        <UserForm
          edit={false}
          modalOpen={open}
          modalClose={() => setOpen(false)}
          onSubmit={handleAddDevice}
          title='Add Device'
          addDeviceForm={true}
          isLoading={jsonDataLoading}
        />
      ) : (
        ''
      )}
    </div>
  );
}

const mapStateToProps = (state: any) => ({
  devices: state.device,
  userData: state.userAuth.userData,
});

export default connect(mapStateToProps)(Devices);
