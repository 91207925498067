import { ON_SET_ADVANCED_PARAMETER_REQUEST } from '../actionTypes/advancedParameter.actionTypes';
import { call, takeLatest, put } from 'redux-saga/effects';
import {
  setAdvancedParameterSuccess,
  setAdvancedParameterFailed,
} from '../actions/advancedParameter.action';
import { addAdvancedParameter } from '../../utils/services/advancedParameter';

function* setAdvancedParameterSaga(action: any) {
  const { data, toast } = action;
  try {
    const result = yield addAdvancedParameter({
      advancedParameterId: data.advancedParameterId,
      patientId: data.patientId,
      payload: data.payload,
    });
    if (result.success) {
      yield put(setAdvancedParameterSuccess(result.data));
      toast.alert(true);
      toast.message('Advanced Parameters updated');
      toast.status(true);
    } else {
      throw result.data.message;
    }
  } catch (err) {
    setAdvancedParameterFailed(err);
    action.toast(true);
    toast.message('Advanced Parameters update failed');
    toast.status(false);
  }
}

const advancedParameterSaga = [
  takeLatest(ON_SET_ADVANCED_PARAMETER_REQUEST, setAdvancedParameterSaga),
];

export default advancedParameterSaga;
