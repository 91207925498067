//SessionsTable.tsx
/**
 * Defines a table for displaying summary information about sessions.
 *
 * @module
 * @author:
 * @copyright:
 */

import React, { Fragment } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { IconButton, Typography } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { Link, useHistory } from 'react-router-dom';
import { styled } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import ToolTip from '@material-ui/core/Tooltip';
import { v4 } from 'uuid';
import { secondsToHms, getEstFormattedDateTime } from '../utils/helper/formatTime';

const TableHeadWrapper = styled(TableHead)({
  backgroundColor: 'rgba(0, 0, 0, 0.05)',
});
const TableBodyWrapper = styled(TableBody)({
  '& tr:nth-of-type(even)': {
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
  },
});
const IconButtonWrapper = styled(IconButton)({
  backgroundColor: '#e5e5e5',
  borderRadius: 8,
  padding: 5,
});
const ErrorText = styled(Typography)({
  padding: '1em',
});

interface Columns {
  key: string;
  label: string;
  align: any;
}

interface OwnProps {
  sessionData: any;
  headers: Array<Columns>;
  topSessions: [];
  onViewPress: Function;
}

export default function SessionsTable(props: OwnProps) {
  const history = useHistory();

  const { sessionData, headers, topSessions, onViewPress } = props;

  const handleClick = (id: number, patientDetails: any) => {
    onViewPress(id, patientDetails);
  };

  // const dashboardData = sessionData?.dashboardData;
  // const sessions = dashboardData?.data;
  // const topSessions = sessions ? sessions.top_sessions : [];

  return (
    <TableContainer component={Paper}>
      {sessionData.loading && <LinearProgress />}
      <Table size='small'>
        <TableHeadWrapper>
          <TableRow>
            {headers.map((item: Columns) => (
              <TableCell align={item.align} key={v4()}>
                <Typography variant='subtitle2'>{item.label}</Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHeadWrapper>
        {!sessionData.loading && !sessionData.error?.message && (
          <TableBodyWrapper>
            {topSessions.map((row: any, index: number) => {
              return (
                <Fragment key={v4()}>
                  <TableRow>
                    <TableCell component='th' scope='row'>
                      <Typography variant='button'>{row.id}</Typography>
                    </TableCell>
                    <TableCell align='left'>
                      <Link
                        to={{
                          pathname: '/patient-details',
                          state: {
                            data: row,
                            id: row.id_person_patient,
                            sessionId: row.id,
                          },
                        }}
                      >
                        {row.first_name_patient}
                      </Link>
                    </TableCell>
                    <TableCell align='left'>
                      {row.first_name_clinician} {row.last_name_clinician}
                    </TableCell>
                    <TableCell align='center'>{getEstFormattedDateTime(row.start_date)}</TableCell>
                    <TableCell align='center'>{getEstFormattedDateTime(row.end_date)}</TableCell>
                    <TableCell align='center'>{secondsToHms(row.duration)}</TableCell>
                    <TableCell align='center'>{secondsToHms(row.voiced)}</TableCell>
                    <TableCell align='center'>
                      {row.modified_date === null || row.modified_date === ''
                        ? getEstFormattedDateTime(row.start_date)
                        : getEstFormattedDateTime(row.modified_date)}
                    </TableCell>
                    <TableCell align='right'>
                      <ToolTip title='View'>
                        <IconButtonWrapper
                          onClick={() => handleClick(row.id, row)}
                          size='small'
                          color='default'
                        >
                          <VisibilityIcon />
                        </IconButtonWrapper>
                      </ToolTip>
                    </TableCell>
                  </TableRow>
                </Fragment>
              );
            })}
          </TableBodyWrapper>
        )}
      </Table>
      {topSessions.length === 0 && (
        <ErrorText variant='body1' align='center'>
          No Data
        </ErrorText>
      )}
    </TableContainer>
  );
}
