import {
  ON_GET_STATES_DATA_REQUEST,
  ON_GET_STATES_DATA_SUCCESS,
  ON_GET_STATES_DATA_FAILED,
  ON_GET_GENDER_DATA_SUCCESS,
  ON_GET_GENDER_DATA_REQUEST,
  ON_GET_GENDER_DATA_FAILED,
  ON_GET_CLINICIAN_CREDENTIALS_REQUEST,
  ON_GET_CLINICIAN_CREDENTIALS_FAILED,
  ON_GET_CLINICIAN_CREDENTIALS_SUCCESS,
  ON_GET_TREATMENT_STATUS_REQUEST,
  ON_GET_TREATMENT_STATUS_SUCCESS,
  ON_GET_TREATMENT_STATUS_FAILED,
  ON_GET_DIAGNOSIS_REQUEST,
  ON_GET_DIAGNOSIS_SUCCESS,
  ON_GET_DIAGNOSIS_FAILED,
  ON_GET_TIME_ZONES_REQUEST,
  ON_GET_TIME_ZONES_SUCCESS,
  ON_GET_TIME_ZONES_FAILED,
} from '../actionTypes';

const sessionInitialState = {
  loading: true,
  statesData: {},
  genderData: {},
  error: {},
};

const listReducer = (state = sessionInitialState, action: any) => {
  switch (action.type) {
    case ON_GET_STATES_DATA_REQUEST:
      return { ...state, loading: true };
    case ON_GET_STATES_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        statesData: action.payload,
        errorStates: {},
      };
    case ON_GET_STATES_DATA_FAILED:
      return {
        ...state,
        loading: false,
        errorStates: action.payload,
        statesData: {},
      };
    case ON_GET_GENDER_DATA_REQUEST:
      return { ...state, loading: true };
    case ON_GET_GENDER_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        genderData: action.payload,
        errorGender: {},
      };
    case ON_GET_GENDER_DATA_FAILED:
      return {
        ...state,
        loading: false,
        errorGender: action.payload,
        genderData: {},
      };
    case ON_GET_CLINICIAN_CREDENTIALS_REQUEST:
      return { ...state, loading: true };
    case ON_GET_CLINICIAN_CREDENTIALS_SUCCESS:
      return {
        ...state,
        loading: false,
        clinicianCredentials: action.payload,
        errorCredentials: {},
      };
    case ON_GET_CLINICIAN_CREDENTIALS_FAILED:
      return {
        ...state,
        loading: false,
        errorCredentials: action.payload,
        clinicianCredentials: {},
      };
    case ON_GET_TREATMENT_STATUS_REQUEST:
      return { ...state, loading: true };
    case ON_GET_TREATMENT_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        treatmentStatus: action.payload,
        errorTreatmentStatus: {},
      };
    case ON_GET_TREATMENT_STATUS_FAILED:
      return {
        ...state,
        loading: false,
        errorTreatmentStatus: action.payload,
        treatmentStatus: {},
      };
    case ON_GET_DIAGNOSIS_REQUEST:
      return { ...state, loading: true };
    case ON_GET_DIAGNOSIS_SUCCESS:
      return {
        ...state,
        loading: false,
        diagnosis: action.payload,
        error: {},
      };
    case ON_GET_DIAGNOSIS_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
        diagnosis: {},
      };
    case ON_GET_TIME_ZONES_REQUEST:
      return { ...state, loading: true };
    case ON_GET_TIME_ZONES_SUCCESS:
      return {
        ...state,
        loading: false,
        timeZones: action.payload,
      };
    case ON_GET_TIME_ZONES_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default listReducer;
