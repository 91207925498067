export const loginErrors = {
  username: {
    required: 'Username field should not be empty',
    pattern: 'Please enter a valid email address',
  },
  password: {
    required: 'Password field should not be empty',
  },
};

export const clinicianFormErrors = {
  firstName: {
    required: 'required',
  },
  lastName: {
    required: 'required',
  },
  npi: {
    required: 'required',
    pattern: 'Please enter a valid npi number',
  },
  email: {
    required: 'required',
    pattern: 'enter a valid email address',
  },
  clinicName: {
    required: 'required',
  },
  street: {
    required: 'required',
  },
  city: {
    required: 'required',
  },
  state: {
    required: 'required',
  },
  zip: {
    required: 'required',
    pattern: 'numbers only',
  },
  credential: {
    required: 'required',
  },
  otherCredential: {
    required: 'required',
  },
  timeZones: {
    required: 'required',
  },
};

export const patientFormErrors = {
  emr: {
    required: 'required',
  },
  gender: {
    required: 'required',
  },
  treatmentStatus: {
    required: 'required',
  },
  clinician: {
    required: 'required',
  },
  timeZones: {
    required: 'required',
  },
};

export const medFormErrors = {
  otherDiagnosis: {
    required: 'required',
  },
};
