import HearingOutlinedIcon from '@material-ui/icons/HearingOutlined';
import DashboardOutlinedIcon from '@material-ui/icons/DashboardOutlined';
import HealingOutlinedIcon from '@material-ui/icons/HealingOutlined';
import LocalHospitalOutlinedIcon from '@material-ui/icons/LocalHospitalOutlined';
import { handleClick } from '../components/MenuDropdown';

/**Defines the navigation items in the sidebar for non-admin users. */
export const navItems = [
  {
    key: 'dashboard',
    label: 'Dashboard',
    link: '/dashboard',
    icon: DashboardOutlinedIcon,
  },
  {
    key: 'patients',
    label: 'Users',
    link: '/patients',
    icon: HealingOutlinedIcon,
  },
  {
    key: 'devices',
    label: 'Devices',
    link: '/devices',
    icon: HearingOutlinedIcon,
  },
];

/**Defines the navigation items in the sidebar for admin users. */
export const adminNavItems = [
  {
    key: 'dashboard',
    label: 'Dashboard',
    link: '/dashboard',
    icon: DashboardOutlinedIcon,
  },
  {
    key: 'patients',
    label: 'Users',
    link: '/patients',
    icon: HealingOutlinedIcon,
  },
  {
    key: 'clinicians',
    label: 'Clinicians',
    link: '/clinicians',
    icon: LocalHospitalOutlinedIcon,
  },
  {
    key: 'devices',
    label: 'Devices',
    link: '/devices',
    icon: HearingOutlinedIcon,
  },
];

export const menuItems = [
  {
    key: 'changePassword',
    label: 'Reset Password',
  },
  {
    key: 'logout',
    label: 'Logout',
    handleClick: handleClick,
  },
];
