import { deleteRow, get, post, put } from '../index';
const ENDPOINT = '/parameter';

const auth = () => {
  return {
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('simbex_authtoken')}`,
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
    },
  };
};

export const addParameter = (data: any) => {
  const { parameterId } = data;
  const url = `${ENDPOINT}/${parameterId}`;
  return put(url, data.payload, auth());
};
