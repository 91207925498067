//PatientDetails.tsx
/**
 * Defines the Patient Details view (route: /patient-details).
 *
 * @module
 * @author:
 * @copyright:
 */

import React, { useEffect, useState } from 'react';
import { v4 } from 'uuid';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { styled } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import NotesIcon from '@material-ui/icons/Notes';
import SearchIcon from '@material-ui/icons/Search';
import { headCells } from '../constants/sessionDetails';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import './patientDetail.css';
import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  Divider,
  Input,
  Snackbar,
  TextareaAutosize,
  Typography,
} from '@material-ui/core';
import CustomButton from '../components/CustomButton';
import { connect, useDispatch } from 'react-redux';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ChatBubbleIcon from '@material-ui/icons/Message';
import moment from 'moment-timezone';
import { Calendar, DateRangePicker } from 'react-date-range';
import DateRangeIcon from '@material-ui/icons/DateRange';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import {
  getGenderRequest,
  getPatientsRequest,
  getPatientDetailsRequest,
  setParameterRequest,
  setAdvancedParameterRequest,
} from '../store/actions';

import ChatCard from '../components/ChatCard';

import { get, isArray, map } from 'lodash';
import UserForm from '../components/UserForm';

import { download, getCSVData, getRawData, get as GET, post } from '../utils/services';
import {
  deletePatientsRequest,
  getPatientSession,
  setPatientDelegateRequest,
  setPatientDiagnosisRequest,
  updatePatientRequest,
} from '../store/actions/patient.actions';
import MenuDropdown from '../components/MenuDropdown';
import ConfirmationModal from '../components/ConfirmationModal';
import ParamterForm from '../components/ParameterForm';
import { paramFormFieldList, advParamFormFieldList } from '../constants/paramterForm';
import { createDiagnosisArray } from '../utils/helper/createDiagnosis';
import Alert from '@material-ui/lab/Alert';
import AdvancedParameterForm from '../components/AdvancedParameterForm';
import { Controller, useForm } from 'react-hook-form';
import ChatIcon from '@material-ui/icons/Chat';
import SessionDetailsTable from '../components/SessionDetailsTable';
import { postChatRequest, setChatRequest } from '../store/actions/chat.action';
import _ from 'lodash';
import {
  secondsToHms,
  getEstFormattedDateTime,
  getEstFormattedTime,
} from '../utils/helper/formatTime';

const CancelButton = styled(Button)({
  textTransform: 'none',
  marginRight: '1em',
});

const DialogActionsWrapper = styled(DialogActions)({
  padding: '24px',
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    body: {
      '&:hover': {
        cursor: 'pointer',
      },
      'display': 'flex',
      'alignItems': 'center',
      'justifyContent': 'space-between',
      'marginBottom': '2em',
    },
    profileContainer: {
      display: 'flex',
      width: '100%',
      backgroundColor: 'white',
      borderRadius: 4,
      borderWidth: 0.7,
      borderColor: 'rgba(0, 0, 0, 0.12)',
      marginBottom: 24,
      borderStyle: 'solid',
      padding: 12,
      flexDirection: 'column',
    },
    profileDiv: {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    link: {
      'padding': '7px 16px',
      'color': theme.palette.primary.main,
      'cursor': 'pointer',
      'fontSize': 14,
      'fontWeight': 600,
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
        color: 'white',
      },
    },
    profileDetails: {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginTop: 10,
    },
    infoContainer: {
      width: '30%',
      display: 'flex',
      flexDirection: 'column',
      borderRadius: 3,
      marginRight: 24,
      justifyContent: 'space-between',
    },
    chatText: {
      color: 'rgb(51, 51, 51)',
      fontSize: 14,
      fontWeight: 'bold',
      display: 'flex',
    },
    deviceParentContainer: {
      flex: 1,
      borderRadius: 4,
      display: 'flex',
      maxHeight: 250,
    },
    deviceContainer: {
      flex: 1,
      // marginRight: 24,
      // height: 170,
      borderRadius: 4,
      borderColor: 'rgba(0, 0, 0, 0.12)',
      borderWidth: 0.1,
      borderStyle: 'solid',
      borderTopWidth: 0,
    },
    deviceLogoContainer: {
      width: '100%',
      borderRadius: '4,4,0,0',
      backgroundColor: '#007cba',
      padding: 12,
      display: 'flex',
      alignItems: 'center',
    },
    deviceIcon: { height: 12, width: 12 },
    deviceText: {
      color: 'white',
      marginLeft: 10,
      fontSize: 13,
      cursor: 'pointer',
    },
    deviceTextHeader: {
      color: 'rgb(109, 109, 109)',
      // marginLeft: 10,
      fontSize: 14,
      fontWeight: 'normal',
    },
    deviceTextInfo: {
      color: 'rgb(51, 51, 51)',
      // marginLeft: 10,
      fontSize: 14,
      fontWeight: 'normal',
    },
    clinicContainer: { marginTop: 24 },
    clinicianLogo: {
      // width: "100%",
      borderRadius: 4,
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      backgroundColor: 'rgb(40, 167, 69)',
      padding: 8,
      display: 'flex',
      // marginRight: 24,
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    clinicListContainer: {
      borderStyle: 'solid',
      borderWidth: 0.7,
      borderColor: 'rgba(0, 0, 0, 0.12)',
      // marginRight: 24,
      borderBottomRightRadius: 4,
      borderBottomLeftRadius: 4,
      overflow: 'auto',
      maxHeight: 300,
    },
    sessionContainer: {
      flex: 1,
      height: '100%',
      borderRadius: 4,
      borderColor: 'rgba(0, 0, 0, 0.12)',
      borderWidth: 0.7,
      borderStyle: 'solid',

      // overflow: "auto",
    },
    sessionLogoContainer: {
      borderRadius: 4,
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      backgroundColor: '#6c757d',
      padding: 8,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    sessionIcon: { height: 18, width: 18 },
    parameterLogoContainer: {
      // marginTop: '24px',
      flex: 1,
      width: '25em',
      borderRadius: 4,
      borderColor: 'rgba(0, 0, 0, 0.12)',
      borderWidth: 0.7,
      borderStyle: 'solid',
    },
    deviceIconContainer: {
      // width: '100%',
      borderRadius: 3,
      backgroundColor: '#ff7f30',
      padding: 8,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
    },
    chatContainer: {
      flex: 1,
      borderRadius: 4,
      borderColor: 'rgba(0, 0, 0, 0.12)',
      borderWidth: 0.7,
      borderStyle: 'solid',
      // marginTop: 24,
    },
    chatHeaderContainer: {
      // width: '100%',
      borderRadius: 3,
      backgroundColor: 'rgb(0, 123, 255)',
      padding: 8,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
    },
    chatAddContainer: {
      padding: 4,
      justifyContent: 'center',
      alignItems: 'center',
      // backgroundColor: "rgb(0, 0, 0)",
      borderRadius: 2,
      borderColor: '#FFFFFF',
      // borderStyle: 'solid',
      borderWidth: 0.1,
      // opacity: 0.2,
    },
    chatAdd: {
      color: '#FFFFFF',
      fontSize: 13,
      opacity: 1,
      cursor: 'pointer',
      // fontWeight: "500",
    },
    parameterParentContainer: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
    },
    muiRoot: {
      maxWidth: 'unset',
      backgroundColor: 'red',
    },
    headerSection: {
      minHeight: '60px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-around',
    },
    innerValue: {
      color: '#777777',
      marginLeft: '4px',
    },
  }),
);

const legendKeyReportdMap: LooseObject = {
  1: `spl`,
  2: `f0`,
  3: `acc`,
  4: `cpp`,
  5: `h1h2`,
};
interface LooseObject {
  [key: string]: string | boolean;
}

interface NotesObject {
  sessionId: number;
  data: Array<any>;
}

interface PatientProps {
  userData: object;

  patient: {
    patientData: {
      data: {
        // result: Array<any>
        patient: Array<any>;
      };
      patientDetails: { data: object };
    };
    updatePatient: any;
    loading: boolean;
    error: {
      message?: string;
    };
  };
  list: {
    genderData: object;
    diagnosis: any;
  };
  location: any;
  history: any;
  sessionListData: any;
  parameters: any;
  advancedParameters: any;
  chat: any;
  sessionLoading: boolean;
}

function Patients({
  patient,
  list,
  userData,
  history,
  location,
  sessionListData,
  parameters,
  advancedParameters,
  chat,
  sessionLoading,
}: PatientProps) {
  const patientDetailsInfo = get(location, 'state.data', {});
  const patientDetails = get(patient, 'patientDetails.data', {});
  const [edit, setEdit] = useState(false);
  const [editData, setEditData] = useState();
  const [patientID, setPatientID] = useState(0);
  const [open, setOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [medInfoForm, setMedInfoForm] = useState(false);
  const [jsonData, setJsonData] = useState<LooseObject>({});
  const [editForm, setEditForm] = useState<LooseObject>({});
  const [viewOnly, setViewOnly] = useState(false);
  const [jsonDataLoading, setJsonDataLoading] = useState(false);
  const [delegateForm, setDelegateForm] = useState(false);
  const [deleteOpen, setDelete] = useState(false);
  const [dateOpen, setDateOpen] = useState(false);
  const [chatOpen, setChatOpen] = useState(false);
  const [advParametersOpen, setAdvParametersOpen] = useState(false);
  const [patientToast, setPatientToast] = useState<any>('');
  const [alert, setAlert] = React.useState(false);
  const [patientOperationSuccess, setPatientOperationSuccess] = React.useState(false);
  const [resetPatientPassword, setResetPatientPassword] = useState(false);
  const [notes, setNotes] = useState<NotesObject>({ sessionId: 0, data: [] });
  const [notesOpen, setNotesOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [searchList, setSearchList] = useState<any>();
  const [sessionDataDownload, setSessionDataDownload] = useState(false);
  const [sessionDetails, setSessionDetails] = useState<any>({});
  const [reportForm, setReportForm] = useState(false);
  const [metrics, setMetrics] = useState([sessionDetails.id, 1, 2]);
  const [date, setDate] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
    isChanged: false,
  });
  const [isEdit, setIsEdit] = useState(false);

  const patientId = get(location, 'state.id', '');
  const classes = useStyles();
  const dispatch = useDispatch();
  const { control, getValues, reset } = useForm();

  const handleClickOpen = () => {
    setEditOpen(true);
  };
  const delegatePatient = (data: any) => {
    setDelegateForm(true);
    setPatientID(data.id);
    dispatch(getPatientDetailsRequest(data.id));
  };

  const onResetPatientPassword = (data: any) => {
    setResetPatientPassword(true);
  };

  const onResetPatientPasswordClose = () => {
    setResetPatientPassword(false);
  };

  const handleResetPatientPassword = (data: any) => {
    setJsonDataLoading(true);
    const { newPassword, confirmPassword } = data;
    const payload = { newPassword, confirmPassword };
    const patientId = get(patientDetails, 'patient.id', '');
    post(`patient/${patientId}/reset-password`, payload, auth()).then((data) => {
      if (data.success) {
        setResetPatientPassword(false);
        setAlert(true);
        setPatientToast(data.message);
        setPatientOperationSuccess(true);
        setJsonDataLoading(false);
      } else {
        setAlert(true);
        setPatientToast(data.message);
        setPatientOperationSuccess(false);
        setJsonDataLoading(false);
      }
    });
  };

  const patientsOptionsMenu = [
    {
      key: 1,
      label: 'Reset Password',
      handleClick: (data: any) => onResetPatientPassword(data),
    },
    {
      key: 2,
      label: 'Edit',
      handleClick: () => handleClickOpen(),
    },
    {
      key: 3,
      label: 'Delegate',
      handleClick: (data: any) => {
        delegatePatient(data);
      },
    },
    {
      key: 4,
      label: 'Delete',
      handleClick: (data: any) => {
        setDelete(true);
      },
    },
  ];

  const triggerPatientUpdateToast = () => {
    if (!patient.loading) {
      const { updatePatient } = patient;
      setPatientOperationSuccess(updatePatient?.success);
      setPatientToast(updatePatient?.data?.message);
    }
  };
  useEffect(() => {
    triggerPatientUpdateToast();
  }, [patient.updatePatient]);
  useEffect(() => {
    dispatch(getPatientsRequest());
    dispatch(getGenderRequest());
    dispatch(getPatientDetailsRequest(patientId));
    dispatch(getPatientSession({ id: patientId }));
  }, [dispatch, patientId]);

  useEffect(() => {
    if (!parameters.loading) {
      if (!parameters.error) {
        dispatch(getPatientDetailsRequest(patientId));
        setOpen(false);
      }
    }
  }, [dispatch, patientId, parameters]);

  useEffect(() => {
    if (!advancedParameters.loading) {
      if (!advancedParameters.error) {
        dispatch(getPatientDetailsRequest(patientId));
        setAdvParametersOpen(false);
        setIsEdit(false);
      }
    }
  }, [dispatch, patientId, advancedParameters]);

  useEffect(() => {
    setJsonDataLoading(true);
    let filteredData: any = [];
    const timeoutId = setTimeout(() => {
      filteredData = (sessionListData || []).filter((e) => {
        return (
          (e.id && e.id.toString().includes(searchValue.toUpperCase())) ||
          (e.created_date &&
            getEstFormattedDateTime(e.created_date)
              .toUpperCase()
              .includes(searchValue.toUpperCase())) ||
          (e.duration &&
            secondsToHms(e.duration).toUpperCase().includes(searchValue.toUpperCase())) ||
          (e.voiced && secondsToHms(e.voiced).toUpperCase().includes(searchValue.toUpperCase())) ||
          (e.alert && e.alert.toString().includes(searchValue.toUpperCase())) ||
          (e.pitch && e.pitch.toString().includes(searchValue.toUpperCase())) ||
          (e.loudness && e.loudness.toString().includes(searchValue.toUpperCase())) ||
          `${e.device.serial_number} (${e.device.id})`.includes(searchValue.toUpperCase())
        );
      });
      setSearchList(filteredData);
      setJsonDataLoading(false);
    }, 1000);
    return () => clearTimeout(timeoutId);
  }, [sessionListData, searchValue]);

  const editPatient = (data: any) => {
    dispatch(getPatientDetailsRequest(data.id));
    setPatientID(data.id);
    setEditData(data);
    setEdit(true);
    setOpen(true);
  };

  const viewAdvancedParams = async () => {
    setJsonDataLoading(true);
    setAdvParametersOpen(true);
    const token = await localStorage.getItem('simbex_authtoken');
    const res = await GET(`/patient/${patientId}/advanced-parameters`, {
      headers: {
        'authorization': `Bearer ${token}`,
        'Cache-Control': 'no-cache,no-store, max-age=0',
      },
    });
    if (!res.success) setJsonData({});
    if (res.success) {
      setJsonData(res.data.result[0]);
      setEditForm(res.data.result[0]);
    }

    setJsonDataLoading(false);
  };

  const getDeviceDetails = () => {
    const device = get(patientDetails, 'devices', []).filter((item) => item.is_current);
    if (device.length !== 0) return `${device[0].serial_number} (ID:${device[0].id_device})`;
    return `No device assigned`;
  };

  const getClinicianName = () => {
    const clinician = get(patientDetails, 'clinicians', []).filter(
      (item) => item.is_primary_clinician,
    );
    if (clinician.length === 0) {
      return 'No Clinician';
    }
    return `${clinician[0].first_name} ${clinician[0].last_name}`;
  };

  const viewParameters = async () => {
    setJsonDataLoading(true);
    setOpen(true);
    setIsEdit(false);
    const token = await localStorage.getItem('simbex_authtoken');

    const res = await GET(`patient/${patientId}/parameters-by-clinician`, {
      headers: {
        'authorization': `Bearer ${token}`,
        'Cache-Control': 'no-cache,no-store, max-age=0',
      },
    });
    if (!res.success) setJsonData({});
    if (res.success) {
      setJsonData(res.data.result[0]);
      setEditForm(res.data.result[0]);
    }
    setJsonDataLoading(false);
  };

  const viewSessionParameters = async (data: any) => {
    setOpen(true);
    setViewOnly(true);
    setJsonData(data);
    setEditForm(data);
  };

  const viewAdvancedSessionParameters = async (data: any) => {
    setAdvParametersOpen(true);
    setViewOnly(true);
    setJsonData(data);
    setEditForm(data);
  };

  const viewSessionNotes = (sessionId: number, notes: Array<any>) => {
    setNotesOpen(true);
    setNotes({ sessionId, data: notes });
  };

  const handleSearch = (e: string) => {
    setSearchValue(e);
  };

  const handleAddParams = async (data?: number) => {
    setJsonDataLoading(true);
    setOpen(true);
    if (data) setIsEdit(false);
    else setIsEdit(true);
    const token = await localStorage.getItem('simbex_authtoken');
    let res;
    if (data) {
      res = await GET(`parameter/${data.toString()}`, {
        headers: {
          authorization: `Bearer ${token}`,
        },
      });
    }
    res = await GET(`parameter/patient/${patientId}/current`, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
    if (!res.success) setJsonData({});
    if (res.success) {
      setJsonData(res.data.result[0]);
      setEditForm(res.data.result[0]);
    }
    setJsonDataLoading(false);
  };

  const handleCloseAddParam = () => {
    setJsonData({});
    setIsEdit(false);
    setOpen(false);
    setViewOnly(false);
  };

  const handleCloseAddAdvParam = () => {
    setJsonData({});
    setIsEdit(false);
    setAdvParametersOpen(false);
    setViewOnly(false);
  };

  const handleDelegateSumbit = (data: any) => {
    dispatch(setPatientDelegateRequest(data, patientId));
    dispatch(getPatientDetailsRequest(patientId));
    setDelegateForm(false);
  };
  const deleteRows = (selected: Array<string>) =>
    selected.forEach((row: any) => {
      // debugger;
      dispatch(deletePatientsRequest(row, setAlert));
      history.goBack();
    });

  const editDataPatient: any = {
    data: patientDetails,
  };

  const updatePatient = (data: any) => {
    dispatch(updatePatientRequest(data, patientId, setAlert));
    setOpen(false);
    setEditOpen(false);
    setTimeout(() => {
      dispatch(getPatientDetailsRequest(patientId));
    }, 0);
  };

  const updateDiagnosis = (data: any) => {
    const patient = patientID;
    dispatch(
      setPatientDiagnosisRequest(
        createDiagnosisArray(list.diagnosis?.result, data),
        patient,
        edit,
        setAlert,
      ),
    );
    setMedInfoForm(false);
  };

  const handleDateIconClick = () => {
    if (dateOpen) {
      dispatch(
        getPatientSession({
          startDate: moment(date.startDate).format('YYYY-MM-DD'),
          endDate: moment(date.endDate).format('YYYY-MM-DD'),
          emr: get(patientDetails, 'patient.emr', ''),
          id: patientId,
        }),
      );
      setDateOpen(false);
    } else setDateOpen(true);
  };

  const handleClearFilter = () => {
    setDate({ startDate: new Date(), endDate: new Date(), key: 'selection', isChanged: false });
    dispatch(getPatientSession({ id: patientId }));
    setDateOpen(false);
  };

  const handleAddParameter = (e: any) => {
    Object.keys(e).map((a, b) => {
      if (e[a] === 'on') e[a] = true;
      else if (e[a] === 'off') e[a] = false;
    });
    dispatch(
      setParameterRequest(
        {
          parameterId: jsonData.id,
          payload: {
            ...e,
            id_person_patient: patientId,
            id_person_clinician: jsonData.id_person_clinician,
          },
        },
        {
          alert: setAlert,
          message: setPatientToast,
          status: setPatientOperationSuccess,
        },
      ),
    );
  };

  const handleAddAdvParameter = (e: any) => {
    dispatch(
      setAdvancedParameterRequest(
        {
          advancedParameterId: jsonData.id,
          patientId: patientId,
          payload: {
            ...e,
            id_person_patient: patientId,
            id_person_clinician: jsonData.id_person_clinician,
          },
        },
        {
          alert: setAlert,
          message: setPatientToast,
          status: setPatientOperationSuccess,
        },
      ),
    );
  };

  const getFormInitialData = () => {
    const obj: LooseObject = {};
    paramFormFieldList.map((item: string, index: number) => {
      obj[item] = jsonData[item];
    });
    return obj;
  };

  const getFormInitialDataForAdvParams = () => {
    const obj: LooseObject = {};
    advParamFormFieldList.map((item: string, index: number) => {
      obj[item] = jsonData[item];
    });
    return obj;
  };

  const auth = () => {
    return {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('simbex_authtoken')}`,
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache',
      },
    };
  };

  const handleCloseChat = () => {
    setChatOpen(false);
  };

  const onChatOpen = () => {
    setChatOpen(true);
    dispatch(setChatRequest({ patientId }));
  };

  const handleAddChat = async (e: any) => {
    dispatch(
      postChatRequest({
        patientId: patientId,
        payload: {
          chat_text: get(e, 'message', ''),
          record_date: `${new Date().toUTCString()}`,
          // id_person_from: get(userData, 'data.user.id'),
        },
      }),
    );

    reset({ message: '' });
  };

  const handleSessionClick = (id: any) => {
    history.push({
      pathname: '/session-visuals',
      state: { data: patientDetailsInfo, id: patientId, sessionId: id },
    });
  };

  /**
   * Calls API to generate csv of session data and automatically downloads the
   * file if API does not return an error.
   * @param data Information about the session.
   */
  function downloadSessionData(data: any) {
    setAlert(true);
    setPatientToast('Report Generation Started');
    setSessionDataDownload(true);
    getCSVData(data.id)
      .then((resp) => {
        setSessionDataDownload(false);
        setPatientOperationSuccess(true);
        const link = document.createElement('a');
        link.href = resp.url;
        //The filename here does not matter - the name is generated in the api
        link.setAttribute('download', `Session_${resp.sessionId}`);
        document.body.appendChild(link);
        link.click();
      })
      .catch(() => {
        setSessionDataDownload(false);
        setPatientOperationSuccess(false);
      });
  }

  function downloadRawData(data: any) {
    setAlert(true);
    setPatientToast('Acceleration data generation started');
    setPatientOperationSuccess(true);
    getRawData(data.id)
      .then((resp) => {
        setSessionDataDownload(false);
        setPatientOperationSuccess(true);
        const link = document.createElement('a');
        link.href = resp.url;
        //The filename here does not matter - the name is generated in the api
        link.setAttribute('download', `Session_${resp.sessionId}`);
        document.body.appendChild(link);
        link.click();
      })
      .catch(() => {
        setSessionDataDownload(false);
        setPatientOperationSuccess(false);
      });
  }

  const downloadPatientReport = (data: any) => {
    setSessionDetails(data);
    setReportForm(true);
  };

  function generate(data: any): void {
    setAlert(true);
    setPatientToast('download started');
    setPatientOperationSuccess(true);
    const { start_date, end_date, id } = sessionDetails;
    const subject = {
      ...data,
      treatmentStatus: data.treatmentStatus.label,
      gender: data.gender.label,
      //NOTE: Refer to README re: use of timezone
      session_date: moment.tz(start_date, 'America/New_York').format('MM/DD/YYYY'),
      start_time: getEstFormattedTime(start_date),
      end_time: getEstFormattedTime(end_date),
    };
    setSessionDataDownload(true);
    setReportForm(false);
    download(
      `/patient/${patientId}/session/${id}/report/${legendKeyReportdMap[metrics[1]]}/${
        legendKeyReportdMap[metrics[2]]
      }`,
      subject,
      `Patient-${patientId}-Session-${id}.docx`,
      false,
    )
      .then(() => setSessionDataDownload(false))
      .catch(() => setSessionDataDownload(false));
  }

  const handleClose = () => {
    setAlert(false);
  };
  return (
    <div className={classes.root}>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={alert}
        autoHideDuration={5000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={patientOperationSuccess ? 'success' : 'error'}>
          {patientToast}
        </Alert>
      </Snackbar>

      {open ? (
        <>
          <Dialog open onClose={handleCloseAddParam} style={{ width: '100%' }}>
            <div
              style={{
                backgroundColor: 'white',
                width: '100%',
                padding: jsonDataLoading ? 100 : 0,
              }}
            >
              {jsonDataLoading || patient.loading ? (
                <CircularProgress variant='indeterminate' />
              ) : (
                <>
                  <ParamterForm
                    handleClose={handleCloseAddParam}
                    initialValue={getFormInitialData()}
                    handleAdd={handleAddParameter}
                    disabled={!isEdit}
                    toggleDisabled={() => setIsEdit(!isEdit)}
                    viewOnly={viewOnly}
                  />
                </>
              )}
            </div>
          </Dialog>
        </>
      ) : null}

      {advParametersOpen ? (
        <>
          <Dialog open onClose={handleCloseAddAdvParam} style={{ width: '100%' }}>
            <div
              style={{
                backgroundColor: 'white',
                width: '100%',
                padding: jsonDataLoading ? 100 : 0,
              }}
            >
              {jsonDataLoading || patient.loading ? (
                <CircularProgress variant='indeterminate' />
              ) : (
                <>
                  <AdvancedParameterForm
                    handleClose={handleCloseAddAdvParam}
                    initialValue={getFormInitialDataForAdvParams()}
                    handleAdd={handleAddAdvParameter}
                    disabled={!isEdit}
                    toggleDisabled={() => setIsEdit(!isEdit)}
                    viewOnly={viewOnly}
                  />
                </>
              )}
            </div>
          </Dialog>
        </>
      ) : null}

      {notesOpen ? (
        <Dialog
          open={notesOpen}
          onClose={() => setNotesOpen(false)}
          style={{ width: '100%' }}
          fullWidth
          classes={{ paperWidthSm: 'max-width:unset;' }}
        >
          <div className={classes.chatContainer}>
            <div className={classes.chatHeaderContainer}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <NotesIcon style={{ height: 18, width: 18 }} htmlColor='#FFFFFF' />
                <Typography
                  style={{
                    color: 'white',
                    marginLeft: 10,
                    fontSize: 16,
                    fontWeight: 'bold',
                  }}
                >
                  {`Notes for Session ${notes.sessionId}`}
                </Typography>
              </div>

              <div
                style={{ color: 'white', cursor: 'pointer', alignSelf: 'center' }}
                onClick={() => setNotesOpen(false)}
              >
                <CloseIcon />
              </div>
            </div>
            {/* NOTE: The Chat Card widget already applies formatting to the the time */}
            {/* TODO: As of 2023-01-25, the phone app only saves the date of notes.  See Jira ticket
            https://simbexsw.atlassian.net/browse/INN043-698 noting fix needed on the app side 
            so we can get both date & time. Using ternary operator here until the app and API can be 
            updated.*/}
            <div style={{ overflow: 'auto', maxHeight: '15em' }}>
              {notes.data.map((note: any, noteIndex: any) => (
                <ChatCard
                  key={v4()}
                  text={note.note}
                  time={note.record_date_formatted ? note.record_date_formatted : note.record_date}
                  fromPatient={true}
                  lastChild={false}
                />
              ))}
            </div>
            {notes.data.length === 0 && (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginTop: 16,
                  padding: 100,
                }}
              >
                No Notes for this session
              </div>
            )}
          </div>
        </Dialog>
      ) : null}

      {dateOpen ? (
        <Dialog
          open={dateOpen}
          onClose={() => setDateOpen(false)}
          style={{ width: '100%' }}
          fullWidth
          classes={{ paperWidthSm: 'max-width:unset;' }}
        >
          <div className={classes.chatContainer}>
            <div className={classes.chatHeaderContainer}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <DateRangeIcon style={{ height: 18, width: 18 }} htmlColor='#FFFFFF' />
                <Typography
                  style={{
                    color: 'white',
                    marginLeft: 10,
                    fontSize: 16,
                    fontWeight: 'bold',
                  }}
                >
                  Filter
                </Typography>
              </div>

              <div
                style={{ color: 'white', cursor: 'pointer', alignSelf: 'center' }}
                onClick={() => setDateOpen(false)}
              >
                <CloseIcon />
              </div>
            </div>

            <DateRangePicker
              ranges={[date]}
              onChange={(dates: any) => {
                setDate({ ...dates.selection, isChanged: true });
              }}
              maxDate={new Date()}
            />

            <DialogActionsWrapper>
              <CancelButton
                autoFocus
                variant='contained'
                onClick={handleClearFilter}
                color='default'
              >
                <Typography variant='body1'>Clear</Typography>
              </CancelButton>

              <CustomButton onClick={handleDateIconClick} width='min-content'>
                <Typography variant='body1'>Apply</Typography>
              </CustomButton>
            </DialogActionsWrapper>
          </div>
        </Dialog>
      ) : null}

      {chatOpen ? (
        <Dialog
          open={chatOpen}
          onClose={handleCloseChat}
          style={{ width: '100%' }}
          fullWidth
          classes={{ paperWidthSm: 'max-width:unset;' }}
        >
          <div className={classes.chatContainer}>
            <div className={classes.chatHeaderContainer}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <ChatBubbleIcon style={{ height: 18, width: 18 }} htmlColor='#FFFFFF' />
                <Typography
                  style={{
                    color: 'white',
                    marginLeft: 10,
                    fontSize: 16,
                    fontWeight: 'bold',
                  }}
                >
                  Chats
                </Typography>
              </div>

              <div
                style={{ color: 'white', cursor: 'pointer', alignSelf: 'center' }}
                onClick={() => setChatOpen(false)}
              >
                <CloseIcon />
              </div>
            </div>

            <div style={{ overflow: 'auto', maxHeight: '15em' }}>
              {_.sortBy(chat.data, (c) => c.record_date).map(
                (chat: any, chatIndex: any, chats: any) => (
                  <ChatCard
                    key={v4()}
                    text={chat.chat_text}
                    time={chat.record_date_local}
                    fromPatient={chat.id_person_from === chat.id_person_patient}
                    lastChild={chatIndex === chats.length - 1}
                  />
                ),
              )}
            </div>
            {!chat.data.length && (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginTop: 16,
                  padding: chat.loading ? 100 : 0,
                }}
              >
                {chat.loading ? <CircularProgress /> : 'No Messages Yet'}
              </div>
            )}
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              margin: 10,
              padding: 10,
              alignItems: 'center',
            }}
          >
            <Controller
              render={(props) => {
                return (
                  <TextareaAutosize
                    rowsMin={5}
                    name={'message'}
                    onChange={props.onChange}
                    value={props.value}
                    style={{ height: '300px !important', width: '100%' }}
                    disabled={false}
                  />
                );
              }}
              name={'message'}
              control={control}
            />
            <div style={{ marginLeft: '20px' }}>
              <CustomButton
                height='10px'
                padding='18px'
                onClick={() => !patient.loading && handleAddChat(getValues())}
                width='min-content'
              >
                ADD
              </CustomButton>
            </div>
          </div>
        </Dialog>
      ) : null}

      <>
        <div className={classes.body}>
          <ArrowBackIosIcon
            fontSize='large'
            onClick={() => {
              history.goBack();
            }}
          />
        </div>
        <div className={classes.profileContainer}>
          <div className={classes.profileDiv}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  height: 66,
                  width: 66,
                  justifyContent: 'center',
                  backgroundColor: 'rgb(0, 124, 186)',
                  alignItems: 'center',
                  display: 'flex',
                  borderRadius: 4,
                }}
              >
                <Typography
                  style={{
                    color: '#FFFFFF',
                    fontSize: 20,
                    fontWeight: 'bold',
                  }}
                >
                  {patientId}
                </Typography>
              </div>
              <div
                style={{
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginLeft: 8,
                }}
              >
                <Typography className={classes.chatText}>
                  {get(patientDetails?.patient, 'patient_display_name', '')}
                </Typography>
              </div>
            </div>
            <div className={classes.headerSection}>
              <div style={{ cursor: 'text' }}>
                <Typography className={classes.deviceTextInfo} style={{ fontWeight: 'bold' }}>
                  Device:<span className={classes.innerValue}>{getDeviceDetails()}</span>
                </Typography>
              </div>
            </div>
            <div className={classes.headerSection}>
              <Typography className={classes.chatText}>
                Sex:
                <span className={classes.innerValue}>
                  {get(patientDetails?.patient, 'gender_text', '')}
                </span>
              </Typography>
              <div style={{ cursor: 'text' }}>
                <Typography className={classes.deviceTextInfo} style={{ fontWeight: 'bold' }}>
                  Primary Clinician:
                  <span className={classes.innerValue}>{getClinicianName()}</span>
                </Typography>
              </div>
            </div>
            {/*Defines the Subject information above the is of sessions */}
            <div className={classes.headerSection}>
              <Typography className={classes.chatText}>
                Custom ID:
                <span className={classes.innerValue}>
                  {get(patientDetails?.patient, 'emr', '')}
                </span>
              </Typography>
              <Typography className={classes.chatText}>
                Created On:
                <span className={classes.innerValue}>
                  {getEstFormattedDateTime(get(patientDetails?.patient, 'created_date', ''))}
                </span>
              </Typography>
            </div>
            <div className={classes.headerSection}>
              <div onClick={() => viewParameters()} style={{ cursor: 'pointer' }}>
                <a className={classes.link}>{`Active Parameters`}</a>
              </div>
              <div onClick={() => viewAdvancedParams()} style={{ cursor: 'pointer' }}>
                <a className={classes.link}>{`Active Advanced Parameters`}</a>
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <CustomButton width='max-content' onClick={onChatOpen}>
                <ChatIcon />
              </CustomButton>
              <MenuDropdown items={patientsOptionsMenu} data={get(patientDetails, 'patient', [])}>
                <MoreVertIcon color='primary' />
              </MenuDropdown>
            </div>
          </div>
        </div>

        <div className={classes.body}>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Typography variant='h6'>Sessions List (Top 100)</Typography>

            <div className={classes.chatAddContainer} onClick={handleDateIconClick}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  backgroundColor: '#555e67',
                  padding: 5,
                  borderRadius: 5,
                  marginLeft: 10,
                }}
              >
                <Typography
                  className={classes.deviceText}
                  style={{ marginRight: 8, cursor: 'pointer' }}
                >
                  Filter
                </Typography>
                <DateRangeIcon htmlColor='white' className={classes.sessionIcon} />
              </div>
            </div>
          </div>

          <div
            style={{
              alignItems: 'center',
              justifyContent: 'flex-end',
              display: 'flex',
            }}
          >
            <SearchIcon style={{ marginRight: -12 }} />
            <Input
              type='text'
              value={searchValue}
              onChange={(e: any) => handleSearch(e.target.value)}
              style={{
                width: 300,
                padding: 8,
                paddingLeft: 12,
                marginRight: 8,
                borderWidth: 0.3,
                borderColor: 'red',
              }}
              placeholder='Search'
            />
          </div>
        </div>
        {/* Displays the date range applied by the calendar filter */}
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          {date.isChanged && (
            <Typography style={{ color: 'black' }}>{`Sessions from ${moment(date.startDate).format(
              'MM/DD/YYYY',
            )} - ${moment(date.endDate).format('MM/DD/YYYY')}`}</Typography>
          )}
        </div>

        <SessionDetailsTable
          header={headCells}
          dataRows={searchValue ? searchList : isArray(sessionListData) ? sessionListData : []}
          loading={jsonDataLoading || sessionLoading}
          onAdvancedParametersPress={viewAdvancedSessionParameters}
          onParametersPress={viewSessionParameters}
          onSessionViewPress={handleSessionClick}
          onNotesViewPress={viewSessionNotes}
          onPatientReportDownload={downloadPatientReport}
          onRawAccelerationDataDownload={downloadRawData}
          on50msPhonationDataDownload={downloadSessionData}
        />

        <UserForm
          modalOpen={editOpen}
          modalClose={() => {
            setEditOpen(false);
            setMedInfoForm(false);
          }}
          onSubmit={updatePatient}
          edit={true}
          editData={editDataPatient}
          title={'Edit User'}
          patient={true}
          onEditDiagClick={(flag?: boolean) => {
            setEditOpen(false);
            setMedInfoForm(true);
          }}
        />
        <UserForm
          modalOpen={medInfoForm}
          modalClose={() => {
            setEditOpen(false);
            setMedInfoForm(false);
          }}
          onSubmit={updateDiagnosis}
          title={'Edit Diagnosis'}
          edit={true}
          editData={editDataPatient}
          medicalInfoForm={true}
          onEditDiagClick={(flag?: boolean) => {
            setMedInfoForm(false);
          }}
        />
        <UserForm
          modalOpen={delegateForm}
          modalClose={() => setDelegateForm(false)}
          onSubmit={handleDelegateSumbit}
          title='Delegate'
          edit={edit}
          editData={editData}
          delegateForm={true}
        />
        <UserForm
          modalOpen={resetPatientPassword}
          modalClose={onResetPatientPasswordClose}
          onSubmit={handleResetPatientPassword}
          title='Reset Patient Password'
          edit={false}
          resetPassword={true}
          resetPasswordForUser={true}
          isLoading={jsonDataLoading}
        />
        <UserForm
          modalOpen={reportForm}
          modalClose={() => setReportForm(false)}
          editData={patientDetailsInfo}
          onSubmit={generate}
          title={'Generate Report'}
          edit={true}
          reportForm={true}
        />
        <ConfirmationModal
          modalOpen={deleteOpen}
          modalClose={() => {
            setDelete(false);
          }}
          onClick={async () => {
            await deleteRows([patientDetails?.patient.id]);
            // history.back();
            setOpen(false);
          }}
          title='Delete'
        >
          {patient
            ? 'Are you sure you want to delete this Patient?'
            : 'Are you sure you want to delete this Clinician?'}
        </ConfirmationModal>
      </>
    </div>
  );
}

const mapStateToProps = (state: any) => ({
  userData: state.userAuth.userData,
  patient: state.patient,
  list: state.list,
  sessionListData: state.session.sessionsData,
  sessionLoading: state.session.loading,
  chat: state.chat,
  parameters: state.parameters,
  advancedParameters: state.advancedParameters,
});

export default connect(mapStateToProps)(Patients);
