import {
  ON_GET_DASHBOARD_DATA_REQUEST,
  ON_GET_DASHBOARD_DATA_SUCCESS,
  ON_GET_DASHBOARD_DATA_FAILED,
} from '../actionTypes';

const sessionInitialState = {
  loading: true,
  dashboardData: {},
  error: {},
};

const dashboardReducer = (state = sessionInitialState, action: any) => {
  switch (action.type) {
    case ON_GET_DASHBOARD_DATA_REQUEST:
      return { ...state, loading: true };
    case ON_GET_DASHBOARD_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        dashboardData: action.payload,
        error: {},
      };
    case ON_GET_DASHBOARD_DATA_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
        dashboardData: {},
      };
    default:
      return state;
  }
};

export default dashboardReducer;
