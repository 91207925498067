import { call, takeLatest, put } from 'redux-saga/effects';

import {
  ON_ASSIGN_DEVICE_REQUEST,
  ON_GET_ALL_DEVICES_REQUEST,
  ON_GET_ASSIGNABLE_DEVICES_REQUEST,
  ON_GET_CURRENT_DEVICE_REQUEST,
  ON_UNASSIGN_DEVICE_REQUEST,
} from '../actionTypes';
import {
  getAssignableDevicesFailed,
  getAssignableDevicesSuccess,
  getCurrentDeviceFailed,
  getCurrentDeviceSuccess,
  unAssignDeviceFailed,
  unAssignDeviceSuccess,
  getAllDevicesSuccess,
  getAllDevicesFailed,
} from '../actions';
import {
  assignDevice,
  getAssignableDevices,
  getCurrentDevice,
  unAssignDevice,
  getDevices,
} from '../../utils/services/device';

function* getAssignableDevicesSaga(action: any) {
  try {
    const { personID } = action;
    const result = yield call(getAssignableDevices, personID);
    if (!result.success) throw result.data;
    yield put(getAssignableDevicesSuccess(result));
  } catch (error) {
    yield put(getAssignableDevicesFailed(error));
  }
}
function* getCurrentDeviceSaga(action: any) {
  try {
    const { personID } = action;
    const result = yield call(getCurrentDevice, personID);
    if (!result.success) throw result.data;
    yield put(getCurrentDeviceSuccess(result));
  } catch (error) {
    yield put(getCurrentDeviceFailed(error));
  }
}
function* unAssignDeviceSaga(action: any) {
  try {
    const { personID, deviceID } = action;
    const result = yield call(unAssignDevice, personID, deviceID);
    if (!result.success) throw result.data;
    yield put(unAssignDeviceSuccess(result));
  } catch (error) {
    yield put(unAssignDeviceFailed(error));
  }
}
function* assignDeviceSaga(action: any) {
  try {
    const { deviceID, data } = action;
    const result = yield call(assignDevice, data, deviceID);
    if (!result.success) throw result.data;
    yield put(unAssignDeviceSuccess(result));
  } catch (error) {
    yield put(unAssignDeviceFailed(error));
  }
}
function* getAllDevices(action: any) {
  try {
    const result = yield call(getDevices);
    if (!result.success) throw result.data;
    yield put(getAllDevicesSuccess(result.data));
  } catch (error) {
    yield put(getAllDevicesFailed(error));
  }
}
const deviceSaga = [
  takeLatest(ON_GET_ASSIGNABLE_DEVICES_REQUEST, getAssignableDevicesSaga),
  takeLatest(ON_GET_CURRENT_DEVICE_REQUEST, getCurrentDeviceSaga),
  takeLatest(ON_UNASSIGN_DEVICE_REQUEST, unAssignDeviceSaga),
  takeLatest(ON_ASSIGN_DEVICE_REQUEST, assignDeviceSaga),
  takeLatest(ON_GET_ALL_DEVICES_REQUEST, getAllDevices),
];

export default deviceSaga;
