//DeviceForm.tsx
/**
 * Defines a view with details about the sensor.
 *
 * @module
 * @author:
 * @copyright:
 */
import {
  Checkbox,
  Grid,
  styled,
  Table,
  TableContainer,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  TextField,
  Typography,
  LinearProgress,
  IconButton,
  makeStyles,
  createStyles,
} from '@material-ui/core';
import { connect, useDispatch } from 'react-redux';
import DeleteIcon from '@material-ui/icons/Delete';
import { getCurrentDeviceRequest, unAssignDeviceRequest } from '../store/actions';
import Dropdown from './Dropdown';
import CustomButton from './CustomButton';

import { getEstFormattedDateTime } from '../utils/helper/formatTime';

const GridItem = styled(Grid)({
  padding: '10px 20px !important',
});

const IconButtonWrapper = styled(IconButton)({
  padding: 0,
});

const useStyles = makeStyles(() =>
  createStyles({
    wrapper: {
      display: 'flex',
      flex: 1,
      flexDirection: 'row',
      alignItems: 'center',
    },
    buttonDiv: {
      marginLeft: '10px',
    },
  }),
);

function DeviceForm({
  control,
  errors,
  device,
  onAddDevice,

  clinicianID,
}: any) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { loading, assignableDevices, currentDevices } = device;
  const devices = assignableDevices?.data?.result || [];
  const deviceDropdown = devices.map((item: any) => {
    return {
      id: item.id,
      label: `${item.serial_number} (${item.mac_address})`,
    };
  });
  const currentDevice = currentDevices?.data?.result || [];
  const handleUnassignDevice = (data: any) => {
    dispatch(unAssignDeviceRequest(clinicianID, data.id));
    setTimeout(() => {
      dispatch(getCurrentDeviceRequest(clinicianID));
    }, 1000);
  };
  return (
    <Grid container spacing={5}>
      <TableContainer>
        {loading && <LinearProgress />}
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align='left'>Serial Number</TableCell>
              {/* <TableCell>Pass Key</TableCell> */}
              <TableCell>Added On</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          {currentDevice.length !== 0 && (
            <TableBody>
              {currentDevice.map((row: any) => (
                <TableRow key={row.id}>
                  <TableCell component='th' scope='row'>
                    {row.serial_number}
                  </TableCell>
                  {/* <TableCell>{row.passkey}</TableCell> */}
                  <TableCell>{getEstFormattedDateTime(row.created_date)}</TableCell>
                  <TableCell>
                    <IconButtonWrapper onClick={() => handleUnassignDevice(row)}>
                      <DeleteIcon color='error' />
                    </IconButtonWrapper>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
        {currentDevice.length === 0 && (
          <GridItem xs={12} sm={12} style={{ textAlign: 'center' }}>
            <Typography
              variant='body1'
              style={{
                padding: '100px',
              }}
            >
              No Data
            </Typography>
          </GridItem>
        )}
        {/* The width is a magic number to ensure that the device dropdown is wide enough to show 
        the device options when a clinician has no devices assigned to them. See INN043-781*/}
        <GridItem xs={12} sm={12}>
          <Typography variant='subtitle2' style={{ width: '500px' }}>
            Device
          </Typography>
          <div className={classes.wrapper}>
            <Dropdown
              fieldRequired={true}
              data={deviceDropdown}
              control={control}
              error={errors}
              label='device'
              name='device'
            />
            <div className={classes.buttonDiv}>
              <CustomButton onClick={onAddDevice} width='min-content'>
                <Typography variant='body1'>Add</Typography>
              </CustomButton>
            </div>
          </div>

          {errors.device && (
            <Typography variant='caption' color='error'>
              required
            </Typography>
          )}
        </GridItem>
      </TableContainer>
    </Grid>
  );
}

const mapStateToProps = (state: any) => ({
  device: state.device,
});

export default connect(mapStateToProps)(DeviceForm);
