import { createStore, applyMiddleware, compose } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleWare, { SagaMiddleware } from 'redux-saga';

// import logger from "redux-logger"; //logger for dev

import rootReducer from './reducers/index';
import { rootSaga } from './sagas';

const sagaMiddleware = createSagaMiddleWare();
const middlewares = [sagaMiddleware];
// if (process.env.NODE_ENV === "development")
//   middlewares.push(logger as SagaMiddleware);
// const middlewares = [sagaMiddleware, logger]; //uncomment for development logs and comment above

export default createStore(
  rootReducer,
  compose(composeWithDevTools(applyMiddleware(...middlewares))),
);

sagaMiddleware.run(rootSaga);
