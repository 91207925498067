import { get, auth } from '../index';
const ENDPOINT = '/list';

export const getStates = () => {
  const url = `${ENDPOINT}/states`;

  return get(url, auth());
};
export const getGender = () => {
  const url = `${ENDPOINT}/genders`;

  return get(url, auth());
};
export const getCredentials = () => {
  const url = `${ENDPOINT}/clinician_credentials`;

  return get(url, auth());
};
export const getTreatmentStatus = () => {
  const url = `${ENDPOINT}/treatment_statuses`;

  return get(url, auth());
};
export const getDiagnosisTypes = () => {
  const url = `${ENDPOINT}/diagnosis_types`;

  return get(url, auth());
};
export const getTimeZones = () => {
  const url = `${ENDPOINT}/time_zones`;

  return get(url, auth());
};
