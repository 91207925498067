import React from 'react';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@material-ui/core/styles';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';

import store from './store';
import theme from './theme';

import App from './screens/App';

import { ConnectedRouter } from 'react-router-redux';
const createHistory = require('history').createBrowserHistory;
const persistor = persistStore(store);

const history = createHistory();

function Index() {
  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <App />
        </PersistGate>
      </Provider>
    </ThemeProvider>
  );
}

export default Index;
