import {
  ON_SET_CHAT_REQUEST,
  ON_SET_CHAT_SUCCESS,
  ON_SET_CHAT_FAILED,
  ON_POST_CHAT_REQUEST,
} from '../actionTypes/chat.actionTypes';

const chatInitialState = {
  loading: true,
  data: [],
  error: null,
};

const chatReducer = (state = chatInitialState, action: any) => {
  switch (action.type) {
    case ON_SET_CHAT_REQUEST:
      return chatInitialState;

    case ON_SET_CHAT_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null,
      };

    case ON_SET_CHAT_FAILED:
      return {
        loading: false,
        data: [],
        error: action.payload,
      };

    case ON_POST_CHAT_REQUEST:
      return {
        loading: true,
        data: [],
        error: null,
      };
    default:
      return state;
  }
};

export default chatReducer;
