import { CircularProgress, Grid, styled, TextField, Typography } from '@material-ui/core';
import { get } from 'lodash';
import React, { useEffect } from 'react';
import { Controller } from 'react-hook-form';

const GridItem = styled(Grid)({
  padding: '10px 20px !important',
});

const Input = styled(TextField)({
  width: '100%',
  marginTop: 10,
});
function ResetPassword({ forUser, isLoading, control, errors, list, watch }: any) {
  if (isLoading) {
    return (
      <div style={{ padding: '100px' }}>
        <CircularProgress variant='indeterminate' />
      </div>
    );
  }
  return (
    <Grid container spacing={5}>
      {!forUser && (
        <GridItem item xs={12} sm={12}>
          <Typography variant='subtitle2'>Old Password</Typography>
          <Controller
            as={<Input size='small' variant='outlined' />}
            name='oldPassword'
            control={control}
            defaultValue=''
            type='password'
            rules={{
              required: true,
            }}
          />
          {errors.oldPassword && (
            <Typography variant='caption' color='error'>
              required
            </Typography>
          )}
        </GridItem>
      )}
      <GridItem item xs={12} sm={12}>
        <Typography variant='subtitle2'>New Password</Typography>
        <Controller
          as={<Input size='small' variant='outlined' />}
          name='newPassword'
          control={control}
          defaultValue=''
          type='password'
          rules={{
            required: true,
          }}
        />
        {errors.newPassword && (
          <Typography variant='caption' color='error'>
            required
          </Typography>
        )}
      </GridItem>
      <GridItem item xs={12} sm={12}>
        <Typography variant='subtitle2'>Confirm Password</Typography>
        <Controller
          as={<Input size='small' variant='outlined' />}
          name='confirmPassword'
          control={control}
          defaultValue=''
          type='password'
          rules={{
            required: true,
            validate: (value) => value === watch('newPassword'),
          }}
        />
        {errors.confirmPassword && (
          <Typography variant='caption' color='error'>
            {errors.confirmPassword.type === 'required' ? 'required' : 'Passwords do not match'}
          </Typography>
        )}
      </GridItem>
    </Grid>
  );
}

export default ResetPassword;
