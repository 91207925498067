export const ON_GET_PATIENTS_DATA_REQUEST = 'ON_GET_PATIENTS_DATA_REQUEST';
export const ON_GET_PATIENTS_DATA_SUCCESS = 'ON_GET_PATIENTS_DATA_SUCCESS';
export const ON_GET_PATIENTS_DATA_FAILED = 'ON_GET_PATIENTS_DATA_FAILED';

export const ON_SET_PATIENT_REQUEST = 'ON_SET_PATIENT_REQUEST';
export const ON_SET_PATIENT_SUCCESS = 'ON_SET_PATIENT_SUCCESS';
export const ON_SET_PATIENT_FAILED = 'ON_SET_PATIENT_FAILED';

export const ON_DELETE_PATIENT_REQUEST = 'ON_DELETE_PATIENT_REQUEST';
export const ON_DELETE_PATIENT_SUCCESS = 'ON_DELETE_PATIENT_SUCCESS';
export const ON_DELETE_PATIENT_FAILED = 'ON_DELETE_PATIENT_FAILED';

export const ON_SET_PATIENT_DIAGNOSIS_REQUEST = 'ON_SET_PATIENT_DIAGNOSIS_REQUEST';
export const ON_SET_PATIENT_DIAGNOSIS_SUCCESS = 'ON_SET_PATIENT_DIAGNOSIS_SUCCESS';
export const ON_SET_PATIENT_DIAGNOSIS_FAILED = 'ON_SET_PATIENT_DIAGNOSIS_FAILED';

export const ON_UPDATE_PATIENT_REQUEST = 'ON_UPDATE_PATIENT_REQUEST';
export const ON_UPDATE_PATIENT_SUCCESS = 'ON_UPDATE_PATIENT_SUCCESS';
export const ON_UPDATE_PATIENT_FAILED = 'ON_UPDATE_PATIENT_FAILED';

export const ON_GET_PATIENT_DETAILED_DATA_REQUEST = 'ON_GET_PATIENT_DETAILED_DATA_REQUEST';
export const ON_GET_PATIENT_DETAILED_DATA_SUCCESS = 'ON_GET_PATIENT_DETAILED_DATA_SUCCESS';
export const ON_GET_PATIENT_DETAILED_DATA_FAILED = 'ON_GET_PATIENT_DETAILED_DATA_FAILED';

export const ON_SET_PATIENT_DELEGATE_REQUEST = 'ON_SET_PATIENT_DELEGATE_REQUEST';
export const ON_SET_PATIENT_DELEGATE_SUCCESS = 'ON_SET_PATIENT_DELEGATE_SUCCESS';
export const ON_SET_PATIENT_DELEGATE_FAILED = 'ON_SET_PATIENT_DELEGATE_FAILED';

export const ON_UNSET_PATIENT_DELEGATE_REQUEST = 'ON_UNSET_PATIENT_DELEGATE_REQUEST';
export const ON_UNSET_PATIENT_DELEGATE_SUCCESS = 'ON_UNSET_PATIENT_DELEGATE_SUCCESS';
export const ON_UNSET_PATIENT_DELEGATE_FAILED = 'ON_UNSET_PATIENT_DELEGATE_FAILED';

export const ON_PATIENT_SESSION_REQUEST = 'ON_PATIENT_SESSION_REQUEST';
export const ON_PATIENT_SESSION_SUCCESS = 'ON_PATIENT_SESSION_SUCCESS';
export const ON_PATIENT_SESSION_FAILED = 'ON_PATIENT_SESSION_FAILED';

export const ON_DEVICE_LIST_REQUEST = 'ON_DEVICE_LIST_REQUEST';
export const ON_DEVICE_LIST_SUCCESS = 'ON_DEVICE_LIST_SUCCESS';
export const ON_DEVICE_LIST_FAILED = 'ON_DEVICE_LIST_FAILED';

export const ON_GET_CLINICIANS_FOR_DELEGATE_REQUEST = 'ON_GET_CLINICIANS_FOR_DELEGATE_REQUEST';
export const ON_GET_CLINICIANS_FOR_DELEGATE_SUCCESS = 'ON_GET_CLINICIANS_FOR_DELEGATE_SUCCESS';
export const ON_GET_CLINICIANS_FOR_DELEGATE_FAILED = 'ON_GET_CLINICIANS_FOR_DELEGATE_FAILED';
