import { getDeviceFailed, getDeviceSuccess } from './../actions/list.actions';
import {
  ON_DEVICE_LIST_REQUEST,
  ON_GET_CLINICIANS_FOR_DELEGATE_REQUEST,
  ON_PATIENT_SESSION_REQUEST,
} from './../actionTypes/patient.actionTypes';
import { call, takeLatest, put } from 'redux-saga/effects';

import {
  ON_DELETE_PATIENT_REQUEST,
  ON_DELETE_PATIENT_SUCCESS,
  ON_GET_PATIENTS_DATA_REQUEST,
  ON_GET_PATIENT_DETAILED_DATA_REQUEST,
  ON_SET_PATIENT_DELEGATE_REQUEST,
  ON_SET_PATIENT_DELEGATE_SUCCESS,
  ON_SET_PATIENT_DIAGNOSIS_REQUEST,
  ON_SET_PATIENT_REQUEST,
  ON_SET_PATIENT_SUCCESS,
  ON_UNSET_PATIENT_DELEGATE_REQUEST,
  ON_UNSET_PATIENT_DELEGATE_SUCCESS,
  ON_UPDATE_PATIENT_REQUEST,
} from '../actionTypes';
import {
  getPatientsSuccess,
  getPatientsFailed,
  deletePatientsFailed,
  deletePatientsSuccess,
  setPatientSuccess,
  setPatientDiagnosisFailed,
  updatePatientFailed,
  updatePatientSuccess,
  getPatientDetailsSuccess,
  getPatientDetailsFailed,
  setPatientDelegateSuccess,
  setPatientDelegateFailed,
  unSetPatientDelegateFailed,
  unSetPatientDelegateSuccess,
  getPatientSessionSuccess,
  getPatientDetailsRequest,
  getCliniciansForDelegateSuccess,
  getCliniciansForDelegateFailed,
} from '../actions';
import {
  delegatePatient,
  deletePatient,
  getCliniciansForDelegate,
  getDeviceList,
  getPatientDetails,
  getPatients,
  getPatientSession,
  setPatient,
  setPatientDiagnosis,
  undoDelegatePatient,
  updatePatient,
} from '../../utils/services/patient';

function* getPatientsSaga(action: any) {
  try {
    const result = yield call(getPatients);
    if (!result.success) throw result;

    yield put(getPatientsSuccess(result));
  } catch (error) {
    yield put(getPatientsFailed(error));
  }
}
function* setPatientSaga(action: any) {
  try {
    const { data } = action;
    const patient = {
      emr: data.emr,
      id_gender: data.gender.id,
      id_patient_treatment_status: data.treatmentStatus.id,
      id_person_clinician: data.clinician.id,
    };
    const result = yield call(setPatient, patient);
    if (!result.success) throw result;
    yield put(setPatientSuccess(result));
    action.toast(true);
  } catch (error) {
    yield put(setPatientSuccess(error));
    action.toast(true);
  }
}
function* deletePatientSaga(action: any) {
  try {
    const { data } = action;

    const result = yield call(deletePatient, data);
    if (!result.success) throw result;
    yield put(deletePatientsSuccess(result));
    action.toast(true);
  } catch (error) {
    yield put(deletePatientsFailed(error));
    action.toast(true);
  }
}
function* setPatientDiagnosisSaga(action: any) {
  try {
    const { data } = action;
    const result = yield call(setPatientDiagnosis, action);
    if (!result.success) throw result;
    yield put(updatePatientSuccess(result));
    action.toast(true);
  } catch (error) {
    yield put(updatePatientFailed(error));
    action.toast(true);
  }
}
function* updatePatientSaga(action: any) {
  try {
    const { data, patientID } = action;
    const patient = {
      emr: data.emr,
      id_device: data?.device?.id || 0,
      id_gender: data.gender.id,
      id_patient_treatment_status: data.treatmentStatus.id,
      id_person_clinician: data.clinician.id,
      id_org: data.clinician.id_org,
    };
    const result = yield call(updatePatient, patient, patientID);
    if (!result.success) throw result;
    yield put(updatePatientSuccess(result));
    action.toast(true);
  } catch (error) {
    yield put(updatePatientFailed(error));
    action.toast(true);
  }
}
function* getPatientDetailsSaga(action: any) {
  try {
    const { patientID } = action;
    const result = yield call(getPatientDetails, patientID);
    if (!result.success) throw result.data;
    yield put(getPatientDetailsSuccess(result));
  } catch (error) {
    yield put(getPatientDetailsFailed(error));
  }
}
function* delegatePatientSaga(action: any) {
  try {
    const { data, patientID } = action;
    const patient = {
      id_person_clinician: data.delegateClinician.id,
    };
    const result = yield call(delegatePatient, patient, patientID);
    if (!result.success) throw result.data;
    yield put(setPatientDelegateSuccess(result));
    yield put(getPatientDetailsRequest(patientID));
  } catch (error) {
    yield put(setPatientDelegateFailed(error));
  }
}
function* undoDelegatePatientSaga(action: any) {
  try {
    const { data, patientID } = action;
    const patient = {
      id_person_clinician: data.id_person_clinician,
    };
    const result = yield call(undoDelegatePatient, patient, patientID);
    if (!result.success) throw result.data;
    yield put(unSetPatientDelegateSuccess(result));
    yield put(getPatientDetailsRequest(patientID));
  } catch (error) {
    yield put(unSetPatientDelegateFailed(error));
  }
}

function* patientSessionSaga(action: any) {
  try {
    const { startDate, endDate, emr, id } = action.data;
    const result = yield call(getPatientSession, {
      startDate,
      endDate,
      emr,
      patientId: id,
    });
    if (result.success) {
      yield put(getPatientSessionSuccess(result.data.result));
    } else yield put(getPatientSessionSuccess([]));
  } catch (err) {
    yield put(getPatientSessionSuccess([]));
  }
}

function* deviceListSaga(action: any) {
  try {
    const { isClinician, personID, clinicianID } = action.payload;
    const result = yield call(getDeviceList, {
      personID,
      isClinician,
      clinicianID,
    });
    if (!result.success) throw result.data;
    if (result.success) {
      yield put(getDeviceSuccess(result.data));
    }
  } catch (err) {
    yield put(getDeviceFailed(err));
  }
}
function* getCliniciansForDelegateSaga(action: any) {
  try {
    const { data } = action;
    const result = yield call(getCliniciansForDelegate, data);
    if (!result.success) throw result.data;
    yield put(getCliniciansForDelegateSuccess(result));
  } catch (error) {
    yield put(getCliniciansForDelegateFailed(error));
  }
}
const patientSaga = [
  takeLatest(ON_GET_PATIENTS_DATA_REQUEST, getPatientsSaga),
  takeLatest(ON_SET_PATIENT_REQUEST, setPatientSaga),
  takeLatest(ON_DELETE_PATIENT_REQUEST, deletePatientSaga),
  takeLatest(ON_DELETE_PATIENT_SUCCESS, getPatientsSaga),
  takeLatest(ON_SET_PATIENT_SUCCESS, getPatientsSaga),
  takeLatest(ON_SET_PATIENT_DIAGNOSIS_REQUEST, setPatientDiagnosisSaga),
  takeLatest(ON_UPDATE_PATIENT_REQUEST, updatePatientSaga),
  takeLatest(ON_GET_PATIENT_DETAILED_DATA_REQUEST, getPatientDetailsSaga),
  takeLatest(ON_SET_PATIENT_DELEGATE_REQUEST, delegatePatientSaga),
  takeLatest(ON_UNSET_PATIENT_DELEGATE_REQUEST, undoDelegatePatientSaga),
  takeLatest(ON_PATIENT_SESSION_REQUEST, patientSessionSaga),
  takeLatest(ON_DEVICE_LIST_REQUEST, deviceListSaga),
  takeLatest(ON_GET_CLINICIANS_FOR_DELEGATE_REQUEST, getCliniciansForDelegateSaga),
];

export default patientSaga;
