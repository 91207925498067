import {
  ON_SET_CHAT_FAILED,
  ON_SET_CHAT_REQUEST,
  ON_SET_CHAT_SUCCESS,
  ON_POST_CHAT_REQUEST,
} from './../actionTypes/chat.actionTypes';

export const setChatRequest = (data: any) => ({
  type: ON_SET_CHAT_REQUEST,
  data,
});

export const setChatSuccess = (data: any) => ({
  type: ON_SET_CHAT_SUCCESS,
  payload: data,
});

export const setChatFailed = (data: any) => ({
  type: ON_SET_CHAT_FAILED,
  payload: data,
});

export const postChatRequest = (data: any) => ({
  type: ON_POST_CHAT_REQUEST,
  data,
});
