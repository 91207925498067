import React from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';

interface InfoCardsStyleProps {
  color: string;
  isAdmin: number;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      'display': 'flex',
      'height': theme.spacing(15),
      'backgroundColor': (props: InfoCardsStyleProps) => props.color,
      'marginRight': (props: InfoCardsStyleProps) => (props.isAdmin ? 0 : theme.spacing(5)),
      '&:hover': {
        cursor: 'pointer',
      },
    },
    content: {
      'flex': '1 0 auto',
      'display': 'flex',
      'flexDirection': 'column',
      'justifyContent': 'space-around',
      '& .MuiTypography-root': {
        color: 'white',
      },
    },
    cover: {
      padding: '1em',
    },
  }),
);

interface InfoCardsProps {
  value: number;
  label: string;
  Icon: React.FC;
  color: string;
  isAdmin: number;
  onClick: any;
}

function InfoCards({ label, value, Icon, ...props }: InfoCardsProps) {
  const classes = useStyles(props);
  return (
    <Card className={classes.root} onClick={props.onClick}>
      <CardContent className={classes.content}>
        <Typography variant='h5'>{value || 0}</Typography>
        <Typography>{label}</Typography>
      </CardContent>
      <CardMedia className={classes.cover}>
        <Icon />
      </CardMedia>
    </Card>
  );
}

export default InfoCards;
