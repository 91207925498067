import { call, takeLatest, put } from 'redux-saga/effects';

import {
  ON_GET_CLINICIAN_CREDENTIALS_REQUEST,
  ON_GET_DIAGNOSIS_REQUEST,
  ON_GET_GENDER_DATA_REQUEST,
  ON_GET_STATES_DATA_REQUEST,
  ON_GET_TIME_ZONES_REQUEST,
  ON_GET_TREATMENT_STATUS_REQUEST,
} from '../actionTypes';
import {
  getStatesSuccess,
  getStatesFailed,
  getGenderSuccess,
  getGenderFailed,
  getClinicianCredentialsSuccess,
  getClinicianCredentialsFailed,
  getTreatmentStatusSuccess,
  getTreatmentStatusFailed,
  getDiagnosisSuccess,
  getDiagnosisFailed,
  getTimeZoneSuccess,
  getTimeZoneFailed,
} from '../actions';
import {
  getCredentials,
  getDiagnosisTypes,
  getGender,
  getStates,
  getTimeZones,
  getTreatmentStatus,
} from '../../utils/services/list';

function* getStatesSaga(action: any) {
  try {
    const result = yield call(getStates);
    if (!result.success) throw result.data;
    yield put(getStatesSuccess(result));
  } catch (error) {
    yield put(getStatesFailed(error));
  }
}
function* getGenderSaga(action: any) {
  try {
    const result = yield call(getGender);
    if (!result.success) throw result.data;
    yield put(getGenderSuccess(result));
  } catch (error) {
    yield put(getGenderFailed(error));
  }
}
function* getClinicianCredentialsSaga(action: any) {
  try {
    const result = yield call(getCredentials);
    if (!result.success) throw result.data;
    yield put(getClinicianCredentialsSuccess(result));
  } catch (error) {
    yield put(getClinicianCredentialsFailed(error));
  }
}
function* getTreatmentStatusSaga(action: any) {
  try {
    const result = yield call(getTreatmentStatus);
    if (!result.success) throw result.data;
    yield put(getTreatmentStatusSuccess(result));
  } catch (error) {
    yield put(getTreatmentStatusFailed(error));
  }
}
function* getDiagnosisSaga(action: any) {
  try {
    const result = yield call(getDiagnosisTypes);
    if (!result.success) throw result.data;
    yield put(getDiagnosisSuccess(result?.data));
  } catch (error) {
    yield put(getDiagnosisFailed(error));
  }
}
function* getTimeZonesSaga(action: any) {
  try {
    const result = yield call(getTimeZones);
    if (!result.success) throw result.data;
    yield put(getTimeZoneSuccess(result?.data));
  } catch (error) {
    yield put(getTimeZoneFailed(error));
  }
}
const listSaga = [
  takeLatest(ON_GET_STATES_DATA_REQUEST, getStatesSaga),
  takeLatest(ON_GET_GENDER_DATA_REQUEST, getGenderSaga),
  takeLatest(ON_GET_CLINICIAN_CREDENTIALS_REQUEST, getClinicianCredentialsSaga),
  takeLatest(ON_GET_TREATMENT_STATUS_REQUEST, getTreatmentStatusSaga),
  takeLatest(ON_GET_DIAGNOSIS_REQUEST, getDiagnosisSaga),
  takeLatest(ON_GET_TIME_ZONES_REQUEST, getTimeZonesSaga),
];
export default listSaga;
