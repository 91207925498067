import {
  ON_ASSIGN_DEVICE_FAILED,
  ON_ASSIGN_DEVICE_REQUEST,
  ON_ASSIGN_DEVICE_SUCCESS,
  ON_GET_ASSIGNABLE_DEVICES_FAILED,
  ON_GET_ASSIGNABLE_DEVICES_REQUEST,
  ON_GET_ASSIGNABLE_DEVICES_SUCCESS,
  ON_GET_CURRENT_DEVICE_FAILED,
  ON_GET_CURRENT_DEVICE_REQUEST,
  ON_GET_CURRENT_DEVICE_SUCCESS,
  ON_UNASSIGN_DEVICE_FAILED,
  ON_UNASSIGN_DEVICE_REQUEST,
  ON_UNASSIGN_DEVICE_SUCCESS,
  ON_GET_ALL_DEVICES_REQUEST,
  ON_GET_ALL_DEVICES_SUCCESS,
  ON_GET_ALL_DEVICES_FAILED,
} from '../actionTypes';

export const getAssignableDevicesRequest = (personID: number) => ({
  type: ON_GET_ASSIGNABLE_DEVICES_REQUEST,
  personID,
});

export const getAssignableDevicesSuccess = (data: any) => ({
  type: ON_GET_ASSIGNABLE_DEVICES_SUCCESS,
  payload: data,
});

export const getAssignableDevicesFailed = (data: any) => ({
  type: ON_GET_ASSIGNABLE_DEVICES_FAILED,
  payload: data,
});

export const getCurrentDeviceRequest = (personID: number) => ({
  type: ON_GET_CURRENT_DEVICE_REQUEST,
  personID,
});

export const getCurrentDeviceSuccess = (data: any) => ({
  type: ON_GET_CURRENT_DEVICE_SUCCESS,
  payload: data,
});

export const getCurrentDeviceFailed = (data: any) => ({
  type: ON_GET_CURRENT_DEVICE_FAILED,
  payload: data,
});

export const unAssignDeviceRequest = (personID: any, deviceID: any) => ({
  type: ON_UNASSIGN_DEVICE_REQUEST,
  personID,
  deviceID,
});

export const unAssignDeviceSuccess = (data: any) => ({
  type: ON_UNASSIGN_DEVICE_SUCCESS,
  payload: data,
});

export const unAssignDeviceFailed = (data: any) => ({
  type: ON_UNASSIGN_DEVICE_FAILED,
  payload: data,
});

export const assignDeviceRequest = (data: any, deviceID: number) => ({
  type: ON_ASSIGN_DEVICE_REQUEST,
  data,
  deviceID,
});

export const assignDeviceSuccess = (data: any) => ({
  type: ON_ASSIGN_DEVICE_SUCCESS,
  payload: data,
});

export const assignDeviceFailed = (data: any) => ({
  type: ON_ASSIGN_DEVICE_FAILED,
  payload: data,
});

export const getAllDevicesRequest = (data?: any) => ({
  type: ON_GET_ALL_DEVICES_REQUEST,
  payload: data,
});

export const getAllDevicesSuccess = (data: any) => ({
  type: ON_GET_ALL_DEVICES_SUCCESS,
  payload: data,
});

export const getAllDevicesFailed = (data: any) => ({
  type: ON_GET_ALL_DEVICES_FAILED,
  payload: data,
});
