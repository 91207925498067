import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import { isLogin } from '../utils/middleware/auth';
import Sidebar from './Sidebar';

interface PrivateRouteProps {
  key: string;
  component: any;
  path: string;
  pageTitle: string;
  subRoutes: Array<string | null>;
  nextPage: object | string | null;
  prevPage: object | string | null;
  exact: Boolean;
  isPrivate: Boolean;
}
export function PrivateRoute({
  component: Component,
  path,
  subRoutes,
  nextPage,
  prevPage,
  ...rest
}: PrivateRouteProps) {
  // const isLoggedIn = await isLogin();
  return (
    <Route
      {...rest}
      exact
      path={path}
      render={(props) => {
        return (
          <>
            {localStorage.getItem('simbex_authtoken') ? (
              <Sidebar>
                <Component
                  {...props}
                  subRoutes={subRoutes}
                  nextPage={nextPage}
                  prevPage={prevPage}
                />
              </Sidebar>
            ) : (
              <Redirect to='/Login' />
            )}
          </>
        );
      }}
    />
  );
}

export default PrivateRoute;
