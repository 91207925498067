import {
  ON_SESSION_DETAILS_REQUEST,
  ON_SESSION_DETAILS_SUCCESS,
  ON_SESSION_DETAILS_FAILED,
  ON_SESSION_VISUAL_DETAILS_SUCCESS,
  ON_SESSION_VISUAL_DETAILS_REQUEST,
  ON_SESSION_VISUAL_DETAILS_FAILED,
  ON_SESSION_VISUAL_TIME_DETAILS_SUCCESS,
  ON_SESSION_VISUAL_TIME_DETAILS_REQUEST,
  ON_SESSION_VISUAL_TIME_DETAILS_FAILED,
  ON_SESSION_HEATMAP_SUCCESS,
  ON_SESSION_HEATMAP_REQUEST,
  ON_SESSION_HEATMAP_FAILED,
} from '../actionTypes';

export const sessionDetailsRequest = (data: any) => ({
  type: ON_SESSION_DETAILS_REQUEST,
  payload: data,
});

export const sessionDetailsSuccess = (data: any) => ({
  type: ON_SESSION_DETAILS_SUCCESS,
  payload: data,
});

export const sessionDetailsFailed = (data: any) => ({
  type: ON_SESSION_DETAILS_FAILED,
  payload: data,
});

export const sessionVisualDetailsRequest = (data: any) => ({
  type: ON_SESSION_VISUAL_DETAILS_REQUEST,
  payload: data,
});

export const sessionVisualDetailsSuccess = (data: any) => ({
  type: ON_SESSION_VISUAL_DETAILS_SUCCESS,
  payload: data,
});

export const sessionVisualDetailsFailed = (data: any) => ({
  type: ON_SESSION_VISUAL_DETAILS_FAILED,
  payload: data,
});

export const sessionVisualTimeDetailsRequest = (data: any) => ({
  type: ON_SESSION_VISUAL_TIME_DETAILS_REQUEST,
  payload: data,
});

export const sessionVisualTimeDetailsSuccess = (data: any) => ({
  type: ON_SESSION_VISUAL_TIME_DETAILS_SUCCESS,
  payload: data,
});

export const sessionVisualTimeDetailsFailed = (data: any) => ({
  type: ON_SESSION_VISUAL_TIME_DETAILS_FAILED,
  payload: data,
});

export const sessionHeatmapRequest = (data: any) => ({
  type: ON_SESSION_HEATMAP_REQUEST,
  payload: data,
});

export const sessionHeatmapSuccess = (data: any) => ({
  type: ON_SESSION_HEATMAP_SUCCESS,
  payload: data,
});

export const sessionHeatmapFailed = (data: any) => ({
  type: ON_SESSION_HEATMAP_FAILED,
  payload: data,
});
