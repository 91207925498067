import { Grid, styled, TextField, Typography } from '@material-ui/core';
import { get } from 'lodash';
import React, { useEffect } from 'react';
import { Controller } from 'react-hook-form';
import moment from 'moment';
import { connect, useDispatch } from 'react-redux';
import { patientFormErrors } from '../constants/errors';
import {
  getClinicainsRequest,
  getGenderRequest,
  getTreatmentStatusRequest,
  getDeviceRequest,
} from '../store/actions';
import Dropdown from './Dropdown';

const GridItem = styled(Grid)({
  padding: '10px 20px !important',
});

const Input = styled(TextField)({
  width: '100%',
  marginTop: 10,
});

const DatePicker = styled('input')({
  marginTop: 10,
  width: '100%',
  padding: '9px',
  border: '1px solid #c4c4c4',
  borderRadius: '3px',
  color: '#757575',
});

function ReportForm({
  control,
  errors,
  list,
  watch,
  clinicians,
  isAdmin,
  setValue,
  edit,
  patient,
  editData,
}: any) {
  const { genderData, treatmentStatus, loading } = list;
  const { loading: patientLoading } = patient;
  const dispatch = useDispatch();

  const patientData = get(patient, 'patientDetails.data.patient', []);
  useEffect(() => {
    if (edit) {
      setValue('emr', patientData?.emr);

      setValue(
        'gender',
        genderData.data.result.filter(
          (gender: any) => gender.label === patientData?.gender_text,
        )[0],
      );
      setValue(
        'treatmentStatus',
        treatmentStatus.data.result.filter(
          (item: any) => item.label === patientData?.patient_status_text,
        )[0],
      );
    }
  }, [editData]);

  const Asterisk = () => {
    return <span style={{ color: '#c62828' }}> *</span>;
  };
  return !loading && !patientLoading ? (
    <Grid container spacing={5}>
      <GridItem item xs={12} sm={6}>
        <Typography variant='subtitle2'>
          EMR
          <Asterisk />
        </Typography>
        <Controller
          as={<Input size='small' variant='outlined' />}
          name='emr'
          control={control}
          rules={{
            required: true,
          }}
        />
        {errors.emr && (
          <Typography variant='caption' color='error'>
            {get(patientFormErrors.emr, errors.emr.type, '')}
          </Typography>
        )}
      </GridItem>

      <GridItem item xs={12} sm={6}>
        <Typography variant='subtitle2'>
          Sex
          <Asterisk />
        </Typography>
        <Dropdown
          fieldRequired={true}
          data={genderData?.data?.result}
          control={control}
          error={errors}
          name='gender'
          label='gender'
        />
        {errors.gender && (
          <Typography variant='caption' color='error'>
            {get(patientFormErrors.gender, errors.gender.type, '')}
          </Typography>
        )}
      </GridItem>
      <GridItem item xs={12} sm={6}>
        <Typography variant='subtitle2'>
          User Treatment Status
          <Asterisk />
        </Typography>
        <Dropdown
          fieldRequired={true}
          data={treatmentStatus?.data?.result}
          control={control}
          error={errors}
          label='treatment status'
          name='treatmentStatus'
        />
        {errors.treatmentStatus && (
          <Typography variant='caption' color='error'>
            {get(patientFormErrors.treatmentStatus, errors.treatmentStatus.type, '')}
          </Typography>
        )}
      </GridItem>
      <GridItem item xs={12} sm={6}>
        <Typography variant='subtitle2'>
          Name
          <Asterisk />
        </Typography>
        <Controller
          as={<Input size='small' variant='outlined' />}
          name='name'
          control={control}
          rules={{
            required: true,
          }}
        />
        {errors.name && (
          <Typography variant='caption' color='error'>
            Required
          </Typography>
        )}
      </GridItem>
      <GridItem item xs={12} sm={6}>
        <Typography variant='subtitle2'>
          MRN
          <Asterisk />
        </Typography>
        <Controller
          as={<Input size='small' variant='outlined' />}
          name='mrn'
          control={control}
          rules={{
            required: true,
          }}
        />
        {errors.mrn && (
          <Typography variant='caption' color='error'>
            Required
          </Typography>
        )}
      </GridItem>
      <GridItem item xs={12} sm={6}>
        <Typography variant='subtitle2'>
          Address
          <Asterisk />
        </Typography>
        <Controller
          as={<Input size='small' variant='outlined' />}
          name='address'
          control={control}
          rules={{
            required: true,
          }}
        />
        {errors.address && (
          <Typography variant='caption' color='error'>
            Required
          </Typography>
        )}
      </GridItem>
      <GridItem item xs={12} sm={6}>
        <Typography variant='subtitle2'>
          Age
          <Asterisk />
        </Typography>
        <Controller
          as={<Input size='small' variant='outlined' />}
          name='age'
          control={control}
          rules={{
            required: true,
          }}
        />
        {errors.age && (
          <Typography variant='caption' color='error'>
            Required
          </Typography>
        )}
      </GridItem>
    </Grid>
  ) : (
    <></>
  );
}

const mapStateToProps = (state: any) => ({
  patient: state.patient,
});

export default connect(mapStateToProps)(ReportForm);
