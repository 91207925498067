import {
  ON_SESSION_VISUAL_DETAILS_REQUEST,
  ON_SESSION_VISUAL_TIME_DETAILS_REQUEST,
  ON_SESSION_HEATMAP_REQUEST,
} from './../actionTypes/sessionDetails.actionTypes';
import {
  sessionVisualDetailsSuccess,
  sessionVisualDetailsFailed,
  sessionVisualTimeDetailsSuccess,
  sessionVisualTimeDetailsFailed,
  sessionHeatmapSuccess,
  sessionHeatmapFailed,
} from './../actions/sessionDetails.action';
import { call, takeLatest, put } from 'redux-saga/effects';

import { ON_SESSION_DETAILS_REQUEST } from '../actionTypes';
import { sessionDetailsSuccess, sessionDetailsFailed } from '../actions';
import {
  getSessionVisualDetails,
  getSessionDetails,
  getSessionVisualTimeDetails,
  getSessionHeatmap,
} from '../../utils/services/sessionDetails';

function* getSessionsDetailsSaga(action: any) {
  try {
    const result = yield call(getSessionDetails, {
      patientId: action.payload.id,
      sessionId: action.payload.sessionId,
    });

    if (!result.success) throw result.data;
    yield put(sessionDetailsSuccess(result));
  } catch (error) {
    yield put(sessionDetailsFailed(error));
  }
}

function* getSessionsVisualDetailsSaga(action: any) {
  try {
    const result = yield call(getSessionVisualDetails, {
      patientId: action.payload.id,
      sessionId: action.payload.sessionId,
    });
    if (!result.success) throw result.data;
    yield put(sessionVisualDetailsSuccess(result));
  } catch (error) {
    yield put(sessionVisualDetailsFailed(error));
  }
}

function* getSessionsVisualTimeDetailsSaga(action: any) {
  try {
    const result = yield call(getSessionVisualTimeDetails, {
      patientId: action.payload.id,
      sessionId: action.payload.sessionId,
    });
    if (!result.success) throw result.data;
    yield put(sessionVisualTimeDetailsSuccess(result));
  } catch (error) {
    yield put(sessionVisualTimeDetailsFailed(error));
  }
}

function* getSessionsHeatmapSaga(action: any) {
  try {
    const result = yield call(getSessionHeatmap, {
      patientId: action.payload.id,
      sessionId: action.payload.sessionId,
      metric1: action.payload.metric1,
      metric2: action.payload.metric2,
    });
    if (!result.success) throw result.data;
    yield put(sessionHeatmapSuccess(result));
  } catch (error) {
    yield put(sessionHeatmapFailed(error));
  }
}

const sessionSaga = [
  takeLatest(ON_SESSION_DETAILS_REQUEST, getSessionsDetailsSaga),
  takeLatest(ON_SESSION_VISUAL_DETAILS_REQUEST, getSessionsVisualDetailsSaga),
  takeLatest(ON_SESSION_VISUAL_TIME_DETAILS_REQUEST, getSessionsVisualTimeDetailsSaga),
  takeLatest(ON_SESSION_HEATMAP_REQUEST, getSessionsHeatmapSaga),
];

export default sessionSaga;
