import { get, auth } from '../index';
const ENDPOINT = '/patient';

export const getSessionDetails = (data: any) => {
  const url = `${ENDPOINT}/${data.patientId}/session/${data.sessionId}/timeseries-data`;

  return get(url, auth());
};

export const getSessionVisualDetails = (data: any) => {
  const url = `${ENDPOINT}/${data.patientId}/session/${data.sessionId}/processed-data-views`;

  return get(url, auth());
};

export const getSessionVisualTimeDetails = (data: any) => {
  const url = `${ENDPOINT}/${data.patientId}/session/${data.sessionId}/timeseries-data`;

  return get(url, auth());
};

export const getSessionHeatmap = (data: any) => {
  const url = `${ENDPOINT}/${data.patientId}/session/${data.sessionId}/${data.metric1}/${data.metric2}/heatmap`;

  return get(url, auth());
};
