import { deleteRow, get, post, auth, put } from '../index';
const ENDPOINT = '/clinician';

export const getClinicians = () => {
  const url = `${ENDPOINT}/all`;

  return get(url, auth());
};

export const setClinician = (payload: object) => {
  const url = `${ENDPOINT}`;

  return post(url, payload, auth());
};

export const deleteClinician = (payload: object) => {
  const url = `${ENDPOINT}/${payload}`;

  return deleteRow(url, auth());
};
export const updateClinician = (payload: any, clinicianID: number) => {
  const { clinician } = payload;
  const url = `${ENDPOINT}/${clinicianID}`;

  return put(url, payload, auth());
};
export const getClinicList = () => {
  const url = `organization/all`;

  return get(url, auth());
};
