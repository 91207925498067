import { getChatsForPatient, postChatForPatient } from '../../utils/services/chat';
import { setChatRequest, setChatSuccess, setChatFailed } from '../actions/chat.action';
import { ON_SET_CHAT_REQUEST, ON_POST_CHAT_REQUEST } from '../actionTypes/chat.actionTypes';
import { takeLatest, put } from 'redux-saga/effects';

function* getChats(data: any) {
  try {
    const result = yield getChatsForPatient(data.data.patientId);
    if (result.success) {
      yield put(setChatSuccess(result.data.result));
    } else {
      throw result;
    }
  } catch (e) {
    yield put(setChatFailed(e));
  }
}

function* postChat(data: any) {
  try {
    const result = yield postChatForPatient(data.data.patientId, data.data.payload);
    if (result.success) {
      const chats = yield getChatsForPatient(data.data.patientId);
      if (chats.success) {
        yield put(setChatSuccess(chats.data.result));
      } else {
        throw chats;
      }
    }
  } catch (e) {
    yield put(setChatFailed(e));
  }
}

const chatSaga = [
  takeLatest(ON_SET_CHAT_REQUEST, getChats),
  takeLatest(ON_POST_CHAT_REQUEST, postChat),
];

export default chatSaga;
