import React, { useEffect } from 'react';
// import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Redirect, Switch } from 'react-router-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import ROUTES from '../utils/routes';
import PublicRoute from '../components/PublicRoute';
import PrivateRoute from '../components/PrivateRoute';
import { CircularProgress } from '@material-ui/core';
import { isLogin } from '../utils/middleware/auth';

function AuthCheck(props: any) {
  useEffect(() => {
    getLoginStatus();
  }, []);

  const getLoginStatus = async () => {
    const isLoggedInResp = await isLogin();
    if (isLoggedInResp) props.history.push('/dashboard');
    else props.history.push('/login');
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
          width: '100%',
        }}
      >
        <CircularProgress variant='indeterminate' size={80} />
      </div>
      :
    </>
  );
}
export default AuthCheck;
