import React from 'react';
import _ from 'lodash/fp';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Controller, Control } from 'react-hook-form';
import { Typography } from '@material-ui/core';
import { get } from 'lodash';

export default function Dropdown({
  control,
  data,
  name,
  error,
  label,
  isDevice,
  disabled,
  fieldRequired,
}: {
  control: Control;
  data: Array<any>;
  name: string;
  error: any;
  label: string;
  isDevice?: boolean;
  disabled?: boolean;
  fieldRequired: boolean;
}) {
  return (
    <Controller
      render={(props) => (
        <Autocomplete
          {...props}
          style={{ width: '100%' }}
          options={data}
          disabled={disabled}
          getOptionLabel={(option) => (isDevice ? option.serial_number : option.label)}
          renderOption={(option) => <span>{isDevice ? option.serial_number : option.label}</span>}
          getOptionSelected={(option, value) => _.isEqual(option, value)}
          closeIcon={false}
          renderInput={(params) => (
            <TextField {...params} margin='dense' size='small' variant='outlined' />
          )}
          onChange={(_, data) => props.onChange(data)}
        />
      )}
      name={name}
      control={control}
      rules={{
        required: fieldRequired,
      }}
    />
  );
}
