import { get, post, auth } from '../index';
const ENDPOINT = '/patient';

export const getChatsForPatient = (id: any) => {
  return get(`${ENDPOINT}/${id}/chats`, auth());
};

export const postChatForPatient = (id: any, data: any) => {
  return post(`${ENDPOINT}/${id}/chat-by-clinician`, data, auth());
};
