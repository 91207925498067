import {
  ON_SET_ADVANCED_PARAMETER_REQUEST,
  ON_SET_ADVANCED_PARAMETER_SUCCESS,
  ON_SET_ADVANCED_PARAMETER_FAILED,
} from '../actionTypes/advancedParameter.actionTypes';

export const setAdvancedParameterRequest = (data: any, toast?: any) => ({
  type: ON_SET_ADVANCED_PARAMETER_REQUEST,
  data,
  toast,
});

export const setAdvancedParameterSuccess = (data: any) => ({
  type: ON_SET_ADVANCED_PARAMETER_SUCCESS,
  payload: data,
});

export const setAdvancedParameterFailed = (data: any) => ({
  type: ON_SET_ADVANCED_PARAMETER_FAILED,
  payload: data,
});
