import { createMuiTheme } from '@material-ui/core/styles';
const defaultTheme = createMuiTheme();

export default createMuiTheme({
  palette: {
    primary: {
      main: '#007CBA',
    },
    secondary: {
      main: '#ffffff',
    },
    text: {
      primary: '#333333',
    },
  },
  typography: {
    h6: {
      fontWeight: 700,
      color: '#333333',
    },
    subtitle2: {
      fontSize: 16,
      color: '#495057',
      fontWeight: 700,
    },
    button: {
      lineHeight: 0,
      fontWeight: 700,
      fontSize: 16,
    },
    fontSize: window.innerWidth > defaultTheme.breakpoints.width('xl') ? 23 : 18,
  },
});
