import { CircularProgress, Grid, styled, TextField, Typography } from '@material-ui/core';
import { get } from 'lodash';
import React, { useEffect } from 'react';
import { Controller } from 'react-hook-form';
import moment from 'moment';
import { connect, useDispatch } from 'react-redux';
import { patientFormErrors } from '../constants/errors';
import {
  getClinicainsRequest,
  getGenderRequest,
  getTreatmentStatusRequest,
  getDeviceRequest,
  getDiagnosisRequest,
} from '../store/actions';
import Dropdown from './Dropdown';

const GridItem = styled(Grid)({
  padding: '10px 20px !important',
});

const Input = styled(TextField)({
  width: '100%',
  marginTop: 10,
});

const Asterisk = () => {
  return <span style={{ color: '#c62828' }}> *</span>;
};

const DatePicker = styled('input')({
  marginTop: 10,
  width: '100%',
  padding: '9px',
  border: '1px solid #c4c4c4',
  borderRadius: '3px',
  fontSize: 'medium',
  color: 'black',
  fontFamily: 'unset',
});

function PatientForm({
  control,
  errors,
  list,
  watch,
  clinicians,
  isAdmin,
  setValue,
  edit,
  patient,
  editData,
  patientID,
  currentUser,
}: any) {
  const { genderData, treatmentStatus, loading } = list;
  const { loading: patientLoading } = patient;
  const dispatch = useDispatch();
  let clinicianID = watch('clinician').id;
  if (currentUser?.user?.is_clinician) {
    clinicianID = currentUser?.user?.id_person;
  }
  useEffect(() => {
    if (clinicianID)
      dispatch(
        getDeviceRequest({
          personID: patientID,
          isClinician: false,
          clinicianID: clinicianID,
        }),
      );
  }, [clinicianID]);
  const deviceList = get(patient, 'deviceData.data.result', []);
  const deviceListLoading = get(patient, 'deviceData.loading', []);
  useEffect(() => {
    if (edit) {
      const patient = editData?.data?.patient;
      const clinician = editData?.data?.clinicians?.filter(
        (item: any) => item.is_primary_clinician,
      )[0];
      const device_current = editData?.data?.devices?.filter((item: any) => item.is_current)[0];
      setValue('emr', patient?.emr);
      setValue(
        'gender',
        genderData.data.result.filter((gender: any) => gender.label === patient?.gender_text)[0],
      );
      setValue(
        'treatmentStatus',
        treatmentStatus.data.result.filter(
          (item: any) => item.label === patient?.patient_status_text,
        )[0],
      );
      setValue(
        'clinician',
        clinician && clinicians.filter((item: any) => item.id === clinician.id_person_clinician)[0],
      );
      setValue('device', device_current);
    }
  }, [editData]);

  // console.log('dropdown options', treatmentStatus.data.result)

  return !loading && !patientLoading ? (
    <Grid container spacing={5}>
      <GridItem item xs={12} sm={6}>
        <Typography variant='subtitle2'>
          Custom ID
          <Asterisk />
        </Typography>
        <Controller
          as={<Input size='small' variant='outlined' />}
          name='emr'
          control={control}
          rules={{
            required: true,
          }}
        />
        {errors.emr && (
          <Typography variant='caption' color='error'>
            {get(patientFormErrors.emr, errors.emr.type, '')}
          </Typography>
        )}
      </GridItem>
      <GridItem item xs={12} sm={6}>
        <Typography variant='subtitle2'>
          Sex
          <Asterisk />
        </Typography>
        <Dropdown
          fieldRequired={true}
          data={genderData?.data?.result}
          control={control}
          error={errors}
          name='gender'
          label='gender'
        />
        {errors.gender && (
          <Typography variant='caption' color='error'>
            {get(patientFormErrors.gender, errors.gender.type, '')}
          </Typography>
        )}
      </GridItem>
      <GridItem item xs={12} sm={6}>
        <Typography variant='subtitle2'>
          User Treatment Status
          <Asterisk />
        </Typography>
        <Dropdown
          fieldRequired={true}
          data={treatmentStatus?.data?.result}
          control={control}
          error={errors}
          label='treatment status'
          name='treatmentStatus'
        />
        {errors.treatmentStatus && (
          <Typography variant='caption' color='error'>
            {get(patientFormErrors.treatmentStatus, errors.treatmentStatus.type, '')}
          </Typography>
        )}
      </GridItem>
      <GridItem item xs={12} sm={6}>
        <Typography variant='subtitle2'>Device</Typography>
        <Dropdown
          fieldRequired={false}
          data={deviceList}
          control={control}
          error={errors}
          label='Device'
          name='device'
          isDevice
          disabled={deviceListLoading || !clinicianID}
        />
      </GridItem>
      {isAdmin ? (
        <GridItem item xs={12} sm={6}>
          <Typography variant='subtitle2'>
            Primary Clinician
            <Asterisk />
          </Typography>
          <Dropdown
            fieldRequired={true}
            data={clinicians}
            control={control}
            error={errors}
            label='clinician'
            name='clinician'
          />
          {errors.clinician && (
            <Typography variant='caption' color='error'>
              {get(patientFormErrors.clinician, errors.clinician.type, '')}
            </Typography>
          )}
        </GridItem>
      ) : (
        ''
      )}
    </Grid>
  ) : (
    <></>
  );
}

const mapStateToProps = (state: any) => ({
  patient: state.patient,
});

export default connect(mapStateToProps)(PatientForm);
