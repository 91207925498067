import {
  ON_GET_PATIENTS_DATA_REQUEST,
  ON_GET_PATIENTS_DATA_SUCCESS,
  ON_GET_PATIENTS_DATA_FAILED,
  ON_DELETE_PATIENT_REQUEST,
  ON_DELETE_PATIENT_FAILED,
  ON_DELETE_PATIENT_SUCCESS,
  ON_SET_PATIENT_FAILED,
  ON_SET_PATIENT_REQUEST,
  ON_SET_PATIENT_SUCCESS,
  ON_SET_PATIENT_DIAGNOSIS_REQUEST,
  ON_SET_PATIENT_DIAGNOSIS_SUCCESS,
  ON_SET_PATIENT_DIAGNOSIS_FAILED,
  ON_UPDATE_PATIENT_REQUEST,
  ON_UPDATE_PATIENT_SUCCESS,
  ON_UPDATE_PATIENT_FAILED,
  ON_GET_PATIENT_DETAILED_DATA_REQUEST,
  ON_GET_PATIENT_DETAILED_DATA_SUCCESS,
  ON_GET_PATIENT_DETAILED_DATA_FAILED,
  ON_SET_PATIENT_DELEGATE_REQUEST,
  ON_UNSET_PATIENT_DELEGATE_REQUEST,
  ON_UNSET_PATIENT_DELEGATE_SUCCESS,
  ON_UNSET_PATIENT_DELEGATE_FAILED,
  ON_SET_PATIENT_DELEGATE_SUCCESS,
  ON_SET_PATIENT_DELEGATE_FAILED,
  ON_DEVICE_LIST_REQUEST,
  ON_DEVICE_LIST_SUCCESS,
  ON_DEVICE_LIST_FAILED,
  ON_GET_CLINICIANS_FOR_DELEGATE_SUCCESS,
  ON_GET_CLINICIANS_FOR_DELEGATE_REQUEST,
  ON_GET_CLINICIANS_FOR_DELEGATE_FAILED,
} from '../actionTypes';

const sessionInitialState = {
  loading: true,
  patientData: {},
  error: {},
  patientDetails: { data: null as any, success: false },
  deviceData: { data: null as any, loading: false },
  cliniciansForDelegate: { data: null as any, success: false },
};

const patientReducer = (state = sessionInitialState, action: any) => {
  switch (action.type) {
    case ON_GET_PATIENTS_DATA_REQUEST:
      return { ...state, loading: true };
    case ON_GET_PATIENTS_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        patientData: action.payload,
        error: {},
      };
    case ON_GET_PATIENTS_DATA_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
        patientData: {},
      };
    case ON_SET_PATIENT_REQUEST:
      return { ...state, loading: true };
    case ON_SET_PATIENT_SUCCESS:
      return {
        ...state,
        loading: false,
        createdPatient: action.payload,
        createError: {},
      };
    case ON_SET_PATIENT_FAILED:
      return {
        ...state,
        loading: false,
        createdPatient: action.payload,
      };
    case ON_DELETE_PATIENT_REQUEST:
      return { ...state, loading: true };
    case ON_DELETE_PATIENT_SUCCESS:
      return {
        ...state,
        loading: false,
        deletePatient: action.payload,
        error: {},
      };
    case ON_DELETE_PATIENT_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
        deletePatient: {},
      };
    case ON_SET_PATIENT_DIAGNOSIS_REQUEST:
      return { ...state, loading: true };
    case ON_SET_PATIENT_DIAGNOSIS_SUCCESS:
      return {
        ...state,
        loading: false,
        createdPatientDiagnosis: action.payload,
        error: {},
      };
    case ON_SET_PATIENT_DIAGNOSIS_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
        createdPatientDiagnosis: {},
      };
    case ON_UPDATE_PATIENT_REQUEST:
      return { ...state, loading: true };
    case ON_UPDATE_PATIENT_SUCCESS:
      return {
        ...state,
        loading: false,
        updatePatient: action.payload,
        error: {},
      };
    case ON_UPDATE_PATIENT_FAILED:
      return {
        ...state,
        loading: false,
        updatePatient: action.payload,
      };
    case ON_GET_PATIENT_DETAILED_DATA_REQUEST:
      return { ...state, loading: true, patientDetails: {} };
    case ON_GET_PATIENT_DETAILED_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        patientDetails: action.payload,
        error: {},
      };
    case ON_GET_PATIENT_DETAILED_DATA_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
        patientDetails: {},
      };
    case ON_SET_PATIENT_DELEGATE_REQUEST:
      return { ...state, loading: true };
    case ON_SET_PATIENT_DELEGATE_SUCCESS:
      return {
        ...state,
        loading: false,
        patientDelegate: action.payload,
        error: {},
      };
    case ON_SET_PATIENT_DELEGATE_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
        patientDelegate: {},
      };
    case ON_UNSET_PATIENT_DELEGATE_REQUEST:
      return { ...state, loading: true };
    case ON_UNSET_PATIENT_DELEGATE_SUCCESS:
      return {
        ...state,
        loading: false,
        patientUndoDelegate: action.payload,
        error: {},
      };
    case ON_UNSET_PATIENT_DELEGATE_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
        patientUndoDelegate: {},
      };
    case ON_DEVICE_LIST_REQUEST: {
      return {
        ...state,
        deviceData: { ...state.deviceData, loading: true },
      };
    }
    case ON_DEVICE_LIST_SUCCESS: {
      return {
        ...state,
        deviceData: {
          ...state.deviceData,
          loading: false,
          data: action.payload,
        },
      };
    }
    case ON_DEVICE_LIST_FAILED: {
      return {
        ...state,
        deviceData: { ...state.deviceData, loading: false, error: true, data: { result: [] } },
      };
    }
    case ON_GET_CLINICIANS_FOR_DELEGATE_REQUEST: {
      return {
        ...state,
        cliniciansForDelegate: { loading: false, error: true },
      };
    }
    case ON_GET_CLINICIANS_FOR_DELEGATE_SUCCESS: {
      return {
        ...state,
        cliniciansForDelegate: {
          ...state.cliniciansForDelegate,
          success: true,
          loading: false,
          data: action.payload,
        },
      };
    }
    case ON_GET_CLINICIANS_FOR_DELEGATE_FAILED: {
      return {
        ...state,
        cliniciansForDelegate: { ...state.cliniciansForDelegate, loading: false, error: true },
      };
    }

    default:
      return state;
  }
};

export default patientReducer;
