import { JSONSchema7 } from 'json-schema';

export const formFieldsProp: { [key: string]: boolean } = {
  include_biofeedback: false,
  require_all_features_for_biofeedback: false,
  biofeedback_micspl_include: false,
  biofeedback_micspl_lower: true,
  biofeedback_micspl_upper: true,
  biofeedback_acclevel_include: false,
  biofeedback_acclevel_lower: true,
  biofeedback_acclevel_upper: true,
  biofeedback_fo_include: false,
  biofeedback_fo_lower: true,
  biofeedback_fo_upper: true,
  biofeedback_cpp_include: false,
  biofeedback_cpp_lower: true,
  biofeedback_cpp_upper: true,
  biofeedback_h1h2_include: false,
  biofeedback_h1h2_lower: true,
  biofeedback_h1h2_upper: true,
  biofeedback_duration_threshold: true,
  biofeedback_duration_hold: true,
  biofeedback_relative_freq: true,
  biofeedback_cue_duration_ms: true,
  biofeedback_stats_include: false,
  biofeedback_stats_timeframe: true,
  recording_limits_include: false,
  recording_time_limit: true,
  recording_limit_voiced_only_include: false,
  feedback_vibration_time: true,
  data_sample_rate: true,
  offset_value: true,
  scale_value: true,
  biofeedback_threshold: true,
  enable_voiced_duration: false,
};

export const advParamFormFieldList = [
  'cpp_fft_bins',
  'cpp_lifter',
  'cpp_max',
  'cpp_min',
  'fo_limit_lower',
  'fo_limit_upper',
  'frame_interval',
  'frame_length',
  'h1h2_fft_bins',
  'lh_ratio_cutoff',
  'lh_ratio_cutoff_high',
  'lh_ratio_freq_min',
  'lh_ratio_lower',
  'norm_autocorr_peak_limit',
  'subharmonic_peak_limit',
];

export const paramFormFieldList = [
  'include_biofeedback',
  'require_all_features_for_biofeedback',
  'biofeedback_micspl_include',
  'biofeedback_micspl_lower',
  'biofeedback_micspl_upper',
  'biofeedback_acclevel_include',
  'biofeedback_acclevel_lower',
  'biofeedback_acclevel_upper',
  'biofeedback_fo_include',
  'biofeedback_fo_lower',
  'biofeedback_fo_upper',
  'biofeedback_cpp_include',
  'biofeedback_cpp_lower',
  'biofeedback_cpp_upper',
  'biofeedback_h1h2_include',
  'biofeedback_h1h2_lower',
  'biofeedback_h1h2_upper',
  'biofeedback_duration_threshold',
  'biofeedback_duration_hold',
  'biofeedback_relative_freq',
  'biofeedback_cue_duration_ms',
  'biofeedback_stats_include',
  'biofeedback_stats_timeframe',
  'recording_limits_include',
  'recording_time_limit',
  'recording_limit_voiced_only_include',
  'feedback_vibration_time',
  'data_sample_rate',
  'offset_value',
  'scale_value',
  'biofeedback_threshold',
  'enable_voiced_duration',
];

export const paramFormSchema: JSONSchema7 = {
  title: 'Add Parameter',
  type: 'object',
  properties: {
    biofeedback_micspl_lower: {
      type: 'number',
      default: '0',
      title: 'Biofeedback micspl lower',
    },
    biofeedback_micspl_upper: {
      type: 'number',
      default: 0,
      title: 'Biofeedback micspl upper',
    },
    feedback_vibration_time: {
      type: 'number',
      default: 0,
      title: 'Feedback vibration time',
    },
    data_sample_rate: {
      type: 'number',
      default: '0',
      title: 'Data sample rate',
    },
    offset_value: {
      type: 'number',
      title: 'Offset value',
      default: '0',
    },
    scale_value: {
      type: 'number',
      title: 'Scale value',
      default: '0',
    },
    biofeedback_threshold: {
      type: 'number',
      default: '0',
      title: 'Biofeedback threshold',
    },
    biofeedback_acclevel_lower: {
      type: 'number',
      default: '0',
      title: 'Biofeedback acclevel lower',
    },
    biofeedback_acclevel_upper: {
      type: 'number',
      default: '0',
      title: 'Biofeedback acclevel upper',
    },
    biofeedback_fo_lower: {
      type: 'number',
      default: '0',
      title: 'Biofeedback fo lower',
    },
    biofeedback_fo_upper: {
      type: 'number',
      default: '0',
      title: 'Biofeedback fo upper',
    },
    biofeedback_cpp_lower: {
      type: 'number',
      default: '0',
      title: 'Biofeedback cpp lower',
    },
    biofeedback_cpp_upper: {
      type: 'number',
      default: '0',
      title: 'Biofeedback cpp upper',
    },
    biofeedback_h1h2_lower: {
      type: 'number',
      default: '0',
      title: 'Biofeedback h1h2 lower',
    },
    biofeedback_h1h2_upper: {
      type: 'number',
      default: '0',
      title: 'Biofeedback h1h2 upper',
    },
    biofeedback_duration_threshold: {
      type: 'number',
      default: '0',
      title: 'biofeedback duration threshold',
    },
    biofeedback_duration_hold: {
      type: 'number',
      default: '0',
      title: 'Biofeedback duration hold',
    },
    biofeedback_relative_freq: {
      type: 'number',
      default: '0',
      title: 'Biofeedback relative freq',
    },
    biofeedback_cue_duration_ms: {
      type: 'number',
      default: '0',
      title: 'Biofeedback cue duration in ms',
    },
    biofeedback_stats_timeframe: {
      type: 'number',
      default: '0',
      title: 'Biofeedback stats timeframe',
    },
    recording_time_limit: {
      type: 'number',
      default: '0',
      title: 'Recording time Limit',
    },
    include_biofeedback: {
      type: 'boolean',
      default: 0,
      title: 'Include biofeedback',
    },
    require_all_features_for_biofeedback: {
      type: 'boolean',
      default: 0,
      title: 'Require all features for biofeedback',
    },
    biofeedback_micspl_include: {
      type: 'boolean',
      default: 0,
      title: 'Biofeedback Micspl include',
    },
    biofeedback_acclevel_include: {
      type: 'boolean',
      default: 0,
      title: 'Biofeedback acclevel include',
    },

    biofeedback_fo_include: {
      type: 'boolean',
      default: 0,
      title: 'Biofeedback fo include',
    },

    biofeedback_cpp_include: {
      type: 'boolean',
      default: 0,
      title: 'Biofeedback cpp include',
    },

    biofeedback_h1h2_include: {
      type: 'boolean',
      default: 0,
      title: 'Biofeedback h1h2 include',
    },

    biofeedback_stats_include: {
      type: 'boolean',
      default: 0,
      title: 'Biofeedback stats include',
    },

    recording_limits_include: {
      type: 'boolean',
      default: 0,
      title: 'Recording limits include',
    },

    recording_limit_voiced_only_include: {
      type: 'boolean',
      default: 0,
      title: 'Recording limit voiced only include',
    },

    enable_voiced_duration: {
      type: 'boolean',
      default: 0,
      title: 'Enable voiced duration',
    },
  },
};

export const advancedParamFormSchema: JSONSchema7 = {
  title: 'Add Parameter',
  type: 'object',
  properties: {
    cpp_fft_bins: {
      type: 'number',
      default: '0',
      title: 'Cpp Fft Bins',
    },
    cpp_lifter: {
      type: 'number',
      default: '0',
      title: 'Cpp Lifter',
    },
    cpp_max: {
      type: 'number',
      default: '0',
      title: 'Cpp Max',
    },
    cpp_min: {
      type: 'number',
      default: '0',
      title: 'Cpp Min',
    },
    fo_limit_lower: {
      type: 'number',
      default: '0',
      title: 'Fo Limit Lower',
    },
    fo_limit_upper: {
      type: 'number',
      default: '0',
      title: 'Fo Limit Upper',
    },
    frame_interval: {
      type: 'number',
      default: '0',
      title: 'Frame Interval',
    },
    frame_length: {
      type: 'number',
      default: '0',
      title: 'Frame Length',
    },
    h1h2_fft_bins: {
      type: 'number',
      default: '0',
      title: 'h1h2 fft bins',
    },
    lh_ratio_cutoff: {
      type: 'number',
      default: '0',
      title: 'Lh Ratio Cutoff',
    },
    lh_ratio_cutoff_high: {
      type: 'number',
      default: '0',
      title: 'Lh Ratio Cutoff High',
    },
    lh_ratio_freq_min: {
      type: 'number',
      default: '0',
      title: 'Lh Ratio Freq Min',
    },
    lh_ratio_lower: {
      type: 'number',
      default: '0',
      title: 'Lh Ratio Lower',
    },
    norm_autocorr_peak_limit: {
      type: 'number',
      default: '0',
      title: 'Norm Autocorr Peak Limit',
    },
    subharmonic_peak_limit: {
      type: 'number',
      default: '0',
      title: 'Subharmonic Peak Limit',
    },
  },
};

export const paramFormUiSchema = {
  classNames: 'form-container',
  include_biofeedback: {
    classNames: 'contentInput',
  },
  require_all_features_for_biofeedback: {
    classNames: 'contentInput',
  },
  biofeedback_micspl_include: {
    classNames: 'contentInput',
  },
  biofeedback_micspl_lower: {
    classNames: 'contentInput',
  },
  biofeedback_micspl_upper: {
    classNames: 'contentInput',
  },
  biofeedback_acclevel_include: {
    classNames: 'contentInput',
  },
  biofeedback_acclevel_lower: {
    classNames: 'contentInput',
  },
  biofeedback_acclevel_upper: {
    classNames: 'contentInput',
  },
  biofeedback_fo_include: {
    classNames: 'contentInput',
  },
  biofeedback_fo_lower: {
    classNames: 'contentInput',
  },
  biofeedback_fo_upper: {
    classNames: 'contentInput',
  },
  biofeedback_cpp_include: {
    classNames: 'contentInput',
  },
  biofeedback_cpp_lower: {
    classNames: 'contentInput',
  },
  biofeedback_cpp_upper: {
    classNames: 'contentInput',
  },
  biofeedback_h1h2_include: {
    classNames: 'contentInput',
  },
  biofeedback_h1h2_lower: {
    classNames: 'contentInput',
  },
  biofeedback_h1h2_upper: {
    classNames: 'contentInput',
  },
  biofeedback_duration_threshold: {
    classNames: 'contentInput',
  },
  biofeedback_duration_hold: {
    classNames: 'contentInput',
  },
  biofeedback_relative_freq: {
    classNames: 'contentInput',
  },
  biofeedback_cue_duration_ms: {
    classNames: 'contentInput',
  },
  biofeedback_stats_include: {
    classNames: 'contentInput',
  },
  biofeedback_stats_timeframe: {
    classNames: 'contentInput',
  },
  recording_limits_include: {
    classNames: 'contentInput',
  },
  recording_time_limit: {
    classNames: 'contentInput',
  },
  recording_limit_voiced_only_include: {
    classNames: 'contentInput',
  },
  feedback_vibration_time: {
    classNames: 'contentInput',
  },
  data_sample_rate: {
    classNames: 'contentInput',
  },
  offset_value: {
    classNames: 'contentInput',
  },
  scale_value: {
    classNames: 'contentInput',
  },
  biofeedback_threshold: {
    classNames: 'contentInput',
  },
  enable_voiced_duration: {
    classNames: 'contentInput1',
  },
};
