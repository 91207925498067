import React from 'react';
// import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { createBrowserHistory } from 'history';

export const history = createBrowserHistory();

import { Redirect, Route, Switch } from 'react-router-dom';
import { Router } from 'react-router-dom';
import ROUTES from '../utils/routes';
import PublicRoute from '../components/PublicRoute';
import PrivateRoute from '../components/PrivateRoute';
import { Typography } from '@material-ui/core';
import Footer from '../components/Footer';

function App() {
  return (
    <>
      <Router history={history}>
        <Switch>
          <Redirect exact from='/' to='/auth-check' />
          {ROUTES.map((route) =>
            route.isPrivate ? <PrivateRoute {...route} /> : <PublicRoute {...route} />,
          )}
        </Switch>
      </Router>
      <Footer />
    </>
  );
}
export default App;
