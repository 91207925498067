import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import { logout } from '../utils/middleware/auth';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      button: { padding: 0 },
      // flexGrow: 1,
    },
    menuButton: {
      // marginRight: theme.spacing(2),
    },
    title: {
      // flexGrow: 1,
    },
  }),
);
interface MenuDropdownProps {
  items: Array<object>;
  children: any;
  data?: any;
  confirmDelete?: any;
}
export const handleClick = (data: any, history: any) => {
  logout();
  history.push('/login');
};
export default function MenuDropdown(props: MenuDropdownProps) {
  const classes = useStyles();
  const history = useHistory();
  const { items, data, confirmDelete } = props;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={classes.root}>
      <IconButton
        aria-label='account of current user'
        aria-controls='menu-appbar'
        aria-haspopup='true'
        style={{ padding: 0 }}
        onClick={handleMenu}
        color='inherit'
      >
        {props.children}
      </IconButton>
      <Menu
        id='menu-appbar'
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        onClose={handleClose}
      >
        {items.map((item: any) => (
          <MenuItem
            key={item.key}
            onClick={() => {
              item.handleClick(data, history);
              handleClose();
            }}
          >
            {item.label}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
