import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { IconButton, styled, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import CustomButton from './CustomButton';

const DialogActionsWrapper = styled(DialogActions)({
  padding: '20px',
});
const CancelButton = styled(Button)({
  textTransform: 'none',
  marginRight: '1em',
});

const CloseButton = styled(IconButton)({
  position: 'absolute',
  right: 8,
  top: 8,
});

interface OwnProps {
  title: string;
  children: string;
  modalOpen: boolean;
  modalClose: () => void;
  onClick: () => void;
}
export default function ConfirmationModal(props: OwnProps) {
  const [open, setOpen] = React.useState(false);
  const { title, children, modalOpen, modalClose, onClick } = props;
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div>
      <Dialog
        open={modalOpen}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>
          {title}
          <CloseButton aria-label='close' onClick={modalClose}>
            <CloseIcon />
          </CloseButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>{children}</DialogContentText>
        </DialogContent>
        <DialogActionsWrapper>
          <CancelButton autoFocus variant='contained' onClick={modalClose} color='default'>
            <Typography variant='body1'>Cancel</Typography>
          </CancelButton>
          <CustomButton onClick={onClick} width='min-content'>
            <Typography variant='body1'>Delete</Typography>
          </CustomButton>
        </DialogActionsWrapper>
      </Dialog>
    </div>
  );
}
