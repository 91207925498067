import React, { useEffect, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import CustomTable from '../components/CustomTable';
import { Input, Snackbar, Typography } from '@material-ui/core';
import CustomButton from '../components/CustomButton';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import { connect, useDispatch } from 'react-redux';
import SearchIcon from '@material-ui/icons/Search';

import { headCells, Clinician, Data } from '../constants/clinician';
import {
  assignDeviceRequest,
  deleteClinicainRequest,
  getAssignableDevicesRequest,
  getClinicainsRequest,
  getCurrentDeviceRequest,
  setClinicainRequest,
  updateClinicianRequest,
} from '../store/actions';
import UserForm from '../components/UserForm';
import ConfirmationModal from '../components/ConfirmationModal';
import Alert from '@material-ui/lab/Alert';
import moment from 'moment';
import { auth, post } from '../utils/services';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    body: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: '2em',
    },
  }),
);

interface ClinicianProps {
  userData: object;

  userAuth: {
    userData: object;
  };
  clinician: {
    clinicianData: {
      data: {
        result: Array<any>;
      };
    };
    deleteClinician: any;
    createClinician: any;
    updateClinician: any;
    loading: boolean;
    error: {
      message?: string;
    };
    clinicList: any;
  };
}

function Clinicians({ clinician }: ClinicianProps) {
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [view, setView] = useState(false);
  const [editData, setEditData] = useState({});
  const [devicesOpen, setDevicesOpen] = useState(false);
  const [clinicianID, setClinicianID] = useState(0);
  const [patientID, setPatientID] = useState(0);
  const [searchValue, setSearchValue] = useState('');
  const [searchList, setSearchList] = useState<any>();
  const [clinicianToast, setClinicianToast] = useState<any>('');
  const [alert, setAlert] = React.useState(false);
  const [clinicianOperationSuccess, setClinicianOperationSuccess] = React.useState(false);

  const { loading, clinicianData } = clinician;
  const classes = useStyles();
  const dispatch = useDispatch();
  const rows: Array<any> = [];

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClickClose = () => {
    setOpen(false);
  };

  const editClinician = (data: any) => {
    setEditData(data);
    setClinicianID(data.id);
    setEdit(true);
    setOpen(true);
  };

  const getClinicName = (id: number) => {
    const clinic = clinician.clinicList.data.result.filter((item) => item.id === id);
    return clinic[0]?.label;
  };

  useEffect(() => {
    dispatch(getClinicainsRequest());
  }, []);

  useEffect(() => {
    let filteredDatas: any = [];
    const timeoutId = setTimeout(() => {
      filteredDatas = (clinician.clinicianData?.data?.result || []).filter((e) => {
        return (
          (e.id && e.id.toString().includes(searchValue.toUpperCase())) ||
          ((e.first_name || e.last_name) &&
            `${e.first_name} ${e.last_name}`.toUpperCase().includes(searchValue.toUpperCase())) ||
          getClinicName(e.id_org)?.toUpperCase().includes(searchValue.toUpperCase()) ||
          (e.npi && e.npi.toUpperCase().includes(searchValue.toUpperCase())) ||
          (e.email && e.email.toUpperCase().includes(searchValue.toUpperCase())) ||
          (e.address1 && e.address1.toUpperCase().includes(searchValue.toUpperCase())) ||
          (e.created_date &&
            moment(e.created_date)
              .utc()
              .format('MMM DD, YYYY')
              .toUpperCase()
              .includes(searchValue.toUpperCase()))
        );
      });
      setSearchList(filteredDatas);
    }, 1000);
    return () => clearTimeout(timeoutId);
  }, [clinician.clinicList, searchValue]);

  const handleClinicianClose = () => {
    setOpen(false);
    setDevicesOpen(false);
    setDevicesOpen(false);
    setEditData({});
    setView(false);
    setTimeout(() => {
      setEdit(false);
    }, 0);
  };

  const deleteRows = (selected: Array<string>) =>
    selected.map((row: any) => {
      dispatch(deleteClinicainRequest(row, setAlert));
    });

  const createClinician = (data: Clinician) => {
    dispatch(setClinicainRequest(data, setAlert));
    handleClickClose();
  };

  const updateClinician = (data: Clinician) => {
    dispatch(updateClinicianRequest(data, clinicianID, setAlert));
    handleClickClose();
    setTimeout(() => {
      dispatch(getClinicainsRequest());
    }, 0);
  };

  const onViewClinician = (data: any) => {
    setEditData(data);
    setClinicianID(data.id);
    setOpen(true);
    setView(true);
  };

  const handleDeviceSumbit = (data: any) => {
    const clinician = {
      id_person_clinician: clinicianID,
    };
    dispatch(assignDeviceRequest(clinician, data.device.id));
    setTimeout(() => {
      dispatch(getCurrentDeviceRequest(clinicianID));
      dispatch(getAssignableDevicesRequest(clinicianID));
    }, 1000);
  };

  const handlePatientSubmit = (data: any) => {
    return edit ? updateClinician(data) : createClinician(data);
  };

  const handleDevice = (data: any) => {
    setDevicesOpen(true);
    setClinicianID(data.id);
  };

  const onResendInvite = (data: any) => {
    post('/auth/forgot-password', { username: data.email }, auth())
      .then(() => {
        setAlert(true);
        setClinicianOperationSuccess(true);
        setClinicianToast('resend invite successful');
      })
      .catch(() => {
        setAlert(true);
        setClinicianOperationSuccess(false);
        setClinicianToast('resend invite failed');
      });
  };

  const handleSearch = (value: any) => {
    setSearchValue(value);
  };
  const triggerClinicianUpdateToast = () => {
    if (!clinician.loading) {
      const { updateClinician } = clinician;
      setClinicianOperationSuccess(updateClinician?.success);
      setClinicianToast(updateClinician?.data?.message);
    }
  };
  const triggerClinicianCreateToast = () => {
    if (!clinician.loading) {
      const { createClinician } = clinician;
      setClinicianOperationSuccess(createClinician?.success);
      setClinicianToast(createClinician?.data?.message);
    }
  };
  const triggerClinicianDeleteToast = () => {
    if (!clinician.loading) {
      const { deleteClinician } = clinician;
      setClinicianOperationSuccess(deleteClinician?.success);
      setClinicianToast(deleteClinician?.data?.message);
    }
  };
  useEffect(() => {
    triggerClinicianUpdateToast();
  }, [clinician.updateClinician]);
  useEffect(() => {
    triggerClinicianDeleteToast();
  }, [clinician.deleteClinician]);
  useEffect(() => {
    triggerClinicianCreateToast();
  }, [clinician.createClinician]);
  const handleClose = () => {
    setAlert(false);
  };

  const title = view ? 'View Clinician' : edit ? 'Edit Clinician' : 'Add Clinician';
  return (
    <div className={classes.root}>
      <div className={classes.body}>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={alert}
          autoHideDuration={5000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity={clinicianOperationSuccess ? 'success' : 'error'}>
            {clinicianToast}
          </Alert>
        </Snackbar>
        <Typography variant='h6'>Clinician List</Typography>

        <UserForm
          modalOpen={open}
          modalClose={handleClinicianClose}
          onSubmit={handlePatientSubmit}
          title={title}
          view={view}
          clinician={true}
          editData={editData}
          edit={edit}
        />
        <UserForm
          modalOpen={devicesOpen}
          modalClose={handleClinicianClose}
          onSubmit={handleDeviceSumbit}
          device={true}
          title='Assign Device'
          edit={edit}
          clinicianID={clinicianID}
        />
        <div
          style={{
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
          }}
        >
          <SearchIcon style={{ marginRight: -12 }} />
          <Input
            type='text'
            onChange={(e: any) => handleSearch(e.target.value)}
            style={{
              width: 300,
              padding: 8,
              paddingLeft: 12,
              marginRight: 8,
            }}
            placeholder='Search'
          />
          <CustomButton height='2.5em' width='max-content' onClick={handleClickOpen}>
            <PersonAddIcon style={{ marginRight: '5px' }} />
            Add Clinician
          </CustomButton>
        </div>
      </div>
      <CustomTable
        loading={loading}
        header={headCells}
        dataRows={searchValue ? searchList : clinicianData.data?.result}
        clinicList={clinician?.clinicList?.data?.result}
        deleteRows={deleteRows}
        onEdit={editClinician}
        onView={onViewClinician}
        handleDevices={handleDevice}
        onResendInvite={onResendInvite}
      />
    </div>
  );
}

const mapStateToProps = (state: ClinicianProps) => ({
  userData: state.userAuth.userData,
  clinician: state.clinician,
});

export default connect(mapStateToProps)(Clinicians);
