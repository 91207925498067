import {
  ON_LOGIN_WITH_EMAIL_REQUEST,
  ON_LOGIN_WITH_EMAIL_SUCCESS,
  ON_LOGIN_WITH_EMAIL_FAILED,
  ON_LOGOUT_SUCCESS,
  ON_LOGOUT_REQUEST,
  ON_LOGOUT_FAILURE,
} from '../actionTypes';

export const loginRequest = (data: any) => ({
  type: ON_LOGIN_WITH_EMAIL_REQUEST,
  payload: data,
});

export const loginSuccess = (data: any) => ({
  type: ON_LOGIN_WITH_EMAIL_SUCCESS,
  payload: data,
});

export const loginFailed = (data: any) => ({
  type: ON_LOGIN_WITH_EMAIL_FAILED,
  payload: data,
});

export const logoutRequest = () => ({
  type: ON_LOGOUT_REQUEST,
});

export const logoutSuccess = () => ({
  type: ON_LOGOUT_SUCCESS,
});

export const logoutFailure = (error: any) => ({
  type: ON_LOGOUT_FAILURE,
  payload: error,
});
