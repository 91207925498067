//SessionDetailsTable.tsx
/**
 * Defines a table for displaying detailed information about sessions. NOTE: As of
 * this table is very similar to the more general Sessions Table displayed on the main
 * dashboard.  Updates to column headers, formatting and such should be propagated to
 * that table when appropriate to maintain consistency.
 *
 * @module
 * @author:
 * @copyright:
 */
import React, { useState, MouseEvent } from 'react';
import { v4 } from 'uuid';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import MenuDropdown from '../components/MenuDropdown';

import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

import Tooltip from '@material-ui/core/Tooltip';

import VisibilityIcon from '@material-ui/icons/Visibility';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import { styled } from '@material-ui/core/styles';
import { LinearProgress } from '@material-ui/core';

import { secondsToHms, getEstFormattedDateTime } from '../utils/helper/formatTime';

interface Data {
  any;
}

type Order = 'asc' | 'desc';

interface EnhancedTableProps {
  classes: ReturnType<typeof useStyles>;
  headerCells: any;
  onRequestSort: (event: MouseEvent<unknown>, property: keyof Data) => void;
  order: Order;
  orderBy: string;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { classes, headerCells, order, orderBy, onRequestSort } = props;

  const createSortHandler = (property: keyof Data) => (event: MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headerCells.map((headCell: any) => {
          return (
            <TableCell
              key={v4()}
              align='left'
              padding='default'
              sortDirection={orderBy === headCell.id ? order : false}
            >
              {headCell.id !== 'options' && (
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : 'asc'}
                  onClick={createSortHandler(headCell.id)}
                >
                  {headCell.label}
                  {orderBy === headCell.id ? (
                    <span className={classes.visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </span>
                  ) : null}
                </TableSortLabel>
              )}
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
}

const ErrorText = styled(Typography)({
  padding: '1em',
  textAlign: 'center',
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      // maxHeight: '85vh',
      marginBottom: theme.spacing(2),
      overflowY: 'auto',
      overflowX: 'hidden',
    },
    table: {
      'minWidth': 750,
      '& tr:nth-of-type(even)': {
        backgroundColor: 'rgba(0, 0, 0, 0.05)',
      },
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    link: {
      'padding': '7px 16px',
      'color': theme.palette.primary.main,
      'cursor': 'pointer',
      'fontSize': 14,
      'fontWeight': 600,
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
        color: 'white',
      },
    },
    parameterLink: {
      'padding': 0,
      'color': theme.palette.primary.main,
      'cursor': 'pointer',
      'fontSize': 14,
      'fontWeight': 600,
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
        color: 'white',
      },
    },
  }),
);

interface SessionDetailsTableProps {
  header: any;
  dataRows: Array<any>;
  loading: boolean;
  onParametersPress: (args0: any) => void;
  onAdvancedParametersPress: (args0: any) => void;
  onSessionViewPress: (args0: any) => void;
  onNotesViewPress: (args0: number, args1: Array<any>) => void;
  onPatientReportDownload: (args0: any) => void;
  onRawAccelerationDataDownload: (args0: any) => void;
  on50msPhonationDataDownload: (args0: any) => void;
}

export default function SessionDetailsTable({
  header,
  loading,
  dataRows,
  onAdvancedParametersPress,
  onParametersPress,
  onSessionViewPress,
  onNotesViewPress,
  onPatientReportDownload,
  onRawAccelerationDataDownload,
  on50msPhonationDataDownload,
}: SessionDetailsTableProps) {
  /**Defines the menu embedded within the Session Details table. */
  //NOTE: 2023-03-13: Removed the patient report as a downloadable b/c there were errors in its
  //generation.  Refer to commit aa39fb5 (or earlier) for returning the code.
  const sessionDetailsTableMenu = [
    {
      key: 1,
      label: 'Notes',
      handleClick: (data: any) => {
        onNotesViewPress(data.id, data.session_notes || []);
      },
    },
    {
      key: 2,
      label: 'Parameters',
      handleClick: (data: any) => onParametersPress(data.parameters_by_clinician),
    },
    {
      key: 3,
      label: 'Advanced Parameters',
      handleClick: (data: any) => onAdvancedParametersPress(data.advanced_parameters),
    },
    {
      key: 5,
      label: 'Raw acceleration data',
      handleClick: (data: any) => onRawAccelerationDataDownload(data),
    },
    {
      key: 6,
      label: ' 50ms phonation data',
      handleClick: (data: any) => on50msPhonationDataDownload(data),
    },
  ];

  const classes = useStyles();
  const [order, setOrder] = useState<Order>('desc');
  const [orderBy, setOrderBy] = useState<keyof Data>(header[0].id);

  const handleRequestSort = (event: MouseEvent<unknown>, property: keyof Data) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const getSortedData = (data, orderBy, order) => {
    if (data) {
      let sortedData;
      const sortMethod = header.find((head) => head.id === orderBy).sortMethod;
      // get null values at data[orderBy], filter them out
      const nulls = data.filter((patient) => patient[orderBy] === null);
      // data without null values
      const newData = data.filter((patient) => patient[orderBy] !== null);

      const alphabeticSort = (a, b) => {
        if (order === 'asc') {
          return a[orderBy].localeCompare(b[orderBy]);
        } else {
          return b[orderBy].localeCompare(a[orderBy]);
        }
      };

      const numericSort = (a, b) => {
        if (order === 'asc') {
          return a[orderBy] - b[orderBy];
        } else {
          return b[orderBy] - a[orderBy];
        }
      };

      const deviceSort = (a, b) => {
        const a1 = `${a.device.serial_number} (${a.device.id})`;
        const b1 = `${b.device.serial_number} (${b.device.id})`;

        if (order === 'asc') {
          return a1.localeCompare(b1);
        } else {
          return b1.localeCompare(a1);
        }
      };

      const dateSort = (a, b) => {
        if (order === 'asc') {
          if (new Date(a[orderBy]) > new Date(b[orderBy])) return 1;
          if (new Date(a[orderBy]) < new Date(b[orderBy])) return -1;
          return 0;
        } else {
          if (new Date(a[orderBy]) > new Date(b[orderBy])) return -1;
          if (new Date(a[orderBy]) < new Date(b[orderBy])) return 1;
          return 0;
        }
      };

      // use appropriate sort function depending on orderBy property's sortMethod
      sortMethod === 'date'
        ? (sortedData = newData.sort(dateSort))
        : sortMethod === 'device'
        ? (sortedData = newData.sort(deviceSort))
        : sortMethod === 'alpha'
        ? (sortedData = newData.sort(alphabeticSort))
        : sortMethod === 'numeric'
        ? (sortedData = newData.sort(numericSort))
        : (sortedData = newData.sort((a, b) => (b[orderBy] = a[orderBy])));
      // append filtered out nulls
      sortedData.push(...nulls);

      return sortedData;
    }
  };

  const tableRows =
    dataRows &&
    getSortedData(dataRows, orderBy, order).map((row: any, index) => {
      const labelId = `enhanced-table-checkbox-${index}`;
      return (
        <TableRow hover key={row.id}>
          <TableCell component='th' id={labelId} scope='row'>
            {row.id}
          </TableCell>

          <TableCell component='th' align='left'>
            {getEstFormattedDateTime(row.start_date)}
          </TableCell>

          <TableCell component='th' align='left'>
            {getEstFormattedDateTime(row.end_date)}
          </TableCell>

          <TableCell component='th' align='center'>
            {secondsToHms(row.duration || 0)}
          </TableCell>

          <TableCell component='th' align='left'>
            {secondsToHms(row.voiced || 0)}
          </TableCell>

          <TableCell align='left' component='th'>
            {row.biofeedback_count || 0}
          </TableCell>

          <TableCell align='left' component='th'>
            {row.pitch}
          </TableCell>

          <TableCell align='left' component='th'>
            {row.loudness || 0}
          </TableCell>

          <TableCell component='th'>{`${row.device.serial_number} (${row.device.id})`}</TableCell>

          <TableCell component='th'>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Tooltip title='Session Details'>
                <VisibilityIcon
                  onClick={() => onSessionViewPress(row.id)}
                  style={{ cursor: 'pointer' }}
                />
              </Tooltip>
              <MenuDropdown items={sessionDetailsTableMenu} data={row}>
                <MoreVertIcon color='primary' />
              </MenuDropdown>
            </div>
          </TableCell>
        </TableRow>
      );
    });

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        {loading && <LinearProgress />}
        <Table className={classes.table} size={'small'} aria-label='enhanced table'>
          <EnhancedTableHead
            classes={classes}
            headerCells={header}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />
          {dataRows && <TableBody>{tableRows}</TableBody>}
        </Table>
        {dataRows?.length === 0 && <ErrorText variant='body1'>No Data</ErrorText>}
      </Paper>
    </div>
  );
}
