import { call, takeLatest, put } from 'redux-saga/effects';

import { ON_GET_SESSIONS_DATA_REQUEST } from '../actionTypes';
import { getSessionsSuccess, getSessionsFailed } from '../actions';
import { getSessions } from '../../utils/services/session';

function* getSessionsSaga(action: any) {
  try {
    const result = yield call(getSessions);
    if (!result.success) throw result.data;
    yield put(getSessionsSuccess(result));
  } catch (error) {
    yield put(getSessionsFailed(error));
  }
}
const sessionSaga = [takeLatest(ON_GET_SESSIONS_DATA_REQUEST, getSessionsSaga)];
export default sessionSaga;
