import { get } from '../index';
const ENDPOINT = '/user';

export const getDashboardData = () => {
  const auth = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('simbex_authtoken')}`,
    },
  };
  const url = `${ENDPOINT}/dashboard`;

  return get(url, auth);
};
