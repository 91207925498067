import { Checkbox, Grid, styled, TextField, Typography } from '@material-ui/core';
import { get } from 'lodash';
import React, { useEffect } from 'react';
import { Controller } from 'react-hook-form';
import { connect, useDispatch } from 'react-redux';
import { medFormErrors } from '../constants/errors';
import {
  getClinicainsRequest,
  getDiagnosisRequest,
  getGenderRequest,
  getTreatmentStatusRequest,
} from '../store/actions';
import Dropdown from './Dropdown';

const Input = styled(TextField)({});
const GridItem = styled(Grid)({
  padding: '10px 10px !important',
  display: 'flex',
  alignItems: 'end',
  width: '7em',
});

const SubCategory = styled('div')({
  //   marginLeft: 42,
  // height: '2em',
  // width: '12em',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
});

const DatePicker = styled('input')({
  marginTop: 10,
  width: '100%',
  padding: '9px',
  border: '1px solid #c4c4c4',
  borderRadius: '3px',
  color: '#757575',
});

function MedicalForm({
  control,
  errors,
  list,
  watch,
  clinicians,
  isAdmin,
  editData,
  edit,
  setValue,
}: any) {
  const dispatch = useDispatch();
  const { diagnosis, loading } = list;
  const parentDiagnosis = diagnosis?.result.filter(
    (item: any) => !item.level && item.name !== 'Other',
  );
  const childDiagnosis = diagnosis?.result.filter((item: any) => item.level);
  useEffect(() => {
    dispatch(getDiagnosisRequest());
    if (edit && editData?.data?.patient) {
      const patient = editData?.data?.patient;
      const selectedParentDiagnosis = patient.patient_diagnoses
        ? patient.patient_diagnoses.map((item: any) => {
            return item.id_diagnosis_type;
          })
        : [];
      const selectedSubDiagnosis = patient.patient_diagnoses
        ? patient.patient_diagnoses.map((item: any) => {
            return item.id_diagnosis_type_sub;
          })
        : [];
      diagnosis?.result.map((diagnosis: any) => {
        return selectedParentDiagnosis.includes(diagnosis.id) && setValue(diagnosis.name, true);
      });
      setTimeout(() => {
        diagnosis?.result.map((diagnosis: any) => {
          return selectedSubDiagnosis.includes(diagnosis.id) ? setValue(diagnosis.name, true) : '';
        });
        if (patient.patient_diagnoses) {
          patient.patient_diagnoses.map((item: any) => {
            return (
              item.diagnosis === 'Other (specify)' &&
              setValue('otherDiagnosis', `${item.other_text}`)
            );
          });
        }
      }, 0);
    }
  }, []);
  return (
    <Grid container spacing={5}>
      {parentDiagnosis?.map((parent: any) => {
        return (
          <GridItem item xs={12} sm={3}>
            <Controller
              name={parent.name}
              control={control}
              render={(props) => (
                <Checkbox
                  color='primary'
                  style={{ padding: 0 }}
                  onChange={(e) => props.onChange(e.target.checked)}
                  checked={props.value}
                />
              )}
            />
            <div style={{ padding: 3 }}>
              <Typography component='div' variant='caption'>
                {parent.label}
              </Typography>
              {childDiagnosis.map((child: any) => {
                return (
                  watch(parent.name) &&
                  child.id_parent === parent.id && (
                    <SubCategory>
                      <Controller
                        name={child.name}
                        control={control}
                        render={(props) => (
                          <Checkbox
                            color='primary'
                            value={child.id}
                            onChange={(e) => props.onChange(e.target.checked)}
                            checked={props.value}
                          />
                        )}
                      />
                      <Typography variant='caption'>{child.label}</Typography>
                    </SubCategory>
                  )
                );
              })}
            </div>
          </GridItem>
        );
      })}
      <GridItem item xs={12} sm={6}>
        <div>
          <Controller
            name='Other'
            control={control}
            render={(props) => (
              <Checkbox
                color='primary'
                style={{ padding: 0 }}
                onChange={(e) => props.onChange(e.target.checked)}
                checked={props.value}
              />
            )}
          />
          <Typography variant='caption'>Other (specify)</Typography>
        </div>
        <GridItem item xs={12} sm={8}>
          {watch('Other') && (
            <Controller
              as={<Input size='small' label='Other Diagnosis' variant='outlined' />}
              defaultValue=''
              name='otherDiagnosis'
              control={control}
              rules={{
                required: true,
              }}
            />
          )}
          {errors.otherDiagnosis && (
            <Typography variant='caption' color='error'>
              {get(medFormErrors.otherDiagnosis, errors.otherDiagnosis.type, '')}
            </Typography>
          )}
        </GridItem>
      </GridItem>
    </Grid>
  );
}

const mapStateToProps = (state: any) => ({
  list: state.list,
});

export default connect(mapStateToProps)(MedicalForm);
