import { call, takeLatest, put, takeEvery } from 'redux-saga/effects';

import {
  ON_DELETE_CLINICIAN_REQUEST,
  ON_GET_CLINICIANS_DATA_REQUEST,
  ON_GET_CLINIC_REQUEST,
  ON_SET_CLINICIAN_REQUEST,
  ON_UPDATE_CLINICIAN_REQUEST,
} from '../actionTypes';
import {
  getCliniciansSuccess,
  getCliniciansFailed,
  setClinicianFailed,
  setClinicianSuccess,
  deleteClinicianSuccess,
  deleteClinicianFailed,
  getClinicainsRequest,
  updateClinicianSuccess,
  updateClinicianFailed,
  getClinicListFailed,
  getClinicListSuccess,
} from '../actions';
import {
  deleteClinician,
  getClinicians,
  getClinicList,
  setClinician,
  updateClinician,
} from '../../utils/services/clinician';

function* getCliniciansSaga(action: any) {
  try {
    const result = yield call(getClinicians);
    if (!result.success) throw result.data;
    yield put(getCliniciansSuccess(result));
  } catch (error) {
    yield put(getCliniciansFailed(error));
  }
}
function* setClinicianSaga(action: any) {
  try {
    const { data } = action;
    const clinician = {
      first_name: data.firstName,
      last_name: data.lastName,
      email: data.email,
      address1: data.street,
      city: data.city,
      zip: data.zip,
      id_org: data.clinicName.id,
      npi: data.npi,
      id_state: data.state.id,
      id_country: data.state.id_country,
      id_clinician_credential: data.credential.id,
      clinician_credential_text: data.credential.label === 'Other' ? data.otherCredential : '',
      id_time_zone: data.timeZones.id,
    };
    const result = yield call(setClinician, clinician);
    if (!result.success) throw result;
    yield put(setClinicianSuccess(result));
    yield put(getClinicainsRequest());
    action.toast(true);
  } catch (error) {
    yield put(setClinicianFailed(error));
    action.toast(true);
  }
}
function* deleteClinicianSaga(action: any) {
  try {
    const { data } = action;

    const result = yield call(deleteClinician, data);
    if (!result.success) throw result;
    yield put(deleteClinicianSuccess(result));
    yield put(getClinicainsRequest());
    action.toast(true);
  } catch (error) {
    yield put(deleteClinicianFailed(error));
    action.toast(true);
  }
}
function* updateClinicianSaga(action: any) {
  try {
    const { data, clinicianID } = action;
    const clinician = {
      first_name: data.firstName,
      last_name: data.lastName,
      email: data.email,
      address1: data.street,
      city: data.city,
      zip: data.zip,
      id_org: data.clinicName.id,
      npi: data.npi,
      id_state: data.state.id,
      id_country: data.state.id_country,
      id_clinician_credential: data.credential.id,
      clinician_credential_text: data.credential.label === 'Other' ? data.otherCredential : '',
      id_time_zone: data.timeZones.id,
    };
    const result = yield call(updateClinician, clinician, clinicianID);
    if (!result.success) throw result;
    yield put(updateClinicianSuccess(result));
    action.toast(true);
  } catch (error) {
    yield put(updateClinicianFailed(error));
    action.toast(true);
  }
}
function* getClinicListSaga(action: any) {
  try {
    const result = yield call(getClinicList);
    if (!result.success) throw result.data;
    yield put(getClinicListSuccess(result));
  } catch (error) {
    yield put(getClinicListFailed(error));
  }
}
const clinicianSaga = [
  takeLatest(ON_GET_CLINICIANS_DATA_REQUEST, getCliniciansSaga),
  takeLatest(ON_SET_CLINICIAN_REQUEST, setClinicianSaga),
  takeEvery(ON_DELETE_CLINICIAN_REQUEST, deleteClinicianSaga),
  takeEvery(ON_UPDATE_CLINICIAN_REQUEST, updateClinicianSaga),
  takeEvery(ON_GET_CLINIC_REQUEST, getClinicListSaga),
];

export default clinicianSaga;
