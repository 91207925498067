import {
  ON_SESSION_DETAILS_REQUEST,
  ON_SESSION_DETAILS_SUCCESS,
  ON_SESSION_DETAILS_FAILED,
  ON_SESSION_VISUAL_DETAILS_REQUEST,
  ON_SESSION_VISUAL_DETAILS_SUCCESS,
  ON_SESSION_VISUAL_DETAILS_FAILED,
  ON_SESSION_VISUAL_TIME_DETAILS_REQUEST,
  ON_SESSION_VISUAL_TIME_DETAILS_SUCCESS,
  ON_SESSION_VISUAL_TIME_DETAILS_FAILED,
  ON_SESSION_HEATMAP_REQUEST,
  ON_SESSION_HEATMAP_SUCCESS,
  ON_SESSION_HEATMAP_FAILED,
} from '../actionTypes';

const sessionInitialState = {
  loading: true,
  sessionsDetailsData: {},
  sessionVisualData: {
    loading: false,
    error: false,
  },
  sessionVisualTimeData: {},
  sessionHeatmapData: {
    data: {},
    loading: false,
    error: false,
  },
  error: {},
};

const sessionDetailsReducer = (state = sessionInitialState, action: any) => {
  switch (action.type) {
    case ON_SESSION_DETAILS_REQUEST:
      return { ...state, loading: true };
    case ON_SESSION_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        sessionsDetailsData: action.payload,
        error: {},
      };
    case ON_SESSION_DETAILS_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
        sessionsDetailsData: {},
      };
    case ON_SESSION_VISUAL_DETAILS_REQUEST:
      return {
        ...state,
        sessionVisualData: {
          loading: true,
        },
      };
    case ON_SESSION_VISUAL_DETAILS_SUCCESS:
      return {
        ...state,
        sessionVisualData: {
          loading: false,
          error: {},
          data: action.payload,
        },
      };
    case ON_SESSION_VISUAL_DETAILS_FAILED:
      return {
        ...state,
        sessionVisualData: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    case ON_SESSION_VISUAL_TIME_DETAILS_REQUEST:
      return {
        ...state,
        sessionVisualTimeData: {
          loading: true,
        },
      };
    case ON_SESSION_VISUAL_TIME_DETAILS_SUCCESS:
      return {
        ...state,
        sessionVisualTimeData: {
          ...action.payload,
          loading: false,
          error: false,
        },
      };
    case ON_SESSION_VISUAL_TIME_DETAILS_FAILED:
      return {
        ...state,
        sessionVisualTimeData: {
          ...action.payload,
          loading: false,
          error: true,
        },
      };
    case ON_SESSION_HEATMAP_REQUEST:
      return {
        ...state,
        sessionHeatmapData: {
          loading: true,
        },
      };
    case ON_SESSION_HEATMAP_SUCCESS:
      return {
        ...state,
        sessionHeatmapData: {
          loading: false,
          error: {},
          data: action.payload,
        },
      };
    case ON_SESSION_HEATMAP_FAILED:
      return {
        ...state,
        sessionHeatmapData: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    default:
      return state;
  }
};

export default sessionDetailsReducer;
