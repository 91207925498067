import {
  ON_GET_CLINICIANS_DATA_REQUEST,
  ON_GET_CLINICIANS_DATA_SUCCESS,
  ON_GET_CLINICIANS_DATA_FAILED,
  ON_SET_CLINICIAN_SUCCESS,
  ON_SET_CLINICIAN_REQUEST,
  ON_SET_CLINICIAN_FAILED,
  ON_DELETE_CLINICIAN_REQUEST,
  ON_DELETE_CLINICIAN_FAILED,
  ON_UPDATE_CLINICIAN_FAILED,
  ON_UPDATE_CLINICIAN_REQUEST,
  ON_UPDATE_CLINICIAN_SUCCESS,
  ON_GET_CLINIC_FAILED,
  ON_GET_CLINIC_REQUEST,
  ON_GET_CLINIC_SUCCESS,
} from '../actionTypes';

const sessionInitialState = {
  loading: true,
  clinicianData: {},
  error: {},
};

const clinicianReducer = (state = sessionInitialState, action: any) => {
  switch (action.type) {
    case ON_GET_CLINICIANS_DATA_REQUEST:
      return { ...state, loading: true };
    case ON_GET_CLINICIANS_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        clinicianData: action.payload,
        error: {},
      };
    case ON_GET_CLINICIANS_DATA_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
        clinicianData: {},
      };
    case ON_SET_CLINICIAN_REQUEST:
      return { ...state, loading: true };
    case ON_SET_CLINICIAN_SUCCESS:
      return {
        ...state,
        loading: false,
        createClinician: action.payload,
        error: {},
      };
    case ON_SET_CLINICIAN_FAILED:
      return {
        ...state,
        loading: false,
        createClinician: action.payload,
      };
    case ON_DELETE_CLINICIAN_REQUEST:
      return { ...state, loading: true };
    case ON_DELETE_CLINICIAN_REQUEST:
      return {
        ...state,
        loading: false,
        deleteClinician: action.payload,
        error: {},
      };
    case ON_DELETE_CLINICIAN_FAILED:
      return {
        ...state,
        loading: false,
        deleteClinician: action.payload,
      };
    case ON_UPDATE_CLINICIAN_REQUEST:
      return { ...state, loading: true };
    case ON_UPDATE_CLINICIAN_SUCCESS:
      return {
        ...state,
        loading: false,
        updateClinician: action.payload,
        error: {},
      };
    case ON_UPDATE_CLINICIAN_FAILED:
      return {
        ...state,
        loading: false,
        updateClinician: action.payload,
      };
    case ON_GET_CLINIC_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case ON_GET_CLINIC_SUCCESS: {
      return {
        ...state,
        loading: false,
        clinicList: action.payload,
        error: {},
      };
    }
    case ON_GET_CLINIC_FAILED: {
      return {
        ...state,
        loading: false,
        error: action.payload,
        clinicList: {},
      };
    }

    default:
      return state;
  }
};

export default clinicianReducer;
