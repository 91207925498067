//dashboard.ts
/**
 * Defines style, metadata, etc. for widgets displayed on the main dashboard.
 *
 * @module
 * @author:
 * @copyright:
 */
import { CardPatientIcon, CardClinicianIcon, CardDeviceIcon } from '../assets/icons';

export const createCards = (patient: number, clinician: number, device: number) => [
  {
    key: 'patient',
    label: 'Total number of Users',
    color: '#007cba',
    Icon: CardPatientIcon,
    value: patient,
    path: '/patients',
  },
  {
    key: 'clinician',
    label: 'Total number of Clinicians',
    color: '#00b2e3',
    Icon: CardClinicianIcon,
    value: clinician,
    path: '/clinicians',
  },
  {
    key: 'device',
    label: 'Total number of Devices',
    color: '#0095a9',
    Icon: CardDeviceIcon,
    value: device,
    path: '/devices',
  },
];

/**Defines key, label and alignment of the column headers for a session list table.
 * NOTE: There are other places in the application where session lists are displayed.
 * To maintain consistency, any changes to the labeling here should be cross checked
 * against those other pages.
 */
export const sessionHeaders = [
  {
    key: '#',
    label: '#',
    align: 'left',
  },
  {
    key: 'patient',
    label: 'User ID',
    align: 'left',
  },
  {
    key: 'clinician',
    label: 'Clinician Name',
    align: 'left',
  },
  {
    key: 'startTime',
    label: 'Start Time',
    align: 'center',
  },
  {
    key: 'endTime',
    label: 'End Time',
    align: 'center',
  },
  {
    key: 'duration',
    label: 'Unpaused Duration',
    align: 'center',
  },
  {
    key: 'voiced',
    label: 'Voiced',
    align: 'center',
  },
  {
    key: 'lastUpdated',
    label: 'Last Updated',
    align: 'center',
  },
  {
    key: 'viewLink',
    label: '',
    align: 'center',
  },
];
