import {
  ON_LOGIN_WITH_EMAIL_REQUEST,
  ON_LOGIN_WITH_EMAIL_SUCCESS,
  ON_LOGIN_WITH_EMAIL_FAILED,
  ON_LOGOUT_REQUEST,
  ON_LOGOUT_SUCCESS,
  ON_LOGOUT_FAILURE,
} from '../actionTypes';

const authInitialState = {
  userData: {},
  authToken: '',
  loading: false,
  isLoggedIn: false,
  error: {},
};

const authReducer = (state = authInitialState, action: any) => {
  switch (action.type) {
    case ON_LOGIN_WITH_EMAIL_REQUEST:
      return { ...state, loading: true, isLoggedIn: false };
    case ON_LOGIN_WITH_EMAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        userData: action.payload,
        authToken: action.payload.data.authToken,
        isLoggedIn: true,
        error: {},
      };
    case ON_LOGIN_WITH_EMAIL_FAILED:
      return {
        ...state,
        loading: false,
        isLoggedIn: false,
        error: action.payload,
        userData: {},
      };
    case ON_LOGOUT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ON_LOGOUT_SUCCESS:
      return {
        ...state,
      };
    case ON_LOGOUT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default authReducer;
