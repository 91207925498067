import React from 'react';
import { Typography } from '@material-ui/core';
import ChatBubbleIcon from '@material-ui/icons/Message';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import Moment from 'react-moment';
import DoctorChat from '../assets/doctor_chat.png';
import { getEstFormattedDateTime } from '../utils/helper/formatTime';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: 24,
      alignItems: 'center',
      borderBottomWidth: 0.7,
      borderColor: 'rgba(0, 0, 0, 0.12)',
    },
    chatContainer: {
      display: 'flex',
      flex: 1,
      alignItems: 'flex-end',
      flexDirection: 'row',
    },
    chatIcon: { height: 44, width: 44 },
    chatText: {
      color: 'rgb(51, 51, 51)',
      fontSize: 14,
    },
    chatDate: {
      color: 'rgb(51, 51, 51)',
      fontSize: 12,
      textAlign: 'end',
      marginLeft: 55,
    },
  }),
);

interface ChatCardProps {
  text: string;
  time: string;
  fromPatient: boolean;
  lastChild: boolean;
}

const ChatCard = (props: ChatCardProps) => {
  const lastChildRef: any = React.useRef();
  const chatRef: any = React.useRef();
  const classes = useStyles();
  React.useEffect(() => {
    if (lastChildRef.current) lastChildRef.current.scrollIntoView();
  }, []);
  return (
    <div className={classes.root} ref={props.lastChild ? lastChildRef : chatRef}>
      <div style={{ marginLeft: props.fromPatient ? 'auto' : 'unset' }}>
        <div className={classes.chatContainer}>
          {props.fromPatient ? (
            <AccountCircleIcon className={classes.chatIcon} htmlColor='rgb(0, 124, 186)' />
          ) : (
            <img src={DoctorChat} alt='doctor' style={{ width: '37px' }} />
          )}
          <div
            style={{
              backgroundColor: '#f0f5f5',
              borderWidth: 0.2,
              maxWidth: '23em',
              borderRadius: 10,
              padding: 8,
              marginLeft: 8,
              borderTopLeftRadius: 0,
              wordWrap: 'break-word',
            }}
          >
            <Typography className={classes.chatText}>{props.text}</Typography>
          </div>
        </div>
        <Typography className={classes.chatDate}>{getEstFormattedDateTime(props.time)}</Typography>
      </div>
    </div>
  );
};

export default ChatCard;
