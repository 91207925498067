import {
  ON_GET_SESSIONS_DATA_REQUEST,
  ON_GET_SESSIONS_DATA_SUCCESS,
  ON_GET_SESSIONS_DATA_FAILED,
  ON_PATIENT_SESSION_SUCCESS,
} from '../actionTypes';

const sessionInitialState = {
  loading: true,
  sessionsData: [],
  error: {},
};

const sessionReducer = (state = sessionInitialState, action: any) => {
  switch (action.type) {
    case ON_GET_SESSIONS_DATA_REQUEST:
      return { ...state, loading: true, sessionsData: [] };
    case ON_GET_SESSIONS_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        sessionsData: action.payload,
        error: {},
      };
    case ON_GET_SESSIONS_DATA_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
        sessionsData: [],
      };
    case ON_PATIENT_SESSION_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        sessionsData: action.data.filter((session) => session.end_date),
      };
    }
    default:
      return state;
  }
};

export default sessionReducer;
