import Login from '../../screens/Login';
import ForgotPassword from '../../screens/ForgotPassword';

import { LOGIN_PATH, FORGOT_PASS_PATH } from './publicRoutes';
import {
  DASHBOARD_PATH,
  PATIENTS_PATH,
  CLINICIANS_PATH,
  PATIENT_DETAILS_PATH,
  AUTH_CHECK_PATH,
  SESSION_VISUAL_PATH,
  DEVICES_PATH,
} from './privateRoutes';
import Dashboard from '../../screens/Dashboard';
import Patients from '../../screens/Patients';
import Clinicians from '../../screens/Clinicians';
import PatientDetails from '../../screens/PatientDetails';
import AuthCheck from '../../screens/AuthCheck';
import SessionVisuals from '../../screens/SessionVisuals';
import Devices from '../../screens/Devices';

const Routes = [
  {
    key: 'login',
    path: LOGIN_PATH,
    pageTitle: 'Login',
    component: Login,
    subRoutes: [],
    nextPage: null,
    prevPage: null,
    exact: true,
    isPrivate: false,
  },
  {
    key: 'forgot-password',
    path: FORGOT_PASS_PATH,
    pageTitle: 'Forgot Password',
    component: ForgotPassword,
    subRoutes: [],
    nextPage: null,
    prevPage: null,
    exact: true,
    isPrivate: false,
  },
  {
    key: 'dashboard',
    path: DASHBOARD_PATH,
    pageTitle: 'Dashboard',
    component: Dashboard,
    subRoutes: [],
    nextPage: null,
    prevPage: null,
    exact: true,
    isPrivate: true,
  },
  {
    key: 'patients',
    path: PATIENTS_PATH,
    pageTitle: 'Users',
    component: Patients,
    subRoutes: [],
    nextPage: null,
    prevPage: null,
    exact: true,
    isPrivate: true,
  },
  {
    key: 'clinicians',
    path: CLINICIANS_PATH,
    pageTitle: 'Clinicians',
    component: Clinicians,
    subRoutes: [],
    nextPage: null,
    prevPage: null,
    exact: true,
    isPrivate: true,
  },
  {
    key: 'patient-details',
    path: PATIENT_DETAILS_PATH,
    pageTitle: 'User Details',
    component: PatientDetails,
    subRoutes: [],
    nextPage: null,
    prevPage: null,
    exact: false,
    isPrivate: true,
  },
  {
    key: 'auth-check',
    path: AUTH_CHECK_PATH,
    pageTitle: 'User Details',
    component: AuthCheck,
    subRoutes: [],
    nextPage: null,
    prevPage: null,
    exact: true,
    isPrivate: false,
  },
  {
    key: 'session-visuals',
    path: SESSION_VISUAL_PATH,
    pageTitle: 'Session Visuals',
    component: SessionVisuals,
    subRoutes: [],
    nextPage: null,
    prevPage: null,
    exact: false,
    isPrivate: true,
  },
  {
    key: 'devices',
    path: DEVICES_PATH,
    pageTitle: 'Devices',
    component: Devices,
    subRoutes: [],
    nextPage: null,
    prevPage: null,
    exact: true,
    isPrivate: true,
  },
];

export default Routes;
