export interface Data {
  id: number;
  serial_number: string;
  mac_address: string;
  passkey: string;
  assinged_clinician: string;
  assinged_patient: string;
}

type SortMethod = 'alpha' | 'numeric' | 'date' | 'name' | 'none';

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  sortMethod: SortMethod;
}

export const headCells: HeadCell[] = [
  {
    id: 'id',
    label: 'ID',
    disablePadding: false,
    sortMethod: 'numeric',
  },
  {
    id: 'serial_number',
    label: 'Serial Number',
    disablePadding: false,
    sortMethod: 'alpha',
  },
  {
    id: 'mac_address',
    label: 'Mac Address',
    disablePadding: false,
    sortMethod: 'alpha',
  },
  {
    id: 'passkey',
    label: 'Passkey',
    disablePadding: false,
    sortMethod: 'alpha',
  },
  {
    id: 'assinged_clinician',
    label: 'Assigned Clinician',
    disablePadding: false,
    sortMethod: 'name',
  },
  {
    id: 'assinged_patient',
    label: 'Assigned Patient',
    disablePadding: false,
    sortMethod: 'name',
  },
];
