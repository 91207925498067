// NOTE: As of 2023-01-13 REACT_APP_ENV is not being set correctly in the package.json
// node scripts so env here is undefined.

const baseURLConfig = () => {
  const env = process.env.REACT_APP_ENV;
  console.log('REACT_APP_ENV: ', env);
  if (env === 'production') {
    return 'https://app.innovoyceapp.com/api/v0';
  } else if (env === 'qa') {
    return 'https://qa.innovoyceapp.com/api/v0';
  } else {
    return 'https://dev.innovoyceapp.com/api/v0';
    //return 'http://localhost:5002/v0';
  }
};

export default baseURLConfig;
