//Patients.tsx
/**
 * Defines the views for the list of patients (/patients)
 *
 * @module
 * @author:
 * @copyright:
 */
import React, { useEffect, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import CustomTable from '../components/CustomTable';
import { Input, Snackbar, Typography, Dialog, CircularProgress } from '@material-ui/core';
import CustomButton from '../components/CustomButton';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import { connect, useDispatch } from 'react-redux';
import SearchIcon from '@material-ui/icons/Search';
import { get as GET, post, auth } from '../utils/services';
import ParameterForm from '../components/ParameterForm';
import AdvancedParameterForm from '../components/AdvancedParameterForm';

import { headCells, Patient } from '../constants/patient';
import {
  deletePatientsRequest,
  getGenderRequest,
  getPatientDetailsRequest,
  getPatientsRequest,
  setPatientDelegateRequest,
  setPatientDiagnosisRequest,
  setPatientRequest,
  updatePatientRequest,
  getCliniciansForDelegate,
  setParameterRequest,
  setAdvancedParameterRequest,
} from '../store/actions';
import UserForm from '../components/UserForm';
import { createDiagnosisArray } from '../utils/helper/createDiagnosis';
import Alert from '@material-ui/lab/Alert';
import { advParamFormFieldList, paramFormFieldList } from '../constants/paramterForm';
import moment from 'moment';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    body: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: '2em',
    },
  }),
);

interface PatientProps {
  userAuth: {
    userData: object;
  };
  list: any;
  patient: {
    patientData: {
      data: {
        patient: Array<any>;
      };
    };
    patientDetails: any;
    createdPatient: any;
    loading: boolean;
    error: {
      message?: string;
    };
    deletePatient: any;
    updatePatient: any;
  };
  history: any;
}

interface LooseObject {
  [key: string]: string | boolean;
}

function Patients({ patient, list, history }: PatientProps) {
  const [open, setOpen] = useState(false);
  const [medInfoForm, setMedInfoForm] = useState(false);
  const [delegateForm, setDelegateForm] = useState(false);
  const [edit, setEdit] = useState(false);
  const [editData, setEditData] = useState({});
  const [patientID, setPatientID] = useState(0);
  const [searchValue, setSearchValue] = useState('');
  const [searchList, setSearchList] = useState<any>();
  const [data, setData] = useState<any>();
  const [patientToast, setPatientToast] = useState<any>('');
  const [alert, setAlert] = React.useState(false);
  const [patientOperationSuccess, setPatientOperationSuccess] = React.useState(false);
  const [resetPatientPassword, setResetPatientPassword] = useState(false);
  const [parametersOpen, setParametersOpen] = useState(false);
  const [advancedParametersOpen, setAdvancedParametersOpen] = useState(false);
  const [editParameters, setEditParameters] = useState(false);
  const [editAdvancedParameters, setEditAdvancedParameters] = useState(false);
  const [jsonDataLoading, setJsonDataLoading] = useState(false);
  const [jsonData, setJsonData] = useState<LooseObject>({});
  const [editForm, setEditForm] = useState<LooseObject>({});

  const classes = useStyles();
  const dispatch = useDispatch();

  const { loading, patientData, createdPatient, patientDetails } = patient;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const createPatient = (data: Patient) => {
    dispatch(setPatientRequest(data, setAlert));
    setMedInfoForm(true);
    setOpen(false);
  };

  const setDiagnosis = (data: any) => {
    const patient = !edit ? createdPatient?.data?.result.id_person : patientID;
    dispatch(
      setPatientDiagnosisRequest(
        createDiagnosisArray(list.diagnosis?.result, data),
        patient,
        edit,
        setAlert,
      ),
    );
    setMedInfoForm(false);
    setTimeout(() => {
      dispatch(getPatientsRequest());
    }, 0);
  };

  useEffect(() => {
    dispatch(getPatientsRequest());
  }, []);

  const editPatient = (data: any) => {
    dispatch(getPatientDetailsRequest(data.id));
    setPatientID(data.id);
    setEditData(data);
    setEdit(true);
    setOpen(true);
    setData(data);
  };

  const editDiagnosis = (data: any) => {
    setMedInfoForm(true);
    setPatientID(data.id);
    setEdit(true);
  };

  const onResetPatientPassword = (data: any) => {
    setPatientID(data.id);
    setResetPatientPassword(true);
  };

  const onEditParametersClose = () => {
    setParametersOpen(false);
    setEditParameters(false);
  };

  const onEditAdvancedParameterClose = () => {
    setAdvancedParametersOpen(false);
    setEditAdvancedParameters(false);
  };

  const viewParameters = async (data: any) => {
    setPatientID(data.id);
    setParametersOpen(true);
    setJsonDataLoading(true);

    const token = await localStorage.getItem('simbex_authtoken');

    const res = await GET(`patient/${data.id}/parameters-by-clinician`, {
      headers: {
        'authorization': `Bearer ${token}`,
        'Cache-Control': 'no-cache,no-store, max-age=0',
      },
    });
    if (!res.success) setJsonData({});
    if (res.success) {
      setJsonData(res.data.result[0]);
      setEditForm(res.data.result[0]);
    }
    setJsonDataLoading(false);
  };

  const viewAdvancedParams = async (data: any) => {
    setPatientID(data.id);
    setJsonDataLoading(true);
    setAdvancedParametersOpen(true);
    const token = await localStorage.getItem('simbex_authtoken');
    const res = await GET(`/patient/${data.id}/advanced-parameters`, {
      headers: {
        'authorization': `Bearer ${token}`,
        'Cache-Control': 'no-cache,no-store, max-age=0',
      },
    });
    if (!res.success) setJsonData({});
    if (res.success) {
      setJsonData(res.data.result[0]);
      setEditForm(res.data.result[0]);
    }

    setJsonDataLoading(false);
  };

  const getFormInitialData = () => {
    const obj: LooseObject = {};
    paramFormFieldList.map((item: string, index: number) => {
      obj[item] = jsonData[item];
    });
    return obj;
  };

  const getFormInitialDataForAdvParams = () => {
    const obj: LooseObject = {};
    advParamFormFieldList.map((item: string, index: number) => {
      obj[item] = jsonData[item];
    });
    return obj;
  };

  const handleAddParameter = (e: any) => {
    setJsonDataLoading(true);
    Object.keys(e).map((a, b) => {
      if (e[a] === 'on') e[a] = true;
      else if (e[a] === 'off') e[a] = false;
    });
    dispatch(
      setParameterRequest(
        {
          parameterId: jsonData.id,
          payload: {
            ...e,
            id_person_patient: patientID,
            id_person_clinician: jsonData.id_person_clinician,
          },
        },
        {
          alert: setAlert,
          message: setPatientToast,
          status: setPatientOperationSuccess,
        },
      ),
    );
    setParametersOpen(false);
    setEditParameters(false);
    setJsonDataLoading(false);
  };

  const handleAddAdvParameter = (e: any) => {
    setJsonDataLoading(true);
    dispatch(
      setAdvancedParameterRequest(
        {
          advancedParameterId: jsonData.id,
          patientId: patientID,
          payload: {
            ...e,
            id_person_patient: patientID,
            id_person_clinician: jsonData.id_person_clinician,
          },
        },
        {
          alert: setAlert,
          message: setPatientToast,
          status: setPatientOperationSuccess,
        },
      ),
    );
    setAdvancedParametersOpen(false);
    setEditAdvancedParameters(false);
    setJsonDataLoading(false);
  };

  const delegatePatient = (data: any) => {
    setDelegateForm(true);
    setPatientID(data.id);
    dispatch(getPatientDetailsRequest(data.id));
    dispatch(getCliniciansForDelegate(data.id));
  };

  const deleteRows = (selected: Array<string>) =>
    selected.forEach((row: any) => {
      dispatch(deletePatientsRequest(row, setAlert));
    });

  const handlePatientClose = () => {
    setOpen(false);
    setEdit(false);
  };

  const handleDiagnosisClose = () => {
    setDelegateForm(false);
    setMedInfoForm(false);
    setEdit(false);
    setResetPatientPassword(false);
  };

  /**
   * Handles updating a patient's data.
   * @param data
   */
  const updatePatient = (data: any) => {
    dispatch(updatePatientRequest(data, patientID, setAlert));
    setOpen(false);
    setTimeout(() => {
      dispatch(getPatientsRequest());
      setEdit(false);
    }, 0);
  };

  /**
   * Entry point for handling the case of either a user's/patient's details being edited
   * or a user/patient being created.
   * @param data Blob of user data
   * @returns
   */
  const handlePatientSubmit = (data: any) => {
    return edit ? updatePatient(data) : createPatient(data);
  };

  const handleDiagnosisSubmit = (data: any) => {
    return setDiagnosis(data);
  };

  const handleDelegateSumbit = (data: any) => {
    dispatch(setPatientDelegateRequest(data, patientID));
  };

  const handleResetPatientPassword = (data: any) => {
    setJsonDataLoading(true);
    const { newPassword, confirmPassword } = data;
    const payload = { newPassword, confirmPassword };
    post(`patient/${patientID}/reset-password`, payload, auth()).then((data) => {
      if (data.success) {
        setResetPatientPassword(false);
        setAlert(true);
        setPatientToast(data.message);
        setPatientOperationSuccess(true);
        setJsonDataLoading(false);
      } else {
        setAlert(true);
        setPatientToast(data.message);
        setPatientOperationSuccess(false);
        setJsonDataLoading(false);
      }
    });
  };

  const onViewPatient = (data: any, id: any) => {
    history.push({
      pathname: '/patient-details',
      // search: '?query=abc',
      state: { data, id },
    });
  };
  useEffect(() => {
    let filteredDatas: any = [];
    const timeoutId = setTimeout(() => {
      filteredDatas = (patientData?.data?.patient || []).filter((e) => {
        return (
          (e.patient_display_name &&
            e.patient_display_name.toUpperCase().includes(searchValue.toUpperCase())) ||
          (e.gender_text && e.gender_text.toUpperCase().includes(searchValue.toUpperCase())) ||
          (e.emr && e.emr.toUpperCase().includes(searchValue.toUpperCase())) ||
          (e.patient_status_text &&
            e.patient_status_text.toUpperCase().includes(searchValue.toUpperCase()))
        );
      });
      setSearchList(filteredDatas);
    }, 1000);
    return () => clearTimeout(timeoutId);
  }, [searchValue]);
  const handleSearch = (value: any) => {
    setSearchValue(value);
  };
  const triggerPatientUpdateToast = () => {
    if (!patient.loading) {
      const { updatePatient } = patient;
      setPatientOperationSuccess(updatePatient?.success);
      setPatientToast(updatePatient?.data?.message);
    }
  };
  const triggerPatientCreateToast = () => {
    if (!patient.loading) {
      const { createdPatient } = patient;
      setPatientOperationSuccess(createdPatient?.success);
      setPatientToast(createdPatient?.data?.message);
    }
  };
  const triggerPatientDeleteToast = () => {
    if (!patient.loading) {
      const { deletePatient } = patient;
      setPatientOperationSuccess(deletePatient?.success);
      setPatientToast(deletePatient?.data?.message);
    }
  };
  useEffect(() => {
    triggerPatientUpdateToast();
  }, [patient.updatePatient]);
  useEffect(() => {
    triggerPatientCreateToast();
  }, [patient.createdPatient]);
  useEffect(() => {
    triggerPatientDeleteToast();
  }, [patient.deletePatient]);
  const handleClose = () => {
    setAlert(false);
  };
  return (
    <div className={classes.root}>
      {parametersOpen ? (
        <>
          <Dialog open onClose={onEditParametersClose} style={{ width: '100%' }}>
            <div
              style={{
                backgroundColor: 'white',
                width: '100%',
                padding: jsonDataLoading ? 100 : 0,
              }}
            >
              {jsonDataLoading || patient.loading ? (
                <CircularProgress variant='indeterminate' />
              ) : (
                <>
                  <ParameterForm
                    handleClose={onEditParametersClose}
                    initialValue={getFormInitialData()}
                    handleAdd={handleAddParameter}
                    disabled={!editParameters}
                    toggleDisabled={() => setEditParameters(!editParameters)}
                  />
                </>
              )}
            </div>
          </Dialog>
        </>
      ) : null}

      {advancedParametersOpen ? (
        <>
          <Dialog open onClose={onEditAdvancedParameterClose} style={{ width: '100%' }}>
            <div
              style={{
                backgroundColor: 'white',
                width: '100%',
                padding: jsonDataLoading ? 100 : 0,
              }}
            >
              {jsonDataLoading || patient.loading ? (
                <CircularProgress variant='indeterminate' />
              ) : (
                <>
                  <AdvancedParameterForm
                    handleClose={onEditAdvancedParameterClose}
                    initialValue={getFormInitialDataForAdvParams()}
                    handleAdd={handleAddAdvParameter}
                    disabled={!editAdvancedParameters}
                    toggleDisabled={() => setEditAdvancedParameters(!editAdvancedParameters)}
                  />
                </>
              )}
            </div>
          </Dialog>
        </>
      ) : null}
      <div className={classes.body}>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={alert}
          autoHideDuration={5000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity={patientOperationSuccess ? 'success' : 'error'}>
            {patientToast}
          </Alert>
        </Snackbar>
        <Typography variant='h6'>User List</Typography>

        <UserForm
          modalOpen={open}
          modalClose={handlePatientClose}
          onSubmit={handlePatientSubmit}
          edit={edit}
          patientID={patientID}
          editData={patientDetails}
          title={edit ? 'Edit User' : 'Add User'}
          patient={true}
          onEditDiagClick={(flag?: boolean) => {
            setOpen(false);
            editDiagnosis(data);
          }}
          isLoading={patient.loading}
        />
        <UserForm
          modalOpen={medInfoForm}
          modalClose={handleDiagnosisClose}
          onSubmit={handleDiagnosisSubmit}
          title={edit ? 'Edit Diagnosis' : 'Add Diagnosis'}
          edit={edit}
          editData={patientDetails}
          medicalInfoForm={true}
          onEditDiagClick={(flag?: boolean) => {
            setMedInfoForm(false);
            editPatient(data);
          }}
          isLoading={patient.loading}
        />
        <UserForm
          modalOpen={delegateForm}
          modalClose={handleDiagnosisClose}
          onSubmit={handleDelegateSumbit}
          title='Delegate'
          edit={edit}
          editData={editData}
          delegateForm={true}
          isLoading={patient.loading}
        />
        <UserForm
          modalOpen={resetPatientPassword}
          modalClose={handleDiagnosisClose}
          onSubmit={handleResetPatientPassword}
          title='Reset Patient Password'
          edit={false}
          resetPassword={true}
          resetPasswordForUser={true}
          isLoading={jsonDataLoading}
        />
        <div
          style={{
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
          }}
        >
          <SearchIcon style={{ marginRight: -12 }} />
          <Input
            type='text'
            value={searchValue}
            onChange={(e: any) => handleSearch(e.target.value)}
            style={{
              width: 300,
              padding: 8,
              paddingLeft: 12,
              marginRight: 8,
              borderWidth: 0.3,
              borderColor: 'red',
            }}
            placeholder='Search'
          />

          <CustomButton height='2.5em' width='max-content' onClick={handleClickOpen}>
            <PersonAddIcon style={{ marginRight: '5px' }} />
            Add User
          </CustomButton>
        </div>
      </div>
      <CustomTable
        deleteRows={deleteRows}
        loading={loading}
        patient={true}
        header={headCells}
        dataRows={searchValue ? searchList : patientData?.data?.patient}
        onEdit={editPatient}
        // onEditDiagnosis={editDiagnosis}
        onResetPassword={onResetPatientPassword}
        onEditParameters={viewParameters}
        onEditAdvancedParameters={viewAdvancedParams}
        onDelegate={delegatePatient}
        onView={onViewPatient}
      />
    </div>
  );
}

const mapStateToProps = (state: any) => ({
  userData: state.userAuth.userData,
  patient: state.patient,
  list: state.list,
});

export default connect(mapStateToProps)(Patients);
