import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import InfoCards from '../components/InfoCards';
import { Input, Typography } from '@material-ui/core';
import SessionsTable from '../components/SessionsTable';
import { createCards, sessionHeaders } from '../constants/dashboard';
import { getDashboardRequest, getPatientSession } from '../store/actions';
import SearchIcon from '@material-ui/icons/Search';
import moment from 'moment';
import { secondsToHms } from '../utils/helper/formatTime';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardsWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: theme.spacing(5),
    },
    clinicianCardsWrapper: {
      display: 'flex',
      marginBottom: theme.spacing(5),
    },
  }),
);

interface DashboardProps {
  userData: any;
  dashboard: {
    dashboardData: {
      data: {
        patient_count: number;
        clinician_count: number;
        device_count: number;
        top_sessions: any;
      };
    };
  };
  history: any;
}

export const Dashboard = ({ dashboard, userData, history }: DashboardProps) => {
  const data = dashboard?.dashboardData?.data;
  const classes = useStyles();
  const dispatch = useDispatch();
  const user = userData?.data?.user;
  const [searchValue, setSearchValue] = React.useState('');
  const [searchList, setSearchList] = React.useState<any>();
  useEffect(() => {
    setTimeout(() => {
      dispatch(getDashboardRequest());
    }, 0);
  }, []);

  useEffect(() => {
    let filteredDatas: any = [];
    const timeoutId = setTimeout(() => {
      filteredDatas = (dashboard.dashboardData?.data?.top_sessions || []).filter((e) => {
        return (
          (e.id && e.id.toString().includes(searchValue.toUpperCase())) ||
          (e.first_name_patient &&
            e.first_name_patient.toUpperCase().includes(searchValue.toUpperCase())) ||
          (e.first_name_clinician &&
            e.first_name_clinician.toUpperCase().includes(searchValue.toUpperCase())) ||
          (e.duration &&
            secondsToHms(e.duration).toUpperCase().includes(searchValue.toUpperCase())) ||
          (e.voiced && secondsToHms(e.voiced).toUpperCase().includes(searchValue.toUpperCase())) ||
          (e.start_date &&
            moment(e.start_date)
              .utc()
              .format('MMM DD, YYYY hh:mm A')
              .toUpperCase()
              .includes(searchValue.toUpperCase()))
        );
      });
      setSearchList(filteredDatas);
    }, 1000);
    return () => clearTimeout(timeoutId);
  }, [dashboard.dashboardData.data, searchValue]);

  const cards = createCards(data?.patient_count, data?.clinician_count, data?.device_count);
  const dashboardData = dashboard?.dashboardData;

  const sessions = dashboardData?.data;

  const topSessions = sessions ? sessions.top_sessions : [];

  const handleSearch = (value: any) => {
    setSearchValue(value);
  };

  //Navigates to the page that presents detailed data about the selected session
  const onViewPress = (id: number, patientDetailsInfo: any) => {
    dispatch(getPatientSession({ id: patientDetailsInfo.id_person_patient }));
    history.push({
      pathname: '/session-visuals',
      state: {
        data: patientDetailsInfo,
        id: patientDetailsInfo.id_person_patient,
        sessionId: id,
      },
    });
  };

  return (
    <>
      <Typography variant='h6' style={{ marginBottom: 4 }}>
        Dashboard
      </Typography>
      <div className={user.is_admin ? classes.cardsWrapper : classes.clinicianCardsWrapper}>
        {cards.map((card) => {
          return user.is_admin ? (
            <InfoCards
              isAdmin={user.is_admin}
              key={card.key}
              label={card.label}
              value={card.value}
              Icon={card.Icon}
              color={card.color}
              onClick={() => {
                if (card.path)
                  history.push({
                    pathname: card.path,
                  });
              }}
            />
          ) : (
            user.is_clinician && card.key !== 'clinician' && (
              <InfoCards
                isAdmin={user.is_admin}
                key={card.key}
                label={card.label}
                value={card.value}
                Icon={card.Icon}
                color={card.color}
                onClick={() => {
                  if (card.path)
                    history.push({
                      pathname: card.path,
                    });
                }}
              />
            )
          );
        })}
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>
          <Typography variant='h6' color='textPrimary'>
            Last 10 sessions
          </Typography>
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <SearchIcon style={{ marginRight: -10 }} />
          <Input
            type='text'
            onChange={(e: any) => handleSearch(e.target.value)}
            style={{ width: 300, paddingLeft: 16 }}
            placeholder='Search'
          />
        </div>
      </div>
      <SessionsTable
        headers={sessionHeaders}
        sessionData={dashboard}
        topSessions={searchValue ? searchList : topSessions}
        onViewPress={onViewPress}
      />
    </>
  );
};

const mapStateToProps = (state: any) => ({
  userData: state.userAuth.userData,
  dashboard: state.dashboard,
});

export default connect(mapStateToProps)(Dashboard);
