export interface Data {
  id: number;
  patient: string;
  created_date: Date;
  gender: string;
  emr: string;
  patientStatus: string;
  options: any;
  first_name: string;
  gender_text: string;
  patient_status_text: string;
}

export interface Patient {
  id: number;
  first_name: string;
  last_name: String;
  email: string;
  created_date: Date;
  id_gender: number;
  address1: string;
  address2: string;
  city: string;
  zip: number;
  emr: string;
  id_state: number;
  id_country: number;
  options: any;
}

type SortMethod = 'alpha' | 'numeric' | 'date' | 'none';

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  sortMethod: SortMethod;
}

/**
 * Defines the columns headers for a list of patients
 */
export const headCells: HeadCell[] = [
  { id: 'id', sortMethod: 'numeric', disablePadding: false, label: 'ID' },
  {
    id: 'first_name',
    sortMethod: 'alpha',
    disablePadding: false,
    label: 'User ID',
  },
  { id: 'gender_text', sortMethod: 'alpha', disablePadding: false, label: 'Sex' },
  { id: 'emr', sortMethod: 'alpha', disablePadding: false, label: 'Custom ID' },
  {
    id: 'patient_status_text',
    sortMethod: 'alpha',
    disablePadding: false,
    label: 'Status',
  },
  { id: 'options', sortMethod: 'none', disablePadding: false, label: '' },
];
