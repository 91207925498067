//dashboard.ts
/**
 * Defines style, metadata, etc. for widgets displayed on the session details page.
 *
 * @module
 * @author:
 * @copyright:
 */

export interface Data {
  id: string;
  start_date: Date;
  end_date: Date;
  duration: number;
  voiced: number;
  biofeedback_count: number;
  pitch: number;
  quality: number;
  loudness: number;
  device: string;
  id_parameters_by_clinician: number;
  id_advanced_parameters: number;
  notes: any;
  details: any;
}

type SortMethod = 'alpha' | 'numeric' | 'date' | 'device' | 'none';

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  sortMethod: SortMethod;
  align: string;
}

/**Defines key, label and alignment of the column headers for a session list table.
 * NOTE: There are other places in the application where session lists are displayed.
 * To maintain consistency, any changes to the labeling here should be cross checked
 * against those other pages.
 */
export const headCells: HeadCell[] = [
  {
    id: 'id',
    sortMethod: 'numeric',
    disablePadding: false,
    label: '#',
    align: 'left',
  },
  {
    id: 'start_date',
    sortMethod: 'date',
    disablePadding: false,
    label: 'Start Time',
    align: 'center',
  },
  {
    id: 'end_date',
    sortMethod: 'date',
    disablePadding: false,
    label: 'End Time',
    align: 'center',
  },
  {
    id: 'duration',
    sortMethod: 'numeric',
    disablePadding: false,
    label: 'Unpaused Duration',
    align: 'center',
  },
  {
    id: 'voiced',
    sortMethod: 'numeric',
    disablePadding: false,
    label: 'Voiced',
    align: 'left',
  },
  {
    id: 'biofeedback_count',
    sortMethod: 'numeric',
    disablePadding: false,
    label: 'Alerts',
    align: 'left',
  },
  {
    id: 'pitch',
    sortMethod: 'numeric',
    disablePadding: false,
    label: 'Pitch (Hz)',
    align: 'left',
  },
  {
    id: 'loudness',
    sortMethod: 'numeric',
    disablePadding: false,
    label: 'Loudness (dB)',
    align: 'left',
  },
  {
    id: 'device',
    sortMethod: 'device',
    disablePadding: false,
    label: 'Device',
    align: 'left',
  },
  {
    id: 'details',
    sortMethod: 'none',
    disablePadding: false,
    label: 'Details',
    align: 'left',
  },
];
