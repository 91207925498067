import { getPatientDetails } from './../../utils/services/patient/index';
import {
  ON_GET_PATIENTS_DATA_REQUEST,
  ON_GET_PATIENTS_DATA_SUCCESS,
  ON_GET_PATIENTS_DATA_FAILED,
  ON_DELETE_PATIENT_REQUEST,
  ON_DELETE_PATIENT_SUCCESS,
  ON_DELETE_PATIENT_FAILED,
  ON_SET_PATIENT_REQUEST,
  ON_SET_PATIENT_FAILED,
  ON_SET_PATIENT_SUCCESS,
  ON_SET_PATIENT_DIAGNOSIS_REQUEST,
  ON_SET_PATIENT_DIAGNOSIS_SUCCESS,
  ON_SET_PATIENT_DIAGNOSIS_FAILED,
  ON_UPDATE_PATIENT_SUCCESS,
  ON_UPDATE_PATIENT_FAILED,
  ON_UPDATE_PATIENT_REQUEST,
  ON_GET_PATIENT_DETAILED_DATA_REQUEST,
  ON_GET_PATIENT_DETAILED_DATA_SUCCESS,
  ON_GET_PATIENT_DETAILED_DATA_FAILED,
  ON_SET_PATIENT_DELEGATE_REQUEST,
  ON_SET_PATIENT_DELEGATE_SUCCESS,
  ON_SET_PATIENT_DELEGATE_FAILED,
  ON_UNSET_PATIENT_DELEGATE_FAILED,
  ON_UNSET_PATIENT_DELEGATE_REQUEST,
  ON_UNSET_PATIENT_DELEGATE_SUCCESS,
  ON_PATIENT_SESSION_REQUEST,
  ON_PATIENT_SESSION_SUCCESS,
  ON_GET_CLINICIANS_FOR_DELEGATE_SUCCESS,
  ON_GET_CLINICIANS_FOR_DELEGATE_REQUEST,
  ON_GET_CLINICIANS_FOR_DELEGATE_FAILED,
} from '../actionTypes';

export const getPatientsRequest = () => ({
  type: ON_GET_PATIENTS_DATA_REQUEST,
});

export const getPatientsSuccess = (data: any) => ({
  type: ON_GET_PATIENTS_DATA_SUCCESS,
  payload: data,
});

export const getPatientsFailed = (data: any) => ({
  type: ON_GET_PATIENTS_DATA_FAILED,
  payload: data,
});
export const setPatientRequest = (data: any, toast: (state: boolean) => void) => ({
  type: ON_SET_PATIENT_REQUEST,
  data,
  toast,
});

export const setPatientSuccess = (data: any) => ({
  type: ON_SET_PATIENT_SUCCESS,
  payload: data,
});

export const setPatientFailed = (data: any) => ({
  type: ON_SET_PATIENT_FAILED,
  payload: data,
});
export const deletePatientsRequest = (data: any, toast: (state: boolean) => void) => ({
  type: ON_DELETE_PATIENT_REQUEST,
  data,
  toast,
});

export const deletePatientsSuccess = (data: any) => ({
  type: ON_DELETE_PATIENT_SUCCESS,
  payload: data,
});

export const deletePatientsFailed = (data: any) => ({
  type: ON_DELETE_PATIENT_FAILED,
  payload: data,
});

export const setPatientDiagnosisRequest = (
  data: any,
  patientID: number,
  edit: boolean,
  toast: (state: boolean) => void,
) => ({
  type: ON_SET_PATIENT_DIAGNOSIS_REQUEST,
  data,
  patientID,
  edit,
  toast,
});

export const setPatientDiagnosisSuccess = (data: any) => ({
  type: ON_SET_PATIENT_DIAGNOSIS_SUCCESS,
  payload: data,
});

export const setPatientDiagnosisFailed = (data: any) => ({
  type: ON_SET_PATIENT_DIAGNOSIS_FAILED,
  payload: data,
});

export const updatePatientRequest = (
  data: any,
  patientID: number,
  toast: (state: boolean) => void,
) => ({
  type: ON_UPDATE_PATIENT_REQUEST,
  data,
  patientID,
  toast,
});

export const updatePatientSuccess = (data: any) => ({
  type: ON_UPDATE_PATIENT_SUCCESS,
  payload: data,
});

export const updatePatientFailed = (data: any) => ({
  type: ON_UPDATE_PATIENT_FAILED,
  payload: data,
});

export const getPatientDetailsRequest = (patientID: number) => ({
  type: ON_GET_PATIENT_DETAILED_DATA_REQUEST,
  patientID,
});

export const getPatientDetailsSuccess = (data: any) => ({
  type: ON_GET_PATIENT_DETAILED_DATA_SUCCESS,
  payload: data,
});

export const getPatientDetailsFailed = (data: any) => ({
  type: ON_GET_PATIENT_DETAILED_DATA_FAILED,
  payload: data,
});

export const setPatientDelegateRequest = (data: any, patientID: number) => ({
  type: ON_SET_PATIENT_DELEGATE_REQUEST,
  data,
  patientID,
});

export const setPatientDelegateSuccess = (data: any) => ({
  type: ON_SET_PATIENT_DELEGATE_SUCCESS,
  payload: data,
});

export const setPatientDelegateFailed = (data: any) => ({
  type: ON_SET_PATIENT_DELEGATE_FAILED,
  payload: data,
});

export const unSetPatientDelegateRequest = (data: any, patientID: number) => ({
  type: ON_UNSET_PATIENT_DELEGATE_REQUEST,
  data,
  patientID,
});

export const unSetPatientDelegateSuccess = (data: any) => ({
  type: ON_UNSET_PATIENT_DELEGATE_SUCCESS,
  payload: data,
});

export const unSetPatientDelegateFailed = (data: any) => ({
  type: ON_UNSET_PATIENT_DELEGATE_FAILED,
  payload: data,
});

export const getPatientSession = (data: any) => ({
  type: ON_PATIENT_SESSION_REQUEST,
  data,
});

export const getPatientSessionSuccess = (data: any) => ({
  type: ON_PATIENT_SESSION_SUCCESS,
  data,
});

export const getCliniciansForDelegate = (data: any) => ({
  type: ON_GET_CLINICIANS_FOR_DELEGATE_REQUEST,
  data,
});

export const getCliniciansForDelegateSuccess = (data: any) => ({
  type: ON_GET_CLINICIANS_FOR_DELEGATE_SUCCESS,
  payload: data,
});
export const getCliniciansForDelegateFailed = (data: any) => ({
  type: ON_GET_CLINICIANS_FOR_DELEGATE_FAILED,
  payload: data,
});
