import {
  ON_SET_PARAMETER_REQUEST,
  ON_SET_PARAMETER_FAILED,
  ON_SET_PARAMETER_SUCCESS,
} from './../actionTypes/parameter.actionTypes';

export const setParameterRequest = (data: any, toast?: any) => ({
  type: ON_SET_PARAMETER_REQUEST,
  data,
  toast,
});

export const setParameterSuccess = (data: any) => ({
  type: ON_SET_PARAMETER_SUCCESS,
  payload: data,
});

export const setParameterFailed = (data: any) => ({
  type: ON_SET_PARAMETER_FAILED,
  payload: data,
});
