import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import { useDispatch } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { get } from 'lodash';
import { useHistory } from 'react-router-dom';

import CustomButton from '../components/CustomButton';
import logo from '../assets/logo/logo-white.jpg';
import { FORGOT_PASS_PATH } from '../utils/routes/publicRoutes';
import { loginRequest } from '../store/actions/userAuth.actions';
import { loginErrors } from '../constants/errors';
import TextInput from '../components/TextInput';
import { isLogin } from '../utils/middleware/auth';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: 'linear-gradient(135deg, #1A428A 0%, #007CBA 100%)',
  },
  card: {
    padding: theme.spacing(2),
    width: theme.spacing(50),
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  logo: {
    width: '5em',
    marginBottom: theme.spacing(3),
  },
  buttonContainer: {
    width: '100%',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(4),
  },
  form: {
    width: '100%',
  },
}));

interface LoginProps {
  userData: object;
  error: {
    message: string;
  };
}

function Login({ userData, error }: LoginProps) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const clickHandle = (data: object) => {
    dispatch(loginRequest(data));
  };

  const checkAuthStatus = async () => {
    const checkLogin = await isLogin();
    if (checkLogin) history.push('/dashboard');
  };

  useEffect(() => {
    checkAuthStatus();
  }, [userData]);

  const { handleSubmit, errors, control } = useForm({ mode: 'onChange' });
  return (
    <div className={classes.root}>
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <img src={logo} alt='white_logo' className={classes.logo} />
          <form className={classes.form}>
            <Controller
              as={
                <TextInput
                  id='username'
                  name='username'
                  label='Username'
                  placeholder='Enter Username'
                  type='text'
                  bgColor='#E6EBF4'
                  autoFocus
                />
              }
              defaultValue=''
              name='username'
              control={control}
              rules={{
                required: true,
                pattern: /^.+@.+\..+$/,
              }}
            />
            {errors.username && (
              <Typography variant='caption' color='error'>
                {get(loginErrors.username, errors.username.type, '')}
              </Typography>
            )}
            <Controller
              as={
                <TextInput
                  id='password'
                  name='password'
                  label='Password'
                  placeholder='Enter Password'
                  bgColor='#E6EBF4'
                  password={1}
                />
              }
              defaultValue=''
              name='password'
              control={control}
              rules={{
                required: true,
              }}
            />
            {errors.password && (
              <Typography variant='caption' color='error'>
                Password required
              </Typography>
            )}
            <Grid container direction='column' justify='center' alignItems='center'>
              <Grid item xs={12} className={classes.buttonContainer}>
                <CustomButton height='3.5em' onClick={handleSubmit(clickHandle)}>
                  Login
                </CustomButton>
              </Grid>
              <Grid item>
                <Typography variant='body1' color='error'>
                  {error?.message}
                </Typography>
              </Grid>
              <Grid item>
                <Link href={FORGOT_PASS_PATH} variant='body2'>
                  Forgot password
                </Link>
              </Grid>
            </Grid>
          </form>
        </CardContent>
      </Card>
    </div>
  );
}

const mapStateToProps = (state: any) => ({
  error: state.userAuth.error,
  userData: state.userAuth.userData,
});

export default connect(mapStateToProps)(Login);
